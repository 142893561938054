<ng-container>
   <div class="holding-details-popup-container">
      <div class="content-container py-0">
         <div
            class="d-flex align-items-center mb-0 justify-content-between gap-4"
         >
            <h5 class="fw-bold lh-1 mb-0">Holding Details</h5>
            <button
               class="btn p-0 shadow-none lh-1"
               (click)="activeModal.dismiss()"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  height="24"
                  width="24"
                  viewBox="0 0 384 512"
               >
                  <path
                     d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  />
               </svg>
            </button>
         </div>
      </div>
      <div class="d-flex flex-column content-container">
         <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
               <img
                  *ngIf="holding.logo"
                  [src]="holding.logo"
                  class="bg-light flex-shrink-0 border img-fluid rounded-circle stock-image"
                  alt=""
               />
               <span
                  *ngIf="!holding.logo"
                  class="bg-white border fs-4 fw-bold flex-shrink-0 img-fluid rounded-circle d-flex align-items-center justify-content-center stock-image"
               >
                  {{ holding.name?.charAt(0) | uppercase }}
               </span>
               <div class="d-block stock-info">
                  <span class="holding-name">{{ holding.name }}</span>
                  <span class="holding-symbol"
                     >&nbsp;({{ holding.symbol }})</span
                  >
               </div>
            </div>
            <app-compliance-chip
               *ngIf="holding.isStockFound"
               [symbol]="holding.isin"
               [complianceStatus]="holding?.complianceStatus"
               [complianceRanking]="holding?.complianceRanking"
            >
            </app-compliance-chip>
         </div>
      </div>
      <div class="gap-3 rounded-3 p-2 px-lg-3 details-container">
         <div class="d-flex flex-column">
            <div class="shared-owned pb-1">
               You own {{ holding.units }} shares
            </div>
            <div class="d-flex flex-column info-container">
               <div class="d-flex justify-content-between pt-2 pb-1 py-lg-2">
                  <div class="details-label">Current Value</div>
                  <div class="details-value">
                     {{ holding.total_current | sumPipe : holding.currency }}
                  </div>
               </div>
               <div class="d-flex justify-content-between py-1 py-lg-2">
                  <div class="details-label">Investment Amount</div>
                  <div class="details-value">
                     {{ holding.total_invested | sumPipe : holding.currency }}
                  </div>
               </div>
               <div class="d-flex justify-content-between py-1 py-lg-2">
                  <div class="details-label">Total Returns</div>
                  <div class="details-value">
                     {{
                        holding.total_current - holding.total_invested >= 0
                           ? "+"
                           : ""
                     }}{{
                        (
                           holding.total_current - holding.total_invested
                        ).toFixed(2) | sumPipe : holding.currency
                     }}
                     <span
                        class="stock-percent"
                        [ngClass]="getPercentChangeClasses(holding)"
                     >
                        ({{
                           holding.percentage_change > 0
                              ? "+" +
                                (holding.percentage_change | number : "1.2-2")
                              : (holding.percentage_change | number : "1.2-2")
                        }}%)
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <ng-container
         *ngIf="
            holding.complianceStatus === 'NON_COMPLIANT' ||
            holding.complianceStatus === 'QUESTIONABLE'
         "
      >
         <p class="mb-0 collapse-btn">
            <button
               type="button"
               class="btn shadow-none py-2 text-start d-flex rounded-3 align-items-center justify-content-between gap-2 w-100"
               (click)="collapse.toggle()"
               [attr.aria-expanded]="!isQuestionCollapsed"
               aria-controls="collapseExample"
            >
               <span [ngClass]="{ 'span-text': !isQuestionCollapsed }">
                  How to deal with Non-Halal stocks as per the Shariah ruling?
               </span>
               <svg
                  width="16px"
                  height="12px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  [ngClass]="{ rotate: !isQuestionCollapsed }"
               >
                  <path
                     d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
               </svg>
            </button>
         </p>
         <div #collapse="ngbCollapse" [(ngbCollapse)]="isQuestionCollapsed">
            <div class="card border-0">
               <div class="card-body pt-0 answer-text">
                  {{ nonPurifiableText }}
                  <!-- As per the Shariah laws, it’s not permissible to invest in Non-compliant stocks. If a stock was previously Halal and it turned Not Halal / Doubtful, a grace period of 90 days can be applied, whereby the investors can continue to hold onto the shares. However, if after the next quarter financial statements, the shares are again non-compliant, then the shares must be disposed. Also, all the capital gains made during the “Non-Compliant” duration should also be purified. -->
               </div>
            </div>
         </div>
      </ng-container>
      <button
         class="btn w-100 fw-bold text-white mark-purified-btn rounded"
         (click)="goToDetails()"
      >
         VIEW STOCK DETAILS
      </button>
   </div>
</ng-container>
