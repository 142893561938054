import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from '../../services/portfolio.service';
import { DataLayerService } from '../../services/data-layer.service';
import { AnalyticsUtils } from '../../services/analytics-utils';
import { lastValueFrom } from 'rxjs';

@Component({
   selector: 'app-connect-iframe-modal',
   templateUrl: './connect-iframe-modal.component.html',
   styleUrls: ['./connect-iframe-modal.component.scss'],
})
export class ConnectIframeModalComponent implements OnInit {
   iframeUrl: string = '';
   isRefetchAccountLoader: boolean = false;
   @Input() module: any;
   status: string = 'PENDING';
   pleaseWaitBtn: boolean = false;
   countdownTimer: number = 5;

   constructor(
      public activeModal: NgbActiveModal,
      private router: Router,
      private portfolioService: PortfolioService,
      private dataLayerService: DataLayerService,
      private analyticsUtils: AnalyticsUtils
   ) {}

   ngOnInit(): void {
      console.log(this.module);

      window.addEventListener(
         'message',
         this.handleMessageEvent.bind(this),
         false
      );
      // this.status = 'SUCCESS'
      // this.startPleaseWaitTimer()
   }

   async startPleaseWaitTimer() {
      this.countdownTimer = 5;
      this.pleaseWaitBtn = true;
      const intervalId = setInterval(() => {
         if (this.countdownTimer === 0) {
            clearInterval(intervalId);
            this.pleaseWaitBtn = false;
            this.portfolioService.triggerDataReload();
         } else {
            this.countdownTimer--;
         }
      }, 1000);
   }

   ngOnDestroy(): void {
      window.removeEventListener(
         'message',
         this.handleMessageEvent.bind(this),
         false
      );
   }

   closeModal(): void {
      this.activeModal.dismiss();
      //  console.log('closed');
   }

   async goToPortfolio() {
      this.isRefetchAccountLoader = true;
      try {
         await lastValueFrom(this.portfolioService.refetchPortfoliosList());
         // this.router.navigate(['/cabinet/portfolio-dashboard']);
         const targetUrl = '/cabinet/portfolio-dashboard';

         this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
               this.router.navigate([targetUrl]);
            });
         this.portfolioService.triggerDataReload();
         this.closeModal();
      } catch {
         console.log('something went wrong');
      } finally {
         this.isRefetchAccountLoader = false;
      }
   }

   async startTrading() {
      this.isRefetchAccountLoader = true;
      try {
         await lastValueFrom(this.portfolioService.refetchPortfoliosList());
         this.activeModal.dismiss();
      } catch {
         console.log('something went wrong');
      } finally {
         this.isRefetchAccountLoader = false;
      }
   }

   private handleMessageEvent(e: MessageEvent<unknown>) {
      if (e.data) {
         const data = e.data as any;

         if (data.status === 'SUCCESS' || data.status === 'ERROR') {
            this.status = data.status;
         }

         if (e.data === 'CLOSED' || e.data === 'CLOSE_MODAL') {
            if (this.status !== 'SUCCESS') {
               this.status = 'ERROR';
            } else {
               // console.log(data)
               this.status = 'SUCCESS';
               this.startPleaseWaitTimer();
               this.dataLayerService.portfolioLinkingSuccessEvent('');
               this.analyticsUtils.portfolio_linking_success('');
            }
         }
      }
   }
}
