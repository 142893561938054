import {
   Component,
   EventEmitter,
   Input,
   OnInit,
   Output,
   SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from '../../services/portfolio.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { AnalyticsUtils } from '../../services/analytics-utils';

@Component({
   selector: 'app-brokers-popup',
   templateUrl: './brokers-popup.component.html',
   styleUrls: ['./brokers-popup.component.scss'],
})
export class BrokersPopupComponent implements OnInit {
   private subscriptions: Subscription[] = [];
   loader: boolean = false;
   @Input() accounts: any = [];
   selectedOption: any = '';
   selectedAccountId: string = '';

   constructor(
      public activeModal: NgbActiveModal,
      private portfolioService: PortfolioService,
      private analyticsUtils: AnalyticsUtils,
      private router: Router,
      private route: ActivatedRoute
   ) {}

   ngOnInit(): void {
      this.subscriptions.push(
         this.portfolioService.getSelectedOption().subscribe((option) => {
            this.selectedOption = option;
         })
      );
      this.subscriptions.push(
         PortfolioService.selectedAccountId.subscribe((accountId: string) => {
            this.selectedAccountId = accountId;
            this.setAccount();
         })
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   setAccount() {
      if (this.accounts.length) {
         if (this.selectedAccountId) {
            this.selectedOption = this.accounts.find(
               (account: any) => account.id == this.selectedAccountId
            );
         } else {
            this.selectedOption = this.accounts[0];
         }
      }
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['accounts'] && changes['accounts'].currentValue?.length) {
         this.selectedAccountId = PortfolioService.selectedAccountId.getValue();
         this.setAccount();
      }
   }

   onOptionChange(account: any = null) {
      this.analyticsUtils.portfolio_account_switched();
      this.portfolioService.setSelectedOption(account);
      this.portfolioService.setAccountId(account?.id ?? this.selectedAccountId);
      this.router.navigate(['/cabinet/portfolio-dashboard'], {
         replaceUrl: true,
         relativeTo: this.route,
      });
      this.closeMyBrokeragesModal();
   }

   closeMyBrokeragesModal() {
      this.activeModal.dismiss();
   }

   openSupportedBrokeragesModal() {
      this.portfolioService.closeMyBrokeragesModal();
      this.portfolioService.openSupportedBrokeragesModal(true);
   }

   navigateToAddPortfolio() {
      this.portfolioService.setIsFromManageTransactions(false);
      this.portfolioService.setHasBrokerageId(true);
      this.portfolioService.setBrokerageId(this.selectedOption.id);
      this.portfolioService.closeMyBrokeragesModal();
      this.router.navigate(['/cabinet/add-portfolio']);
   }
}
