import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

interface FeaturedIn {
   id: string,
   imagePath: string,
   redirectUrl: string
}

@Component({
   selector: 'app-featured-in',
   templateUrl: './featured-in.component.html',
   styleUrls: ['./featured-in.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedInComponent implements OnInit {
   featuredInLogos: FeaturedIn[] = [
      { id: 'associated_press', imagePath: '/assets/images/featured-in/associated-press-logo.png', redirectUrl: 'https://apnews.com/press-release/business-wire/richard-laermer-north-america-4bb55f57172d489282d7b3947cac0573' },
      { id: 'yahoo_finance', imagePath: '/assets/images/featured-in/yahoo-finance-logo.png', redirectUrl: 'https://finance.yahoo.com/news/muslims-fintech-invest-ethically-musaffa-152900771.html' },
      { id: 'busines_wire', imagePath: '/assets/images/featured-in/businesswire-logo.png', redirectUrl: 'https://www.businesswire.com/news/home/20241231906547/en/How-Muslims-Use-Fintech-to-Invest-Ethically-Musaffa-CEO-Dilshod-Jumaniyazov-Explains' },
      { id: 'market_watch', imagePath: '/assets/images/featured-in/market-watch-logo.png', redirectUrl: 'https://www.marketwatch.com/press-release/how-muslims-use-fintech-to-invest-ethically-musaffa-ceo-dilshod-jumaniyazov-explains-1194438a' },
      { id: 'wealth_management', imagePath: '/assets/images/featured-in/wealth-management-logo.png', redirectUrl: 'https://www.wealthmanagement.com/etfs/musaffa-wants-more-muslims-invest-ethically' },
      { id: 'salaam_gateway', imagePath: '/assets/images/featured-in/salaam-gateway-logo.png', redirectUrl: 'https://salaamgateway.com/ranking/top-30-digital-islamic-economy-startups-2024' },
      { id: 'fintect_futures', imagePath: '/assets/images/featured-in/fintech-futures-logo.png', redirectUrl: 'https://www.fintechfutures.com/techwire/how-muslims-use-fintech-to-invest-ethically-musaffa-ceo-dilshod-jumaniyazov-explains/' },
      { id: 'halal_times', imagePath: '/assets/images/featured-in/the-halal-times-logo.png', redirectUrl: 'https://www.halaltimes.com/islamic-fintech-revolution-making-halal-banking-accessible-online/' },
      { id: 'fonivate', imagePath: '/assets/images/featured-in/fonivate-logo.png', redirectUrl: 'https://finovate.com/finovate-global-an-introduction-to-islamic-finance-with-musaffas-dilshod-jumaniyazov/' },
      { id: 'investors_hangout', imagePath: '/assets/images/featured-in/investor-hangout-logo.png', redirectUrl: 'https://investorshangout.com/exploring-ethical-investments-insight-from-musaffas-ceo-163095-/' },
   ]

   direction: string = 'left';
   speed: string = 'slow';
   // private renderCount = 0;

   constructor(private renderer: Renderer2, private el: ElementRef) { }

   ngOnInit(): void {
      // this.incrementRenderCount();
   }

   ngOnChanges(): void {
      // this.incrementRenderCount();
   }

   ngAfterViewInit() {
      // if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      //    this.addAnimation();
      // }
   }

   // private incrementRenderCount(): void {
   //    this.renderCount++;
   //    console.log(`Component rendered ${this.renderCount} times`);
   // }

   trackByFn(index: number, item: FeaturedIn) {
      return item.id;
   }

   // private addAnimation() {
   //    const scroller = this.el.nativeElement.querySelector('.scroller');
   //    this.renderer.setAttribute(scroller, 'data-animated', 'true');

   //    const scrollerInner = this.el.nativeElement.querySelector('.scroller__inner');
   //    const scrollerContent = Array.from(scrollerInner.children) as HTMLElement[]; // Explicit cast to HTMLElement[]

   //    scrollerContent.forEach((item: HTMLElement) => {
   //       const duplicatedItem = item.cloneNode(true) as HTMLElement;
   //       this.renderer.setAttribute(duplicatedItem, 'aria-hidden', 'true');
   //       this.renderer.appendChild(scrollerInner, duplicatedItem);
   //    });
   // }

}
