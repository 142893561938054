<section class="centered">
   <form class="needs-validation" [formGroup]="cancellationForm" (submit)="proceedToCancel(cancellationForm)"
      novalidate>
      <header class="heading">
         <article class="p-3 p-lg-4 article">
            <h4>Cancel Subscription</h4>
         </article>
      </header>
      <div class="px-3 px-lg-4 cancel-content">
         <div>
            <ng-container *ngIf="subscriptionSource === 'stripe'">
               <div class="mb-4">
                  <!-- We're sorry to see you go! <br>
                  We hope that you reconsider your decision and continue your <b>Halal investment</b> journey with us.
                  In case you have decided to cancel, your subscription will end on {{expiresDate}}, and it will not
                  renew
                  after that. -->
                  <div class="mb-2">
                     Do you want to proceed with cancellation of your premium plan?
                  </div>
                  <div>
                     Upon cancellation, your subscription will terminate at the end of the current billing cycle:
                     <b>{{expiresDate}}</b> and will not auto-renew after that.
                  </div>
               </div>
               <div class="form-group">
                  <div class=" my-2">
                     <label for="cancellation-reason" class="mb-1">Reason for Cancellation<span
                           class="text-danger">*</span></label>
                     <!-- <label for="select" class="form-label">Country</label> -->
                     <select class="form-select form-control" (change)="setOtherValidation()"
                        aria-label="Default select example" id="cancellation-reason"
                        formControlName="cancellationReason">
                        <option *ngFor="let reason of reasons" value="{{ reason.key }}">
                           {{ reason.display_value }}
                        </option>
                     </select>
                     <div
                        *ngIf="cancellationForm.controls.cancellationReason.touched && cancellationForm.controls.cancellationReason.errors?.required"
                        class="text text-danger mt-1 small">
                        Reason for cancellation is required.
                     </div>
                  </div>
                  <div class="my-2" *ngIf="cancellationForm.controls.cancellationReason.value === 'other'">
                     <label for="cancellation-reason-other" class="mb-1">Description<span
                           class="text-danger">*</span></label>
                     <input type="text" class="form-control" id="cancellation-reason-other"
                        formControlName="cancellationOtherReason" name="cancellationOtherReason">
                     <div
                        *ngIf="cancellationForm.controls.cancellationOtherReason.touched && cancellationForm.controls.cancellationOtherReason.errors?.required"
                        class="text text-danger mt-1 small">
                        Reason for cancellation is required.
                     </div>
                  </div>
               </div>
            </ng-container>
            <ng-container *ngIf="subscriptionSource === 'app_store'">
               We're sorry to see you go! <br><br>
               We hope that you reconsider your decision and continue your <b>Halal investment</b> journey with us.
               In case you have decided to cancel, you have to cancel the subscription from Apple App Store.
            </ng-container>
            <ng-container *ngIf="subscriptionSource === 'play_store'">
               We're sorry to see you go! <br><br>
               We hope that you reconsider your decision and continue your <b>Halal investment</b> journey with us.
               In case you have decided to cancel, you have to cancel the subscription from Android Play Store.
            </ng-container>
         </div>
      </div>
      <div class="p-3 p-lg-4">
         <div class="d-flex align-items-center justify-content-center btns-container">
            <!-- <button type="button"
            class="btn shadow-none rounded-pill card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3">
            <span>Go Back</span>
         </button> -->
            <!-- <button type="button"
            class="btn shadow-none rounded-pill card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3">
            <span>Cancel Subscription</span>
         </button> -->
            <button [disabled]="loading" type="button" class="btn mx-1 shadow-none modal-btn modal-btn-confirm rounded"
               (click)="dismissPopup()">
               <span>Go Back</span>
            </button>
            <button [disabled]="loading" type="submit" *ngIf="subscriptionSource === 'stripe'"
               class="btn mx-1 shadow-none modal-btn modal-btn-cancel rounded">
               <span *ngIf="!loading">Cancel Subscription</span>
               <div class="d-flex justify-content-center" *ngIf="loading">
                  <div class="spinner-border spinner-border-sm" role="status"
                     style="margin-top: 4px; margin-bottom: 4px;">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
            </button>
         </div>
      </div>
   </form>
</section>