import { createAction, props } from '@ngrx/store';

export const loadPermissions = createAction('[Permissions] Load Permissions');
export const loadPermissionsSuccess = createAction(
  '[Permissions] Load Permissions Success',
  props<{ permissions: any }>()
);
export const loadPermissionsFailure = createAction(
  '[Permissions] Load Permissions Failure',
  props<{ error: any }>()
);
