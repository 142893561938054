import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Checkbox, FormValues, ZakatFormsEnum, ZakatData, Holding, Investment, StockFundInvestments, OtherInvestments } from 'src/app/models/zakat.model';
import { Observable, lastValueFrom, map, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ZakatService {
  url: string = environment.apiUrl

  selectedCurrencyAndFormsSteps!: FormValues;
  ZakatEnum = ZakatFormsEnum;
  showForm = this.ZakatEnum.Home;
  selectedCheckboxes: Checkbox[] = []
  currentStep: number = 1
  private allHoldings: Holding[] = [];
  private otherInvestment: Investment[] = [];

  constructor(private http: HttpClient) {}

  setSelectedCurrencyAndFormsData(data: FormValues) {
    this.selectedCurrencyAndFormsSteps = data
  }

  getSelectedCurrencyAndFormsData() {
    return this.selectedCurrencyAndFormsSteps
  }

  getShowForm(): ZakatFormsEnum {
    return this.showForm;
  }

  setShowForm(form: ZakatFormsEnum) {
    this.showForm = form;
  }

  getAllHoldings(): StockFundInvestments {
    const holdings: Holding[] = this.allHoldings;
    return { holdings };
  }

  setAllHoldings(holdings: Holding[]): void {
    this.allHoldings = holdings;
  }

  getOtherInvestment(): OtherInvestments {
    const investments: Investment[] = this.otherInvestment;
    return  { investments };
  }

  setOtherInvestment(investments: Investment[]): void {
    this.otherInvestment = investments;
  }

  setSelectedCheckboxes(steps: Checkbox[]) {
    this.selectedCheckboxes = steps
  }

  getSelectedCheckboxes() {
    return this.selectedCheckboxes
  }

  getCurrencyCodes(): Observable<any> {
    return this.http.post(this.url+'/api/fetch_currency_code', {})
  }

  async getUserHoldings(currency_code: string): Promise<any> {
    return await lastValueFrom(this.http.post(this.url+'/api/holdings', { currency_code }))
  }

  async calculateMyZakat(zakatBody: ZakatData): Promise<any>  {
    return await lastValueFrom(
      this.http.post(this.url+'/api/zakat_calculation', {
        currency_code: zakatBody.currency_code,
        cash_hand_amt: zakatBody.cash_hand_amt,
        cash_bank_amt: zakatBody.cash_bank_amt,
        gold_amt: zakatBody.gold_amt,
        silver_amt: zakatBody.silver_amt,
        tradable_goods_amt: zakatBody.tradable_goods_amt,
        raw_materials_amt: zakatBody.raw_materials_amt,
        property_total_amt: zakatBody.property_total_amt,
        loan_amt: zakatBody.loan_amt,
        bills_amt: zakatBody.bills_amt,
        payables_amt: zakatBody.payables_amt,
        stock_fund_investments: zakatBody.stock_fund_investments,
        other_investments: zakatBody.other_investments,
        shares_investment_type: zakatBody.shares_investment_type
      })
    )
  }

  getMyZakatCalculation(): Observable<any>  {
    return this.http.post(this.url+'/api/get_my_zakat_calc', {})
  }

}

