import { Component, Input, OnInit } from '@angular/core';
import { selectedAccountDetails } from 'src/app/stores/portfolio/portfolio.selectors';
import { allAccounts } from 'src/app/stores/portfolio/portfolio.selectors';
import { AccountDetailsData } from '../../models/account-details-response.model';
import { allPortfolioLoading } from 'src/app/stores/portfolio/portfolio.selectors';
import { Store } from '@ngrx/store';
import { PortfolioService } from '../../services/portfolio.service';
import { take } from 'rxjs/operators';

@Component({
   selector: 'app-overview',
   templateUrl: './overview.component.html',
   styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
   @Input() selectedRange: string = '1Y';
   @Input() smoothLine: boolean = false;
   selectedAccount$ = this.store.select(selectedAccountDetails);
   accounts$ = this.store.select(allAccounts);
   accountDetails: AccountDetailsData | undefined;
   loading$ = this.store.select(allPortfolioLoading);

   constructor(
      private store: Store,
      private portfolioService: PortfolioService
   ) { }

   purenessData: any;
   portfolioPerformanceData: any;
   currency: string = 'USD';
   accountDetailsLoading: boolean = false;
   portfolioPerformanceLoading: boolean = false;
   purenessLoading: boolean = false;

   ngOnInit(): void {
      this.selectedAccount$.subscribe((account) => {
         if (account) {
            this.accountDetailsLoading = true;
            this.portfolioPerformanceLoading = true;
            this.purenessLoading = true;
            this.portfolioPerformanceData = [];
            this.purenessData = [];

            this.portfolioService
               .getPortfolioAnalytics(account.id, 'shariah_compliant_status')
               .pipe(take(1))
               .subscribe({
                  next: (response: any) => {
                     const responseData =
                        response?.data?.shariah_compliant_status ?? [];
                     this.purenessData = responseData.map((item: any) => {
                        return {
                           label: item.category,
                           value: item.category_amount_percentage,
                        };
                     });
                  },
                  error: (error: any) => {
                     console.error('Error fetching pureness data:', error);
                     this.purenessLoading = false;
                  },
                  complete: () => {
                     this.purenessLoading = false;
                  },
               });
            this.portfolioService
               .getPortfolioPerformance(account.id)
               .pipe(take(1))
               .subscribe({
                  next: (response: any) => {
                     if (response.status) {
                        this.portfolioPerformanceData =
                           response.data.portfolio_performance.sort(
                              (a: any, b: any) =>
                                 new Date(a.date).getTime() -
                                 new Date(b.date).getTime()
                           );
                        this.currency = response.data.currency;
                     }
                  },
                  error: (error: any) => {
                     console.error(
                        'Error fetching portfolio performance data:',
                        error
                     );
                     this.portfolioPerformanceLoading = false;
                  },
                  complete: () => {
                     this.portfolioPerformanceLoading = false;
                  },

               });
            this.portfolioService
               .getPortfolioAccountDetails(account.id)
               .pipe(take(1))
               .subscribe({
                  next: (details) => {
                     this.accountDetails = details.data;
                  },
                  error: (error: any) => {
                     console.error('Error fetching account details:', error);
                     this.accountDetailsLoading = false;
                  },
                  complete: () => {
                     this.accountDetailsLoading = false;
                  },

               });
         }
      });
   }
}
