<div class="greetings-container d-flex flex-column">
   <button type="button" class="btn p-2 rounded-circle shadow-none ms-auto close-btn" (click)="closeGreetingsPopup()">
      <svg xmlns="http://www.w3.org/2000/svg" height="2rem" fill="#5E5E5E" viewBox="0 0 384 512">
         <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
      </svg>
   </button>
   <img src="/assets/images/congratulations-image.svg" alt="congratulations-image" class="congratulations-image img-fluid mx-auto">
   <h3 class="congratulations-heading">Congratulations!</h3>
   <p class="congratulations-text">
      You have unlocked the "{{getPlanText(userPlan)}}" plan.  <br>
      Enjoy all the features & benefits of your plan 🚀
   </p>
   <button class="btn mx-auto shadow-none done-btn" (click)="closeGreetingsPopup()">
      Done
   </button>
</div>
