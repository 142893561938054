<header
   class="modal-header d-flex align-items-start justify-space-between gap-lg-3 px-3 pb-2 border-bottom-0"
>
   <div class="d-flex flex-column gap-1">
      <h5 class="mb-0 modal-heading">Select Account</h5>
      <p class="mb-0 modal-heading-para">
         Select an account to view its portfolio details
      </p>
   </div>
   <button class="btn lh-1 p-0 shadow-none" (click)="activeModal.dismiss()">
      <svg
         xmlns="http://www.w3.org/2000/svg"
         height="24"
         width="24"
         viewBox="0 0 384 512"
      >
         <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
         />
      </svg>
   </button>
</header>
<div class="modal-body pt-2 accounts-container d-flex flex-column gap-3">
   <div class="m-0 d-flex align-items-center single-account" *ngFor="let account of accounts" [ngClass]="selectedAccountId === account.id ? 'selected-class' : ''">
      <input type="radio" class="account-checkbox p-0 my-0 ms-0 me-3 me-lg-4 d-none shadow-none" [value]="account" [id]="account.id" [(ngModel)]="selectedOption" (change)="onOptionChange(account)" />
      <label class="form-check-label d-flex align-items-center gap-3 w-100 checkbox-label" [for]="account.id" >
         <div class="d-flex align-items-center justify-content-center overflow-hidden px-2 flex-shink-0 account-logo-wrapper" >
            <ng-container *ngIf="!account?.is_manual">
               <img *ngIf="account.brokerage_logo" src="{{ account.brokerage_logo }}" class="rounded-2 bg-light radio-image" alt="{{ account.brokerage_name }}" />
               <span *ngIf="!account.brokerage_logo" class="fw-bold text-uppercase d-inline-block text-nowrap">{{ account.name.charAt(0) }}</span>
            </ng-container>
            <ng-container *ngIf="account?.is_manual">
               <span class="fw-semibold d-inline-block text-nowrap">Manual</span>
            </ng-container>
         </div>
         <div class="account-text">
            <span class="d-block account-name lh-1 line-clamp-1">{{
               account.name
            }}</span>
            <span
               *ngIf="account.number"
               class="d-block lh-1 mt-2 account-number"
               >{{ account.number }}</span
            >
         </div>
      </label>
   </div>
</div>
<div
   class="modal-footer p-3 d-flex flex-nowrap align-items-start justify-content-start gap-2 add-another-portfolio"
>
   <svg
      class="flex-shrink-0 d-none d-lg-block"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M6 7V5H15V7H6ZM6 10V8H15V10H6ZM9 20H3C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V9.025C17.6667 8.99167 17.3292 9.00417 16.9875 9.0625C16.6458 9.12083 16.3167 9.225 16 9.375V2H5V14H11L9 16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18H9V20ZM11 20V16.925L16.525 11.425C16.675 11.275 16.8417 11.1667 17.025 11.1C17.2083 11.0333 17.3917 11 17.575 11C17.775 11 17.9667 11.0375 18.15 11.1125C18.3333 11.1875 18.5 11.3 18.65 11.45L19.575 12.375C19.7083 12.525 19.8125 12.6917 19.8875 12.875C19.9625 13.0583 20 13.2417 20 13.425C20 13.6083 19.9667 13.7958 19.9 13.9875C19.8333 14.1792 19.725 14.35 19.575 14.5L14.075 20H11ZM12.5 18.5H13.45L16.475 15.45L16.025 14.975L15.55 14.525L12.5 17.55V18.5ZM16.025 14.975L15.55 14.525L16.475 15.45L16.025 14.975Z"
         fill="#316A56"
      />
   </svg>
   <div class="d-flex m-0 flex-column gap-2 flex-grow-1">
      <div class="d-flex align-items-center justify-content-between gap-2">
         <h5 class="mb-0">Add another Portfolio?</h5>
         <button
            class="btn rounded-pill shadow-none lh-1 text-nowrap add-button"
            (click)="openSupportedBrokeragesModal()"
         >
            ADD PORTFOLIO
         </button>
      </div>
      <p class="mb-0 manual-para">
         Click on the add portfolio button to either link an existing portfolio
         or enter a manual portfolio
      </p>
   </div>
</div>
