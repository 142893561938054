import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../services/subscription.service';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';

@Component({
  selector: 'app-resume-subscription-popup',
  templateUrl: './resume-subscription-popup.component.html',
  styleUrls: ['./resume-subscription-popup.component.scss']
})
export class ResumeSubscriptionPopupComponent implements OnInit {
   constructor(
      private modalService: NgbModal,
      private toaster: ToastrService,
      private subscriptionService: SubscriptionService
   ) {}

   private subscriptions: Subscription[] = [];
   public userRole: string | null = null;
   public subscriptionSource: string | null = null;
   public expiresDate: string | null = null;
   public loading: boolean = false;

   ngOnInit(): void {
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
               this.subscriptionSource = data.subscriptionSource;
               this.expiresDate = data.expiresDate
                  ? new Date(data.expiresDate).toLocaleDateString()
                  : null;
            }
         )
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   dismissPopup() {
      this.modalService.dismissAll();
   }

   proceedToResume() {
      this.loading = true;
      this.subscriptionService
         .resumeSubscription()
         .then((data: any) => {
            if (data.status) {
               // this.toaster.success('Subscription Resumed!');
               location.reload()
               // this.subscriptionService.setStripeSubscriptionInfo();
               this.dismissPopup();
            }
         })
         .catch(() => {
            this.toaster.error('Something went wrong!');
         })
         .finally(() => {
            this.loading = false;
         });
   }
}
