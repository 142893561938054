export const TYPESENSE_COLLECTIONS = {
  COMPANY_PROFILE: 'company_profile_collection_new',
  ETF_PROFILE: 'etf_profile_collection_4',
  USER_RECENTLY_VIEWED: 'user_recently_viewed',
  FUNDAMENTALS: 'fundamentals_collection',
  COMPLIANCE: 'compliance_collection_alias_new',
  RECOMMENDATION: 'recommendation_collection',
  COMPANY_NEWS: 'company_news_collection',
  PRESS_RELEASES: 'press_releases_collection',
  ETF_HOLDINGS: 'etf_holdings_collection_2',
  ETF_COMPLIANCE_DETAILED: 'etf_compliance_detailed_collection_2',
  ETF_SECTOR_EXPOSURE: 'etf_sector_exposure_collection',
  ETF_COUNTRY_EXPOSURE: 'etf_country_exposure_collection',
  INVESTMENT_CHECKLIST: 'investment_checklist_collection',
  ETFS_DATA: 'etfs_data',
  PRICE_TARGET: 'price_target_collection',
  USER_MONTHLY_VIEWED: 'user_monthly_viewed',
  REVENUE_ESTIMATES: 'revenue_estimates_collection',
  EARNINGS_ESTIMATES: 'earnings_estimates_collection',
  FORECASTS: 'forecasts_collection',
  BSE_TICKERS: 'bse_tickers',
  MUSAFFA_SECTOR: 'musaffa_sector_collection',
  MUSAFFA_ETF_CLASS: 'musaffa_etf_class_collection',
  ACADEMY_ARTICLES: 'academy_articles_collection',
  STOCK_BUCKET_52WEEKHIGH: 'stock_bucket_52weekhigh',
  STOCK_BUCKET_52WEEKLOW: 'stock_bucket_52weeklow',
  STOCK_BUCKET_MOSTPOPULAR: 'stock_bucket_mostpopular',
  STOCK_BUCKET_MOSTACTIVE: 'stock_bucket_mostactive',
  STOCK_BUCKET_TOPGAINERS: 'stock_bucket_topgainers',
  STOCK_BUCKET_TOPLOSERS: 'stock_bucket_toplosers',
  PRICE_SOCKET: 'price_socket_collection',
  STOCKS_DATA: 'stocks_data',
  EARNINGS_SURPRISES: 'earnings_surprises_collection',
  EARNINGS_CALENDAR: 'earnings_calendar_collection',
  INVESTMENT_CHECKLIST_VARIABLES: 'investment_checklist_variables',
  MARKET_NEWS: 'market_news_collection',
  MARKET_COLLECTION: 'market_collection',
  SUPER_INVESTORS: 'super_investors',
  SUPER_INVESTORS_PORTFOLIO: 'super_investors_portfolio',
  HALAL_STOCK_BUCKETS: 'halal_stock_buckets',
  INVESTMENT_PROGRESSBAR: 'investment_progressbar',
  WEBSITE: 'website_collection',
};
