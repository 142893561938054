import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})

export class ComplianceHistoryService {
   private newApi = environment.apiUrl;

   private showComplianceHistoryOffcanvasSource = new Subject<void>();
   showComplianceHistoryOffcanvas$ = this.showComplianceHistoryOffcanvasSource.asObservable();

   constructor(
      private http: HttpClient,
   ) {}

   showComplianceHistory() {
      this.showComplianceHistoryOffcanvasSource.next();
   }

   fetchComplianceHistory<T>(ticker: string): Observable<T> {
      return this.http.get<T>(this.newApi + '/api/compliance-history/' + encodeURIComponent(ticker))
   }

}
