import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { AllPermissionsResponse } from 'src/app/models/permissions.model';
// import { getRemoteConfig, getValue, RemoteConfig } from "firebase/remote-config";
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class FirebaseRemoteConfigService {
   constructor(private remote_config: AngularFireRemoteConfig) {
      this.remote_config.fetchAndActivate();
   }

   public async getFreeStocksList() {
      return JSON.parse(await this.remote_config.getString('free_collections'))
         .stocks;
   }

   public async getFreeEtfList() {
      return JSON.parse(await this.remote_config.getString('free_collections'))
         .etfs;
   }

   public async getBucketList() {
      return JSON.parse(
         await this.remote_config.getString('free_buckets_collection')
      );
   }

   public async monthlyFreeCompliance() {
      return JSON.parse(await this.remote_config.getString('config_defaults'))
         ?.monthly_free_stock_count;
   }

   public async activePromotionalOffer() {
      return JSON.parse(await this.remote_config.getString('config_defaults'))
         ?.active_promotional_offer;
   }

   public async getcbaStatusDisclaimer() {
      return JSON.parse(await this.remote_config.getString('config_defaults'))
         ?.cba_status_disclaimer;
   }

   public async countryPricingPlans() {
      let countryPricingPlans = null;
      let userCountry: any = localStorage.getItem('userCountry');
      userCountry = JSON.parse(userCountry);
      var countryList = await JSON.parse(
         await this.remote_config.getString('country_pricing_plans')
      );
      await countryList.forEach((countryPlans: any) => {
         if (userCountry != null && userCountry.length) {
            if (
               countryPlans.country_code === userCountry[0].code.toLowerCase()
            ) {
               countryPricingPlans = countryPlans;
               return;
            }
         }
      });

      if (countryPricingPlans == null) {
         countryPricingPlans = countryList.find(
            (item: any) => item.country_code == 'default'
         );
      }
      return countryPricingPlans;
   }

   public async promoOffersConfig() {
      let promoOffer = {};
      let activePromotionalOfferName = await this.activePromotionalOffer();
      await JSON.parse(
         await this.remote_config.getString('promo_offers_config')
      )?.forEach((offerConfig: any) => {
         if (offerConfig.name == activePromotionalOfferName) {
            promoOffer = offerConfig;
            return;
         }
      });
      return promoOffer;
   }

   public async getConfigDefault() {
      return await JSON.parse(
         await this.remote_config.getString('config_defaults')
      );
   }

   public async getMusaffaAcademy() {
      return await JSON.parse(
         await this.remote_config.getString('musaffa_academy')
      );
   }

   public async getInAppSubscriptionDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('inapp_subscription_defaults')
      );
   }

   public async userOnboardingdefaults() {
      return await JSON.parse(
         await this.remote_config.getString('user_onboarding_defaults')
      );
   }

   public async getPaywallExperiments() {
      return await JSON.parse(
         await this.remote_config.getString('paywall_experiments')
      );
   }

   public async getMarketingBanners() {
      return await JSON.parse(
         await this.remote_config.getString('marketing_banners')
      );
   }

   public async getPurificationDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('purification_defaults')
      );
   }

   public async getPortfolioDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('portfolio_defaults')
      );
   }

   public async getPurificationArticles() {
      return await JSON.parse(
         await this.remote_config.getString('purification_articles')
      );
   }

   public async getPortfolioArticles() {
      return await JSON.parse(
         await this.remote_config.getString('portfolio_articles')
      );
   }

   public async getComplianceData() {
      var complianceJson: any = [
         { key: '', display_value: 'All', isAll: true },
         { key: 'COMPLIANT', display_value: 'Halal' },
         { key: 'NON_COMPLIANT', display_value: 'Not Halal' },
         { key: 'QUESTIONABLE', display_value: 'Doubtful' },
      ];
      return complianceJson;
   }

   public getComplianceScoreData() {
      var complianceScoreJson: any = [
         {
            key: '1',
            display_value: '1',
         },
         {
            key: '2',
            display_value: '2',
         },
         {
            key: '3',
            display_value: '3',
         },
         {
            key: '4',
            display_value: '4',
         },
         {
            key: '5',
            display_value: '5',
         },
      ];
      return complianceScoreJson;
   }

   public getAnalystRatings() {
      var analystRatingsJson: any = [
         {
            key: 'Strong buy',
            display_value: 'Strong Buy',
         },
         {
            key: 'Buy',
            display_value: 'Buy',
         },
         {
            key: 'Hold',
            display_value: 'Hold',
         },
         {
            key: 'Sell',
            display_value: 'Sell',
         },
         {
            key: 'Strong sell',
            display_value: 'Strong Sell',
         },
      ];
      return analystRatingsJson;
   }

   public getSortBy() {
      var sortByJson: any = [
         {
            key: '',
            display_value: 'None',
         },
         {
            key: 'currentPrice:asc',
            display_value: 'Price: Low to High',
         },
         {
            key: 'currentPrice:desc',
            display_value: 'Price: High to Low',
         },
         {
            key: 'percentChange:asc',
            display_value: 'Percent Change: Low to High',
         },
         {
            key: 'percentChange:desc',
            display_value: 'Percent Change: High to Low',
         },
         {
            key: 'compliantRanking:asc',
            display_value: 'Compliance: Low to High',
         },
         {
            key: 'compliantRanking:desc',
            display_value: 'Compliance: High to Low',
         },
      ];
      return sortByJson;
   }

   public getEtfAssetClassData() {
      var etfAssetClassJson: any = [
         { key: 'Equity', display_value: 'Equity' },
         { key: 'Fixed Income', display_value: 'Fixed Income' },
      ];
      // var etfAssetClassJson: any = [{"key":"Equity","display_value":"Equity"},{"key":"Fixed Income","display_value":"Fixed Income"},{"key":"Commodities","display_value":"Commodities"},{"key":"Currency","display_value":"Currency"},{"key":"Asset Allocation","display_value":"Asset Allocation"},{"key":"Alternatives","display_value":"Alternatives"}];
      return etfAssetClassJson;
   }

   public async getFeatureVisibilityDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('feature_visibility_flags')
      );
   }

   public async getPaywallDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('paywall_defaults')
      );
   }

   public async getPromoOffers() {
      return await JSON.parse(
         await this.remote_config.getString('promo_offers_config')
      );
   }

   public async getRequestReportUpdationDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('request_report_updation_defaults')
      );
   }

   public async getCrowdfundingDefaults() {
      return await JSON.parse(
         await this.remote_config.getString('crowdfunding_defaults')
      );
   }

   public async getSubscriptionPlanConfig(): Promise<AllPermissionsResponse> {
      const config =  await JSON.parse(
         await this.remote_config.getString('subscription_plans_config')
      );
      const permissionsResponse: AllPermissionsResponse = {
         plans: config.plans,
         features: config.features
      }
      return permissionsResponse;
   }
}
