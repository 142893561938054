import { createReducer, on } from '@ngrx/store';
import { loadWatchlistSymbols, loadWatchlistSymbolsSuccess, loadWatchlistSymbolsFailure } from './watchlist-symbols.actions';

export interface WatchliistSymbolsState {
  symbols: string[];
  loading: boolean;
  error: any;
}

export const initialState: WatchliistSymbolsState = {
  symbols: [],
  loading: false,
  error: null
};

export const watchlistSymbolsReducer = createReducer(
  initialState,
  on(loadWatchlistSymbols, state => ({
    ...state,
    loading: true,
    error: null
  })),
  on(loadWatchlistSymbolsSuccess, (state, { symbols }) => ({
    ...state,
    symbols,
    loading: false
  })),
  on(loadWatchlistSymbolsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  }))
);
