import { createReducer, on } from '@ngrx/store';
import { loadPermissions, loadPermissionsSuccess, loadPermissionsFailure } from './access-control.actions';

export interface PermissionsState {
  permissions: any;
  loading: boolean;
  isLoaded: boolean;
  error: any;
}

export const initialState: PermissionsState = {
  permissions: {},
  loading: false,
  isLoaded: false,
  error: null
};

export const PermissionsReducer = createReducer(
  initialState,
  on(loadPermissions, state => ({
    ...state,
    loading: true,
    isLoaded: true,
    error: null
  })),
  on(loadPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissions,
    loading: false,
    isLoaded: true
  })),
  on(loadPermissionsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    isLoaded: true
  }))
);
