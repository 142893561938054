import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as branch from 'branch-sdk';
// @ts-expect-error
import moengage from '@moengage/web-sdk';

if (environment.production) {
   enableProdMode();
}

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .catch((err) => console.error(err));

branch.init(environment.branchKey);
moengage.initialize({
   app_id: environment.moengageAppId,
   cluster: 'DC_2',
   debug_logs: environment.moengageDebugLogs,
});

// window.addEventListener('MOE_AUTOMATED_EVENTS', function (event: any) {
//    if (
//       event.detail.name === 'MOE_ONSITE_MESSAGE_CLICKED' &&
//       event.detail.data &&
//       event.detail.data.length
//    ) {
//       // console.log(event.detail.data);
//    }
// });
