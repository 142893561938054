import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from './subscription.service';
import { AuthService } from './auth.service';
import { SharedService } from './shared.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';

@Injectable({
   providedIn: 'root',
})
export class AccessControlService {
   constructor(
      private toaster: ToastrService,
      private auth: AuthService,
      private sharedService: SharedService,
      private permissionStore: Store<{ permissions: PermissionsState }>
   ) {
      this.permissionStore
         .select(({ permissions }) => permissions)
         .subscribe((permissions) => {
            this.isPermissionFirstLoaded = permissions.isLoaded;
            this.isPermissionLoading = permissions.loading;
         });
   }

   private isPermissionFirstLoaded: boolean = false;
   private isPermissionLoading: boolean = false;
   // private isCheckInProcess: boolean = false;

   async accessDenied() {
      if (!this.auth.getUserId()) {
         this.auth.openAuthPopup();
         return
      }
      // this.isCheckInProcess = true;
      // await this.until(() => this.isPermissionFirstLoaded === true);
      if (this.auth.getUserId() && this.isPermissionFirstLoaded && !this.isPermissionLoading) {
         this.sharedService.openUpgradePopup();
         // if(SubscriptionService.subscriptionData.value?.isSubscribed) {
         //    // this.toaster.error('Subscription Upgrade Required');
         // } else {
         //    // this.toaster.error('Subscription Required');
         //    this.sharedService.openUpgradePopup()
         // }
      }
   }

   until(conditionFunction: () => any) {
      const poll = (resolve: any) => {
         if (conditionFunction()) resolve();
         else setTimeout(() => poll(resolve), 400);
      };
      return new Promise(poll);
   }
}
