import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, Hit, MarketCollectionResponse } from 'src/app/models/market-collection/market-collection.model';
import { TYPESENSE_COLLECTIONS } from '../constants/typesense-collections';

@Injectable({
   providedIn: 'root'
})

export class CountryService {
   private countries$!: Observable<Country[]>;

   private selectedCountrySubject: BehaviorSubject<Country>;
   public selectedCountry$: Observable<Country>;

   constructor(private http: HttpClient) {
      const defaultCountry: Country = {
         country: 'United States',
         countryCode: 'US',
         detailedProfileAvailable: true,
         etfEnabled: true,
         etfExchanges: ['NYSE ARCA', 'NASDAQ'],
         etfsCovered: true,
         id: 'US',
         sortOrder: 1,
         stockEnabled: true,
         stockExchanges: ['NYSE', 'NASDAQ'],
         stocksCovered: true
      };
      this.selectedCountrySubject = new BehaviorSubject<Country>(defaultCountry);
      this.selectedCountry$ = this.selectedCountrySubject.asObservable();
   }

   getCountries(): Observable<Country[]> {
      this.countries$ = this.countries$ ?? this.fetchCountries().pipe(shareReplay());
      return this.countries$;
   }

   private fetchCountries(): Observable<Country[]> {
      const queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return this.http.get<MarketCollectionResponse>(`${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.MARKET_COLLECTION}/documents/search`, { params: queryParams }).pipe(
         map((response) => response.hits.map((hits: Hit) => hits.document) as Country[])
      );
   }

   setSelectedCountry(country: Country): void {
      localStorage.setItem('dataFilterCountry', country.countryCode);
      this.selectedCountrySubject.next(country);
   }

   getSelectedCountry(): Country {
      return this.selectedCountrySubject.value;
   }
}


// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';

// @Injectable({
//    providedIn: 'root'
// })

// export class CountryService {
//    private selectedCountrySubject = new BehaviorSubject<string>('US');
//    selectedCountry$ = this.selectedCountrySubject.asObservable();

//    constructor() {}

//    setSelectedCountry(country: string): void {
//       this.selectedCountrySubject.next(country);
//    }
// }
