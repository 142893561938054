import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sumPipe'
})
export class SumPipe extends CurrencyPipe implements PipeTransform {

	transform(value: any, currancy: string): any {
		if(value == "-")
			return "-"
		let symbol;
		switch (currancy) {
			case 'MYR':
				symbol = 'RM';
				break;
			case 'GBP':
				symbol = '£';
				break;
			case 'SGD':
				symbol = 'SGD';
				break;
			case 'CAD':
				symbol = 'CAD';
				break;
			case 'IDR':
				symbol = 'Rp';
				break;
			case 'EUR':
				symbol = '€';
				break;
			case 'USD':
				symbol = '$';
				break;
			case 'QAR':
				symbol = 'QR';
				break;
			case 'BHD':
				symbol = 'BD';
				break;
			case 'INR':
				symbol = '₹';
				break;
			// case 'SAR':
			// 	symbol = 'Riyal';
			// 	break;
			// case 'AED':
			// 	symbol = 'Dirham';
			// 	break;
			default:
				symbol = currancy;
				break;
		}
		if (value) {

			value = super.transform(value);
			value = value.replace('$', '');



			if (value < 0) {
				value = '-' + symbol  + value * -1;
			}
			else {
				value = symbol  + value;
			}


			return value;
		}
		else {
			return symbol + '0';
		}

	}

}
