import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-limit-reached-popup',
  templateUrl: './limit-reached-popup.component.html',
  styleUrls: ['./limit-reached-popup.component.scss']
})
export class LimitReachedPopupComponent implements OnInit {

  @Input() title: string = '';
  @Input() message: string = '';
  @Input() canUpgrade: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
