import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { MyPortfolioService } from '../../services/my-portfolio.service';

@Component({
  selector: 'app-connect-portfolio-popup',
  templateUrl: './connect-portfolio-popup.component.html',
  styleUrls: ['./connect-portfolio-popup.component.scss']
})
export class ConnectPortfolioPopupComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   userNewRole: Role = null;
   maxManualPortfoliosCount: number = 0;
   maxBrokeragePortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;
   currentBrokeragePortfoliosCount: number = 0;

   constructor(
      private sharedService: SharedService,
      private portfolioService: PortfolioService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private myPortolioService: MyPortfolioService
   ) { }

   ngOnInit(): void {
      this.permissionStore.select(({ permissions }) => permissions.permissions).pipe(takeUntil(this.destroy$)).subscribe((permissions) => {
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
            this.maxBrokeragePortfoliosCount = permissions.brokerage_connected_portfolios_count;

            // console.log(permissions)
         })

      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userNewRole = data.newRole;
      });

      // this.getAccountsCount()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getAccountsCount() {
      this.portfolioService.getAccountCounts().pipe(takeUntil(this.destroy$)).subscribe((data) => {
         this.currentManualPortfoliosCount = data.manual_connected_count > this.maxManualPortfoliosCount ? this.maxManualPortfoliosCount : data.manual_connected_count;
         this.currentBrokeragePortfoliosCount = data.brokerage_connected_count > this.maxBrokeragePortfoliosCount ? this.maxBrokeragePortfoliosCount : data.brokerage_connected_count;
      })
   }

   openBrokeragesPopup() {
      this.portfolioService.closeConnectPortfolioPopup()

      if(this.currentBrokeragePortfoliosCount >= this.maxBrokeragePortfoliosCount) {
         this.sharedService.openUpgradePopup('portfolio');
      } else {
         this.portfolioService.openSupportedBrokeragesModal(true);
      }
   }

   openManualPopup() {
      this.portfolioService.closeConnectPortfolioPopup()

      if(this.currentManualPortfoliosCount < this.maxManualPortfoliosCount) {
         this.myPortolioService.openCreateManualPortfolioPopup();
      } else {
         this.portfolioService.openMessagePopup('manual')
      }
   }

   closeConnectPopup(){
      this.portfolioService.closeConnectPortfolioPopup()
   }

}
