import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WebinarPopupComponent } from '../components/webinar-popup/webinar-popup.component';
import { UpgradePopupComponent } from '../components/upgrade-popup/upgrade-popup.component';
import { AnalyticsUtils } from './analytics-utils';
import { HoldingDetailsPopupComponent } from '../components/holding-details-popup/holding-details-popup.component';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';
import {
   BrokerageCoverageApiResponse,
   CoverageBrokerage,
} from 'src/app/features/pricing/models/brokerage-coverage.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DisclaimerPopupComponent } from '../components/disclaimer-popup/disclaimer-popup.component'
import { LimitReachedPopupComponent } from '../components/limit-reached-popup/limit-reached-popup.component';
import { EnablePersonalizedAssistancePopupComponent } from '../components/enable-personalized-assistance-popup/enable-personalized-assistance-popup.component';

@Injectable({
   providedIn: 'root',
})
export class SharedService {
   private upgradeModalRef: NgbModalRef | undefined;
   private holdingDetailsPopupRef: any;
   private covrageBrokerages$: Observable<BrokerageCoverageApiResponse> | null =
      null;
   url: string = environment.apiUrl;

   private limitReachedModalRef: NgbModalRef | undefined;
   private personalizedAssistanceModalRef: NgbModalRef | undefined;

   constructor(
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils,
      private http: HttpClient
   ) {}

   openWebinarModal() {
      this.modalService.open(WebinarPopupComponent, {
         centered: true,
         modalDialogClass: 'webinar-popup-modal',
         size: 'lg',
      });
   }

   openUpgradePopup(
      feature?: 'report' | 'portfolio' | 'buySell' | 'brokerage'
   ) {
      this.upgradeModalRef = this.modalService.open(UpgradePopupComponent, {
         centered: true,
         scrollable: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'global-modal',
         size: 'xl',
      });
      this.upgradeModalRef.componentInstance.activefeature = feature;
   }

   closeUpgradePopup() {
      if (this.upgradeModalRef) {
         this.upgradeModalRef.close();
      }
   }

   openHoldingDetailsPopup(holding: any) {
      this.analyticsUtils.portfolio_holding_item_tapped(holding.symbol);
      this.holdingDetailsPopupRef = this.modalService.open(
         HoldingDetailsPopupComponent,
         {
            centered: true,
         }
      );
      this.holdingDetailsPopupRef.componentInstance.holding = holding;
   }

   getBrokerageCoverages(): Observable<BrokerageCoverageApiResponse> | null {
      this.covrageBrokerages$ =
         this.covrageBrokerages$ ??
         this.fetchBrokeragesCoverage().pipe(shareReplay());
      return this.covrageBrokerages$;
   }

   refetchBrokerageCoverages(): Observable<BrokerageCoverageApiResponse> {
      this.covrageBrokerages$ = null; // Clear the cache
      this.covrageBrokerages$ = this.fetchBrokeragesCoverage().pipe(
         shareReplay()
      ); // Re-fetch the dat
      return this.covrageBrokerages$;
   }

   fetchBrokeragesCoverage() {
      return this.http
         .get<BrokerageCoverageApiResponse>(
            `${this.url}/api/trading/pricing/brokerage-coverage`
         )
         .pipe(
            map((response) => response),
            catchError((error) => {
               console.error('Error fetching portfolio list', error);
               return throwError(() => error);
            })
         );
   }

   openDisclaimerPopup() {
      const modalRef = this.modalService.open(DisclaimerPopupComponent, {
         centered: true,
         scrollable: true,
         backdrop: 'static',
         backdropClass: 'global-modal-backdrop',
         windowClass: 'global-modal',
         size: 'lg',
      });

      return modalRef.result.then(
         () => {},
         () => {}
      );
   }

   openLimitReachedPopup(title: string, message: string, canUpgrade: boolean) {
      this.limitReachedModalRef = this.modalService.open(LimitReachedPopupComponent, {
         centered: true,
         scrollable: true,
         backdrop: 'static',
         backdropClass: 'limit-reached-modal-backdrop',
         windowClass: 'limit-reached-modal',
         size: 'md',
      });
      this.limitReachedModalRef.componentInstance.title = title;
      this.limitReachedModalRef.componentInstance.message = message;
      this.limitReachedModalRef.componentInstance.canUpgrade = canUpgrade;
   }

   closeLimitReachedPopup() {
      if (this.limitReachedModalRef) {
         this.limitReachedModalRef.close();
      }
   }

   openPersonalizedAssistancePopup() {
      this.personalizedAssistanceModalRef = this.modalService.open(EnablePersonalizedAssistancePopupComponent, {
         centered: true,
         // scrollable: true,
         backdrop: 'static',
         backdropClass: 'global-modal-backdrop',
         windowClass: 'persolalized-assistance-popup',
         size: 'lg'
      });
   }

   closePersonalizedAssistancePopup() {
      if (this.personalizedAssistanceModalRef) {
         this.personalizedAssistanceModalRef.close();
      }
   }

   closeAllPopup() {
      this.closeUpgradePopup();
      this.closeLimitReachedPopup();
      this.closePersonalizedAssistancePopup();
      this.modalService.dismissAll();
   }

   afterSignUpActions(email: string)
   {
      this.sendReferralEmail(email);
   }

   sendReferralEmail(email: string): void {
      //@ts-ignore
      window.fpr('referral', { email });
    }
}
