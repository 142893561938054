import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';

interface Reason {
   key: string;
   display_value: string;
}

@Component({
   selector: 'app-cancel-subscription-popup',
   templateUrl: './cancel-subscription-popup.component.html',
   styleUrls: ['./cancel-subscription-popup.component.scss'],
})
export class CancelSubscriptionPopupComponent implements OnInit {
   @Input() priceId: string | null = null;

   constructor(
      private modalService: NgbModal,
      private toaster: ToastrService,
      private subscriptionService: SubscriptionService,
      private remoteConfig: FirebaseRemoteConfigService
   ) {
      this.cancellationForm = new FormGroup({
         cancellationReason: new FormControl('', [Validators.required]),
         cancellationOtherReason: new FormControl(''),
      });
   }

   private subscriptions: Subscription[] = [];
   public reasons: Reason[] = [];
   // public reasons: String[] = [
   //    'Too expensive',
   //    'Not enough features',
   //    'Poor user experience',
   //    'Found the data incomplete/inaccurate',
   //    'The need was temporary',
   //    'Found better alternative',
   //    'Other',
   // ];
   public userRole: string | null = null;
   public subscriptionSource: string | null = null;
   public subscriptionCancellationReason: string = '';
   public cancellationOtherReason: string = '';
   public expiresDate: string | null = null;
   public loading: boolean = false;
   public cancellationForm: FormGroup;

   ngOnInit(): void {
      this.getCancellationReasons();
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
               this.subscriptionSource = data.subscriptionSource;
               this.expiresDate = data.expiresDate
                  ? new Date(data.expiresDate).toLocaleDateString()
                  : null;
            }
         )
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   dismissPopup() {
      this.modalService.dismissAll();
   }

   setOtherValidation() {
      this.cancellationForm.controls.cancellationOtherReason.setValidators(
         this.cancellationForm.value.cancellationReason !== 'other'
            ? null
            : [Validators.required]
      );
      this.cancellationForm.controls.cancellationOtherReason.updateValueAndValidity();
   }

   getCancellationReasons() {
      this.remoteConfig.getInAppSubscriptionDefaults().then((data) => {
         // console.log(data);
         this.reasons = data.cancellation_reasons;

         console.log('logging from resons', this.reasons);
      });
   }

   proceedToCancel(form: FormGroup) {
      if (!form.valid) {
         return;
      }

      const cancellationReason: string = form.value.cancellationReason;
      const cancellationOtherReason: string =
         form.value.cancellationOtherReason ?? '';
      this.loading = true;

      const cancelSubscription = this.subscriptionService.cancelSubscription();
      // this.priceId
      //    ? this.subscriptionService.cancelSubscriptionWithPriceId(this.priceId)
      //    :

      cancelSubscription
         .then((data: any) => {
            if (data.status) {
               this.handleSuccessfulCancellation(
                  cancellationReason,
                  cancellationOtherReason
               );
            }
         })
         .catch(this.handleCancellationError)
         .finally(() => {
            this.loading = false;
         });
   }

   private handleSuccessfulCancellation(
      cancellationReason: string,
      cancellationOtherReason: string
   ) {
      this.subscriptionService
         .cancelSubscriptionReason(cancellationReason, cancellationOtherReason)
         .then(() => {
            this.toaster.info('Subscription cancelled');
            location.reload();
            this.dismissPopup();
         });
   }

   private handleCancellationError = () => {
      this.toaster.error('Something went wrong!');
   };
}
