import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'screaningMillionPipe' })

export class screaningMillionPipe implements PipeTransform {
  transform(value: any,): any {
    // value = value
    if(value == null || value == undefined)
      return "--"
    let absValue = Math.abs(value)
  
    if (absValue < 1000) {
      return value.toFixed(2)
    }
    if (absValue >= 1000 && absValue < 999999) {
      return (value / 1000).toFixed(2) + 'K'
    }

    if (absValue >= 1000000 && absValue < 999999999) {
      return (value / 1000000).toFixed(2) + 'M'
    }

    if (absValue >= 1000000000 && absValue < 999999999999) {
      return (value / 1000000000).toFixed(2) + 'B'
    }

    // if (absValue >= 1000000000000 && absValue < 999999999999999) {
    //   return (value / 1000000000000).toFixed(2) + 'T'
    // }

    if (absValue >= 1000000000000) {
      return (value / 1000000000000).toFixed(2) + 'T'
    }
  }
}
