import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { CountryCodeService } from './phone-code.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
   providedIn: 'root',
})
export class ApiService {
   private newApi = environment.apiUrl;
   private api = environment.oldServerApiUrl;
   private purificationApi = environment.purificationApiUrl;
   constructor(
      private http: HttpClient,
      private auth: AuthService,
      private authFire: AngularFireAuth,
      private countryCodeService: CountryCodeService,
      private firestoreService: FirestoreService
   ) {}

   public selectedCurrency = '';

   setSelectedCurrency(currency: string) {
      this.selectedCurrency = currency;
   }

   // getSelectedCurrency() {
   //    return this.selectedCurrency.asObservable();
   // }

   //charts-api
   // public stockCandles(body: any): any {
   //    return this.http.post<any>(this.api + '/api/v1/stocks/candles', body);
   // }

   public stockCandles(body: any): any {
      return this.http.post<any>(this.newApi + '/api/stock/candles', body);
   }
   //etf charts
   public etfCandles(body: any): any {
      return this.http.post<any>(this.api + '/api/v1/etf/candles', body);
   }

   // Stripe payment

   public stripePay(data: any): any {
      return this.http.post<any>(
         this.api + '/api/v1/stripe/do-subscription',
         data
      );
   }

   //stripe-api

   public stripeMembership(data: any): any {
      return this.http.post<any>(
         this.api + '/api/v1/stripe/do-subscription',
         data
      );
   }
   // refresh token

   public refreshToken(data: any): any {
      return this.http.post<any>(this.api + '/api/v1/auth/refreshToken', data);
   }

   //Analitcs request
   public requestAnalitcs(stockName: any, companyName: any): any {
      return this.http.post<any>(this.api + '/api/v1/notification/task', {
         stockName: stockName,
         companyName: companyName,
      });
   }

   //Etf-market-cap-breakdown
   public etfMarketCap(symbol: string) {
      return this.http.get<any>(
         this.api + '/api/v1/etf/market-cap-breakdown/' + symbol
      );
   }

   public async purificationLimitCheck() {
      const timezoneOffset = -1 * new Date().getTimezoneOffset();
      let params = {
         timeZoneOffset: timezoneOffset,
      };
      return await lastValueFrom(
         this.http.post<any>(
            this.newApi + '/api/purification/check-limit',
            params
         )
      );
   }

   public async purificationCalculation(
      symbol: any,
      count: any,
      start: any,
      end: any
   ): Promise<any> {
      // const endPoint = `${this.merlinApi}/etfcompliance/purification/${symbol}/${count}/${start}/${end}`;
      // const data = await lastValueFrom(
      //   this.http.get<any>(endPoint)
      // );
      // return data;

      const timezoneOffset = -1 * new Date().getTimezoneOffset();

      let params = {
         Ticker: symbol,
         NumberOfShares: count,
         BuyDate: start,
         SellDate: end,
         timeZoneOffset: timezoneOffset,
      };
      return await lastValueFrom(
         this.http.post<any>(this.newApi + '/api/purification/v2', params, {
            // headers: new HttpHeaders({
            //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiVVNFUixQUkVNSVVNIiwiaWQiOiI0MTE3NzM3Yy0zMDQ0LTRmZjctODljNi1jN2I4YmUwMWVkZTgiLCJzdWIiOiJhYmR1cmFzaGlkLmxAbXVzYWZmYS5jb20iLCJqdGkiOiI0MTE3NzM3Yy0zMDQ0LTRmZjctODljNi1jN2I4YmUwMWVkZTgiLCJpYXQiOjE2Njg2NTg3MDIsImV4cCI6MTY2ODc0NTEzMn0.bHmJJkzc3XAnctSrgTRnogXPYK8f5N3w0VoHoOD4AGE',
            // })
         })
      );
   }

   public async purificationSampleCalculation(): Promise<any> {
      return await lastValueFrom(
         this.http.get<any>(this.newApi + '/api/purification/example')
      );
   }

   public async updateComplianceChangeRequest(ticker: string): Promise<any> {
      return await lastValueFrom(
         this.http.post<any>(
            this.newApi + '/api/user/compliance-update/store',
            { ticker }
         )
      );
   }

   public getUserCountry(): any {
      return this.http.get<any>(
         'https://api.ipregistry.co/?key=r4k1ipy7id5o6u8u&fields=location.country.name,location.country.code'
      );
   }



   async getRelatedStocks(ticker: string, country: string, gsubind: string): Promise<any>  {
      return await lastValueFrom(
         this.http.post<any>(this.newApi + '/api/get-related-stocks', {
            ticker,
            country,
            gsubind
         })
      );
   }

   async getUserCountryCode() {
      return await lastValueFrom(this.http.get<any>(this.newApi + '/api/country'));
   }

   async getAreSymbolsCovered(symbols: string[], type: string) {
      return await lastValueFrom(this.http.post<any>(this.newApi + `/api/user/are-symbols-covered`, {symbols, type}));
   }

   async getStockComplianceMeta(ticker: string) {
      return await lastValueFrom(this.http.post<any>(this.newApi + `/api/user/compliance-report-meta`, {ticker, type: 'stock'}));
   }

   async getEtfComplianceMeta(ticker: string) {
      return await lastValueFrom(this.http.post<any>(this.newApi + `/api/user/compliance-report-meta`, {ticker, type: 'etf'}));
   }

   async getSampleStockComplianceInfo() {
      return await lastValueFrom(this.http.get<any>(this.newApi + `/api/user/get-detailed-compliance-sample-report/stock`));
   }

   async getSampleEtfComplianceInfo() {
      return await lastValueFrom(this.http.get<any>(this.newApi + `/api/user/get-detailed-compliance-sample-report/etf`));
   }

   async getStockComplianceInfo(ticker: string) {
      return await lastValueFrom(this.http.post<any>(this.newApi + `/api/user/get-stock-detailed-compliance-report`, {ticker}));
   }

   async getEtfComplianceInfo(ticker: string) {
      return await lastValueFrom(this.http.post<any>(this.newApi + `/api/user/get-etf-detailed-compliance-report`, {ticker}));
   }
}
