<div class="modal-body p-0">
   <section class="upgrade-grid">
      <button type="button" class="btn shadow-none p-1 bg-transparent rounded-3 modal-header-close-btn" aria-label="Close" (click)="activeUpgradeModal.close()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7e7e7e" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>

      <div class="feature-images-wrapper overflow-hidden">
         <div class="d-lg-none">
            <img src="/assets/images/upgrade/Pro.png" class="img-fluid premium-logo" alt="Premium Logo">
            <h3 class="upgrade-heading fw-600">
               Unlock the full potential of Musaffa
            </h3>
         </div>

         <div class="carousel mx-auto" #carousel>
            <article class="carousel-slides" [style.transform]="translateX">
               <div class="slide" *ngFor="let feature of feature">
                  <img [src]="feature.imageUrl" class="img-fluid mx-auto" [alt]="feature.description" draggable="false">
               </div>
            </article>
         </div>
         <div class="dots mt-3">
            <span 
               class="dot" 
               *ngFor="let feature of feature" 
               [class.active]="feature.id === currentId"
               (click)="goToSlide(feature.id)">
            </span>
         </div>
      </div>
      
      <div class="upgrade-btns">
         <div class="d-none d-lg-block">
            <img src="/assets/images/upgrade/Pro.png" class="img-fluid premium-logo" alt="Premium Logo">
            <h3 class="upgrade-heading fw-700">
               Unlock the full potential of Musaffa
            </h3>
         </div>

         <ul class="d-grid gap-2 gap-lg-3 feature-points-list" role="list">
            <li *ngFor="let feature of feature" class="d-flex align-items-center gap-2 gap-lg-3 rounded-pill overflow-hidden feature-points-item" [ngClass]="feature.id === currentId ? 'active-item' : ''"  (click)="goToSlide(feature.id)">
               <img src="/assets/icons/gradient-tick-icon.svg" class="img-fluid tick-icon" alt="tick-icon" />
               <p class="mb-0">{{ feature.description }}</p>
            </li>
         </ul>
         
         <button type="button" class="gradient-btn btn shadow-none py-2 d-flex align-items-center justify-content-center px-4 mt-4 rounded-3 pricing-btn text-white" (click)="navigateToPricing()">
            View Pricing Plans
         </button>
      </div>
   </section>
</div>










<!-- <div class="modal-body p-0">
   <div class="carousel" #carousel>
      <div class="carousel-slides" [style.transform]="'translateX(' + (-this.currentIndex * 100) + '%)'">
         <div class="slide" *ngFor="let slide of slides" draggable="false">
               <img [src]="slide.img" class="img-fluid" alt="" draggable="false">
         </div>
      </div>
    </div>
    <div class="dots">
      <span 
        class="dot" 
        *ngFor="let slide of slides; let i = index" 
        [class.active]="i === currentIndex"
        (click)="goToSlide(i)">
      </span>
    </div>
</div> -->


