import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { Currency } from 'src/app/models/zakat.model';
import { ZakatService } from 'src/app/shared/services/zakat.service';
import { ManualPortfolioResponse } from 'src/app/models/portfolio/manual-portfolio.model';
import { Router } from '@angular/router';
import { MyPortfolioService } from '../../services/my-portfolio.service';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';

@Component({
   selector: 'app-create-manual-portfolio-popup',
   templateUrl: './create-manual-portfolio-popup.component.html',
   styleUrls: ['./create-manual-portfolio-popup.component.scss'],
})
export class CreateManualPortfolioPopupComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   currenciesLoader: boolean = false;
   manualPortfolioLoader: boolean = false;
   manualPortfolioForm!: FormGroup;
   currencies: Currency[] = [];
   readonly NoWhitespaceRegExp: RegExp = new RegExp('\\S');

   constructor(
      public createPopupModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      private zakatService: ZakatService,
      private myPortfolioService: MyPortfolioService,
      private portfolioService: PortfolioService,
      private router: Router
   ) {}

   ngOnInit(): void {
      this.manualPortfolioForm = this.formBuilder.group({
         currency: ['USD', Validators.required],
         manualPortfolioName: [
            '',
            [
               Validators.required,
               Validators.minLength(1),
               Validators.pattern(this.NoWhitespaceRegExp),
            ],
         ],
      });

      this.fetchCurrencyCodes();
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   fetchCurrencyCodes() {
      this.currenciesLoader = true;
      this.zakatService
         .getCurrencyCodes()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.currencies = response.data;
               this.currenciesLoader = false;
            },
            error: (error) => {
               console.error(error);
            },
         });
   }

   addManualPortfolio() {
      this.manualPortfolioLoader = true;

      const formData = {
         name: this.manualPortfolioForm.value.manualPortfolioName.trim(),
         currency: this.manualPortfolioForm.value.currency,
      };

      this.myPortfolioService
         .createManualPortfolio<ManualPortfolioResponse>(
            formData.name,
            formData.currency
         )
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               if (response.status) {
                  const portfolios$ = this.portfolioService.refetchPortfoliosList();
                  const accountCounts$ = this.portfolioService.refetchAccountCounts();

                  lastValueFrom(combineLatest([portfolios$, accountCounts$]))
                     .then(() => {
                        this.myPortfolioService.closeCreateManualPortfolioPopup();
                        this.manualPortfolioLoader = false;
                        this.myPortfolioService.setManualPortfolioName(
                           response.data.name
                        );
                        this.router.navigate([
                           '/portfolio-dashboard/manual/' + response.data.id,
                        ]);
                     })
                     .catch((error) => {
                        console.error('Error while fetching portfolios and account counts:', error);
                        this.manualPortfolioLoader = false;
                     });
               }
            },
            error: (error) => {
               console.error(error);
               this.manualPortfolioLoader = false;
            },
         });
   }
}
