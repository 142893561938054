import { createAction, props } from '@ngrx/store';

export const loadWatchlistSymbols = createAction('[WatchlistSymbols] Load WatchlistSymbols');
export const loadWatchlistSymbolsSuccess = createAction(
  '[WatchlistSymbols] Load WatchlistSymbols Success',
  props<{ symbols: string[] }>()
);
export const loadWatchlistSymbolsFailure = createAction(
  '[WatchlistSymbols] Load WatchlistSymbols Failure',
  props<{ error: any }>()
);
