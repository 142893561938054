import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PagesGuard implements CanActivate {
  constructor(public router: Router, private auth:AuthService, private cookie:CookieService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    // if (this.auth.checkRole('ANONYMOUS')) {
    //   this.router.navigate(['/authentication/registration'],{queryParams:{'redirectURL':state.url}})
    //   return false
    // }
    return true
  }
}
