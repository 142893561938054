<article class="border-0 d-block">
   <div class="modal-body rounded-0 position-relative border-0 p-0">
      <a class="p-0 btn d-block shadow-none rounded-0" href="https://forms.gle/X8dxYmNtvwA1Dd1W6" target="_blank">
         <img src="/assets/images/webinar-banner.jpg" class="d-block img-fluid" alt="Webinar Image">
      </a>
      <button class="position-absolute top-0 end-0 mt-1 me-1 mt-md-2 me-md-2 p-0 btn rounded-circle shadow" (click)="activeModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 512 512">
            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
         </svg>
      </button>
   </div>
</article>
