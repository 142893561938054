import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.scss']
})
export class AuthPopupComponent implements OnInit {

   @Input() action: string | null = null;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public formName: string = 'signIn';

  public formChange(event: any)
  {
   this.formName = event;
  }

}
