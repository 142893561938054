import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, timer } from 'rxjs';
import { Router } from '@angular/router';

interface Feature {
   id: string,
   imageUrl: string,
   description: string
}

@Component({
   selector: 'app-upgrade-popup',
   templateUrl: './upgrade-popup.component.html',
   styleUrls: ['./upgrade-popup.component.scss'],
})
export class UpgradePopupComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   @Input() activefeature!: 'report' | 'portfolio' | 'buySell' | 'brokerage';

   feature: Feature[] = [
      {
         id: 'report',
         imageUrl: '/assets/images/upgrade/screener.png',
         description: 'Find out why a stock is Halal or Not Halal!'
      },
      {
         id: 'portfolio',
         imageUrl: '/assets/images/upgrade/portfolio.png',
         description: 'Screen your portfolio & start making it shariah compliant'
      },
      {
         id: 'buySell',
         imageUrl: '/assets/images/upgrade/buy-sell.png',
         description: 'Buy & Sell via supported brokerages'
      },
      {
         id: 'brokerage',
         imageUrl: '/assets/images/upgrade/brokerages.png',
         description: 'Purify your investments'
      },
   ];
   

   currentId: string = this.feature[0].id;
   isDragging = false;
   startX = 0;
   endX = 0;
   autoSlideInterval: any;
   isPaused = false;

   @ViewChild('carousel', { static: true }) carousel!: ElementRef;

   constructor(
      public activeUpgradeModal: NgbActiveModal,
      private router: Router,
   ) {}

   ngOnInit(): void {
      if (this.activefeature) {
         this.currentId = this.activefeature;
      } else {
         this.currentId = this.feature[0].id;
      }

      timer(3000).subscribe(() => this.startAutoSlide());
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   get translateX() {
      const index = this.feature.findIndex(f => f.id === this.currentId);
      return `translateX(${-index * 100}%)`;
   }

   startAutoSlide() {
      if (this.autoSlideInterval) return; // Prevent multiple intervals

      this.autoSlideInterval = setInterval(() => {
         if (!this.isPaused) {
            this.nextSlide();
         }
      }, 3000); // Change slide every 3 seconds
   }

   stopAutoSlide() {
      if (this.autoSlideInterval) {
         clearInterval(this.autoSlideInterval);
         this.autoSlideInterval = null;
      }
   }

   @HostListener('dragstart', ['$event'])
   onDragStart(event: DragEvent) {
      event.preventDefault(); // Prevent default drag behavior
   }
 
   @HostListener('mousedown', ['$event'])
   onMouseDown(event: MouseEvent) {
      if (this.isInsideCarousel(event)) {
         this.isDragging = true;
         this.startX = event.clientX;
      }
   }
 
   @HostListener('mouseup', ['$event'])
   onMouseUp(event: MouseEvent) {
      if (!this.isDragging) return;
   
      this.isDragging = false;
      this.endX = event.clientX;
      this.handleSwipe();
   }

   @HostListener('mouseenter')
   onMouseEnter() {
      this.isPaused = true; 
   }
 
   @HostListener('mouseleave')
   onMouseLeave() {
      this.isDragging = false;
      this.isPaused = false;
   }
 
   @HostListener('mousemove', ['$event'])
   onMouseMove(event: MouseEvent) {
      if (this.isDragging) {
         // Optionally handle dragging visuals here
      }
   }
 
   @HostListener('touchstart', ['$event'])
   onTouchStart(event: TouchEvent) {
      if (this.isInsideCarousel(event.touches[0])) {
         this.isDragging = true;
         this.startX = event.touches[0].clientX;
      }
   }
 
   @HostListener('touchend', ['$event'])
   onTouchEnd(event: TouchEvent) {
      if (!this.isDragging) return;
   
      this.isDragging = false;
      this.endX = event.changedTouches[0].clientX;
      this.handleSwipe();
   }
 
   handleSwipe() {
      const diffX = this.endX - this.startX;
      if (diffX > 50) {
         this.prevSlide();
      } else if (diffX < -50) {
         this.nextSlide();
      }
   }
 
   nextSlide() {
      const currentIndex = this.feature.findIndex(f => f.id === this.currentId);
      const nextIndex = (currentIndex + 1) % this.feature.length;
      this.currentId = this.feature[nextIndex].id;
   }

   prevSlide() {
      const currentIndex = this.feature.findIndex(f => f.id === this.currentId);
      const prevIndex = (currentIndex - 1 + this.feature.length) % this.feature.length;
      this.currentId = this.feature[prevIndex].id;
   }

   goToSlide(id: string) {
      this.currentId = id;
   }
 
   // Check if the event is inside the carousel
   private isInsideCarousel(event: MouseEvent | Touch): boolean {
      const carouselElement = this.carousel.nativeElement;
      const { left, top, right, bottom } = carouselElement.getBoundingClientRect();
      
      return (
         event.clientX >= left &&
         event.clientX <= right &&
         event.clientY >= top &&
         event.clientY <= bottom
      );
   }

   navigateToPricing() {
      this.activeUpgradeModal.close();
      timer(10).pipe(takeUntil(this.destroy$)).subscribe(() => {
         this.router.navigate(['/pricing']);
      });
   }
}

