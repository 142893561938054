<section class="centered">
   <header class="heading">
      <article class="p-3 p-lg-4 article">
         <h4>Resume Subscription</h4>
      </article>
   </header>
   <div class="px-3 px-lg-4 cancel-content">
      <div>
         <div class="mb-4" *ngIf="subscriptionSource === 'stripe'">
            <!-- <br> -->
            <!-- Your continued trust in our services is greatly appreciated. -->
            <div class="mb-2">
               Thank you for choosing to resume your subscription with Musaffa!
               We are thrilled to have you back on board.
            </div>
            <div>
               Upon resuming, your subscription will auto-renew at the end of the current billing cycle:
               <b>{{expiresDate}}</b>.
            </div>
         </div>
      </div>
   </div>
   <div class="p-3 p-lg-4">
      <div class="d-flex align-items-center justify-content-center btns-container">
         <!-- <button type="button"
            class="btn shadow-none rounded-pill card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3">
            <span>Go Back</span>
         </button> -->
         <!-- <button type="button"
            class="btn shadow-none rounded-pill card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3">
            <span>Cancel Subscription</span>
         </button> -->
         <button [disabled]="loading" class="btn mx-1 shadow-none modal-btn modal-btn-cancel rounded" (click)="dismissPopup()">
            <span>Go Back</span>
         </button>
         <button [disabled]="loading" *ngIf="subscriptionSource === 'stripe'" class="btn mx-1 shadow-none modal-btn modal-btn-confirm rounded" (click)="proceedToResume()">
            <span *ngIf="!loading">Resume Subscription</span>
            <div class="d-flex justify-content-center" *ngIf="loading">
               <div class="spinner-border spinner-border-sm" role="status" style="margin-top: 4px; margin-bottom: 4px;">
                 <span class="visually-hidden">Loading...</span>
               </div>
           </div>
         </button>
      </div>
   </div>
</section>
