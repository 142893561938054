import { Injectable } from '@angular/core';
import {
   Firestore,
   collectionData,
   collection,
   getFirestore,
   FirestoreModule,
} from '@angular/fire/firestore';
import {
   FirebaseApp,
   FirebaseAppModule,
   initializeApp,
} from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { lastValueFrom, map, Observable, of } from 'rxjs';
import { StockInfoModel } from 'src/app/models/stock-info-model.model';
import { AuthService } from './auth.service';
import * as firebase from 'firebase/compat/app';
import * as uuid from "uuid";
import { TypesenseService } from './typesense.service';
import { TYPESENSE_COLLECTIONS } from '../constants/typesense-collections';

@Injectable({
   providedIn: 'root',
})
export class FirestoreService {
   private firebaseTimestamp: any;

   constructor(
      private auth: AuthService,
      private typesenseService: TypesenseService,
      private firestore: AngularFirestore
   ) {
      this.firebaseTimestamp = firebase.default.firestore.Timestamp.now()
   }

   // RECENT
   public async storeRecentlyViewed(
      symbol: string,
      isin: string,
      type: string
   ): Promise<void> {
      const userId = this.auth.getUserId() ?? null;

      const doc: any = await lastValueFrom(
         this.firestore
            .collection('user_recently_viewed', (ref) =>
               ref
                  .where('user_id', '==', userId)
                  .where('symbol', '==', symbol)
                  .where('type', '==', type)
            )
            .get()
      );

      if (!!doc.size) {
         this.firestore
            .collection('user_recently_viewed')
            .doc(doc.docs[0].id)
            .update({
               last_viewed_at:
                  firebase.default.firestore.FieldValue.serverTimestamp(),
            });
      } else {
         const storeObj = {
            isin: isin,
            last_viewed_at:
               firebase.default.firestore.FieldValue.serverTimestamp(),
            symbol: symbol,
            type: type,
            user_id: userId,
         };

         await this.firestore.collection('user_recently_viewed').add(storeObj);
      }
   }

   //clear recent list
   public async clearRecentlyViewed(){
      const userId = this.auth.getUserId() ?? null;

      const recentlyViewedData: any = await lastValueFrom(
         this.firestore
            .collection('user_recently_viewed', (ref) =>
               ref
                  .where('user_id', '==', userId)
            )
            .get()
      );

      for (const data of recentlyViewedData.docs) {
         await data.ref.delete()
      }


   }

   // ETF
   public etfGetInfo(id: string): any {
      return this.firestore
         .collection('etf_profile_collection')
         .doc(id)
         .get()
         .pipe(map((data) => data.data()));
   }

   public async etfComplianceInfo(id: string): Promise<any> {
      return await lastValueFrom(
         this.firestore
            .collection('etf_compliance_collection')
            .doc(id)
            .get()
            .pipe(map((data) => data.data()))
      );
   }

   public etfCountryExposure(id: string): Observable<any> {
      return this.firestore
         .collection('etf_country_exposure_collection')
         .doc(id)
         .get()
         .pipe(
            map((data) => {
               const item: any = data.data();
               return item.countryExposure;
            })
         );
   }

   public etfSectorExposure(id: string): any {
      return this.firestore
         .collection('etf_sector_exposure_collection')
         .doc(id)
         .get()
         .pipe(
            map((data) => {
               const item: any = data.data();
               return item.sectorExposure;
            })
         );
   }

   public async getEtfComplianceInfo(symbol: string): Promise<any> {
      return await lastValueFrom(
         this.firestore
            .collection('etf_compliance_collection')
            .doc(symbol)
            .get()
            .pipe(map((item) => item.data()))
      );
   }

   // public etfMarketCap(id: string): any {
   //    return this.firestore.collection('etf_sector_exposure_collection')
   //    .doc(id)
   //    .get()
   //    .pipe(map((data) => {
   //       const item: any = data.data()
   //       return item.sectorExposure
   //    }))
   // }

   public etfHoldings(id: string): any {
      return this.firestore
         .collection('etf_holdings_collection')
         .doc(id)
         .get()
         .pipe(
            map((data) => {
               const item: any = data.data();
               return {
                  items: item.holdings,
                  totalCount: item.holdings.length,
               };
            })
         );
   }

   public async getUserViewedEtf(): Promise<any> {
      const userId = this.auth.getUserId();
      const data = await lastValueFrom(
         this.firestore
            .collection('user_monthly_viewed', (ref) =>
               ref
                  .where('user_id', '==', this.auth.getUserId())
                  .where('type', '==', 'etf')
            )
            .get()
            .pipe(
               map((item) => item.docs.map((data: any) => data.data().symbol))
            )
      );
      return data;
   }

   public async getIsUserViewedEtf(symbol: string): Promise<any> {
      const userId = this.auth.getUserId();
      const data = await lastValueFrom(
         this.firestore
            .collection('user_monthly_viewed', (ref) =>
               ref
                  .where('user_id', '==', this.auth.getUserId())
                  .where('symbol', '==', symbol)
            )
            .get()
      );
      return !!data?.size;
   }

   public async storeUserViewedEtf(symbol: string, isin: string): Promise<any> {
      const userId = this.auth.getUserId() ?? null;
      const storeObj = {
         isin: isin,
         symbol: symbol,
         last_viewed_at:
            firebase.default.firestore.FieldValue.serverTimestamp(),
         user_id: userId,
         type: 'etf',
      };

      await this.firestore.collection('user_monthly_viewed').add(storeObj);
   }

   // STOCK
   public getCompanyProfile(ticker: string): any {
      return this.firestore
         .collection(TYPESENSE_COLLECTIONS.COMPANY_PROFILE)
         .doc(ticker)
         .get()
         .pipe(
            map((dataItem) => {
               const item: any = dataItem.data();
               if (item) {
                  const {
                     debt,
                     identifier,
                     logs,
                     notHalal,
                     questionable,
                     ranking,
                     reportSource,
                     securitiesAndAssets,
                     source,
                     status,
                     totalRevenue,
                     trailing36MonAvrCap,
                     shariahCompliantStatus,
                     ...companyProfileData
                  } = item;
                  return companyProfileData;
               } else {
                  return item;
               }
            })
         );
   }

   public async getUserViewedBetweenCount(
      start: Date,
      end: Date
   ): Promise<any> {
      const userId = this.auth.getUserId();
      const data = await lastValueFrom(
         this.firestore
            .collection('user_monthly_viewed', (ref) =>
               ref
                  .where('user_id', '==', this.auth.getUserId())
                  .where('last_viewed_at', '>=', start)
                  .where('last_viewed_at', '<=', end)
            )
            .get()
      );
      return data?.size;
   }

   public async getUserViewedStocks(): Promise<any> {
      const userId = this.auth.getUserId();
      const data = await lastValueFrom(
         this.firestore
            .collection('user_monthly_viewed', (ref) =>
               ref
                  .where('user_id', '==', this.auth.getUserId())
                  .where('type', '==', 'stock')
            )
            .get()
            .pipe(map((item) => item.docs.map((data: any) => data.data().isin)))
      );
      return data;
   }

   public async getIsUserViewedStock(isin: string): Promise<any> {
      const userId = this.auth.getUserId();
      const data = await lastValueFrom(
         this.firestore
            .collection('user_monthly_viewed', (ref) =>
               ref
                  .where('user_id', '==', this.auth.getUserId())
                  .where('isin', '==', isin)
            )
            .get()
      );
      return !!data?.size;
   }

   public async storeUserViewedStock(
      ticker: string,
      isin: string
   ): Promise<any> {
      const userId = this.auth.getUserId() ?? null;
      const storeObj = {
         isin: isin,
         symbol: ticker,
         last_viewed_at:
            firebase.default.firestore.FieldValue.serverTimestamp(),
         user_id: userId,
         type: 'stock',
      };

      await this.firestore.collection('user_monthly_viewed').add(storeObj);
   }

   public getCompanyFundamentals(ticker: string): any {
      return this.firestore
         .collection('fundamentals_collection')
         .doc(ticker)
         .get()
         .pipe(map((item) => item.data()));
   }

   public getCompanyRecommendations(ticker: string): any {
      return this.firestore
         .collection('recommendation_collection')
         .doc(ticker)
         .get()
         .pipe(map((item) => item.data()));
   }

   public async getStockComplianceInfo(isin: string): Promise<any> {
      return await lastValueFrom(
         this.firestore
            .collection('compliance_collection')
            .doc(isin)
            .get()
            .pipe(map((item) => item.data()))
      );
   }

   public getStockPressReleases(ticker: string): any {
      return this.firestore
         .collection('press_releases_collection')
         .doc(ticker)
         .get()
         .pipe(map((item) => item.data()));
   }

   public getCompanyNewsCollection(ticker: string): any {
      return this.firestore
         .collection('company_news_collection')
         .doc(ticker)
         .get()
         .pipe(
            map((item) => {
               const items: any = item.data();
               const values: any = items.News;
               return values.map((item: any) => ({
                  image: item.Image,
                  source: item.Source,
                  datetime: item.Datetime,
                  url: item.Url,
                  headline: item.Headline,
               }));
            })
         );
   }

   public getRelatedStocks(
      musaffaIndustryName: string,
      marketCapClassification: string,
      country: string,
      gsubind: string,
      ticker: string
   ): any {
      return this.firestore
         .collection(TYPESENSE_COLLECTIONS.COMPANY_PROFILE, (ref) =>
            ref
               .where('marketCapClassification', '==', marketCapClassification)
               .where('country', '==', country)
               .where('musaffaIndustryName', '==', musaffaIndustryName)
               .where('gsubind', '==', gsubind)
               // .where('ticker', '!=', ticker)
               .orderBy('marketCapitalization', 'desc')
               .limit(11)
         )
         .get()
         .pipe(
            map((item) => {
               return item.docs
                  .filter((x) => {
                     const item: any = x.data();
                     return item.ticker !== ticker;
                  })
                  .slice(0, 10)
                  .map((dataItem) => {
                     const item: any = dataItem.data();
                     return {
                        type: 'STOCK',
                        identifier: item.identifier,
                        stockName: item.ticker,
                        companyName: item.name,
                        isin: item.isin,
                        shariahCompliantStatus: item.shariahCompliantStatus,
                        currency: item.currency,
                        logo: item.logo,
                     };
                  });
            })
         );
   }

   public getRecentlyViewed() {
      const userId = this.auth.getUserId() ?? null;
      return this.firestore
         .collection('user_recently_viewed', (ref) =>
            ref
               .where('user_id', '==', userId)
               .orderBy('last_viewed_at', 'desc')
               .limit(50)
         )
         .get()
         .pipe(
            map((item) => {
               const items: any = {};
               const stockSymbols: any[] = [];
               const etfSymbols: any[] = [];
               item.docs.forEach((dataItem) => {

                  const doc: any = dataItem.data();
                  if (doc.type === 'stock') {
                     stockSymbols.push(doc.symbol);
                     items[doc.symbol] = {
                        companyName: null,
                        // compliantRanking: 0,
                        currency: null,
                        identifier: null,
                        isin: doc.isin,
                        // shariahCompliantStatus: "QUESTIONABLE",
                        stockName: doc.symbol,
                        type: 'STOCK',
                     };
                  } else if (doc.type === 'etf') {
                     etfSymbols.push(doc.symbol);
                     items[doc.symbol] = {
                        domicile: null,
                        etfCompany: null,
                        identifier: null,
                        name: null,
                        nav: null,
                        navCurrency: null,
                        isin: doc.isin,
                        symbol: doc.symbol,
                        type: 'ETF',
                     };
                  }
               });
               // console.log({
               //    stockSymbols: stockSymbols,
               //    etfSymbols: etfSymbols,
               // })
               return {
                  viewedItems: items,
                  stockSymbols: stockSymbols,
                  etfSymbols: etfSymbols,
               };
            })
         );
   }

   public getUserInfo(email: string) {
      return this.firestore.collection('user_position')
      .doc(email)
      .get()
      .pipe(map((item) => {
         const data: any = item.data()
         return {
            active: true,
            ageGroup: data?.ageGroup,
            birthDay: data?.birthDay,
            country: data?.country,
            createDate: data?.createDate,
            email: data?.email,
            firstname: data?.firstname,
            geoIpCountry: data?.geoIpCountry,
            identifier: data?.identifier,
            lastUpdateTime: data?.lastUpdateTime,
            lastname: data?.lastname,
            phone: data?.phone,
            role: data?.authorities.join(','),
            sex: data?.sex,
            state: data?.state,
            username: data?.username,
            zipCode: data?.zipCode
         }
      }));
   }

   public async updatePersonalInfo(storeObj:any){
      await this.firestore
         .collection('user_position')
         .doc(this.auth.getEmail())
         .update(storeObj);
   }

   public async updateUserPromotion (userPromotionData: any ){

      await this.firestore
         .collection('user_promotions')
         .add(userPromotionData);
   }

   public watchlistList() {

      return this.firestore.collection('watchlist_position',
         (ref) =>
            ref
            .where('userId', '==', localStorage.getItem('userId'))
            .orderBy('name', 'asc')
         ).get().pipe(map((item) => {
         const items = item.docs.map((data: any) => {
            const x = data.data()
            if(x.stocks == null)
               x.stocks = []
            if(x.etfs == null)
               x.etfs = []
            return {
               "identifier": data.id,
               "name": x.name,
               "notifyOnComplianceChange": x.notifyOnComplianceChange,
               "stockCount": x.stocks?.length + x.etfs?.length,
               "stocks": x.stocks,
               "etfs": x.etfs,
               "createDateTime": x?.createDate?.epochSecond
            }
         })

         // console.log(items)

         return {
            "pageQuantity": item.docs.length,
            "currentPage": 0,
            "itemCount": item.docs.length,
            "hasPrevious": false,
            "hasNext": false,
            "items": items,
            "totalCount": item.docs.length
         }
      }))
   }

   public isSymbolExistInAnyWatchlist(symbol:any, isStock:any) {

      return this.firestore.collection('watchlist_position',
         (ref) =>
            ref
            .where('userId', '==', localStorage.getItem('userId'))
            .orderBy('name', 'asc')
         ).get().pipe(map((item) => {
            item.docs

         var matchFound = false;

         item.docs.every((data: any) => {
            const x = data.data()
            if(x.stocks == null)
               x.stocks = []
            if(x.etfs == null)
               x.etfs = []
            if(isStock){
               matchFound = x.stocks.find((item:any)=>item.symbol == symbol)
            }
            else{
               matchFound = x.etfs.find((item:any)=>item.symbol == symbol)
            }
            if(matchFound)
               return false
            else
               return true
         })
         if(matchFound)
            return true
         else
            return false

      }))
   }

   public async createWatchlist(name: string, notify: boolean)
   {
      const docId = uuid.v4();
      const storeObj = {
         createDate: {
            epochSecond: this.firebaseTimestamp.seconds,
            nano: this.firebaseTimestamp.nanoseconds
         },
         etfs: [],
         lastUpdateDate: {
            epochSecond: this.firebaseTimestamp.seconds,
            nano: this.firebaseTimestamp.nanoseconds
         },
         name: name,
         identifier: docId,
         notifyOnComplianceChange: notify,
         stocks: [],
         userId: this.auth.getUserId(),
         username: this.auth.getEmail()
      };

      let data = await this.firestore
         .collection('watchlist_position')
         .doc(docId)
         .set(storeObj);
         return storeObj
   }

   public async watchlistUpdate(id:string, name: string, notify: boolean)
   {
      const storeObj = {
         lastUpdateDate: {
            epochSecond: this.firebaseTimestamp.seconds,
            nano: this.firebaseTimestamp.nanoseconds
         },
         name: name,
         notifyOnComplianceChange: notify,
      };

      await this.firestore
         .collection('watchlist_position')
         .doc(id)
         .update(storeObj);
   }

   public async watchlistDelete(id:string)
   {
      await this.firestore
         .collection('watchlist_position')
         .doc(id)
         .delete()
   }

   public async watchlistIncluding(id: string) {

      const data: any = await lastValueFrom(this.firestore
         .collection('watchlist_position')
         .doc(id)
         .get()
         .pipe(map((item) => item.data())));


      if(data.etfs == null)
         data.etfs = []
      if(data.stocks == null)
         data.stocks = []
      const etfSymbols: any = data.etfs.map((etf: any) => etf.symbol)
      const stockSymbols: any = data.stocks.map((stock: any) => stock.symbol)

      const stockData = await this.typesenseService.getStockFromSymbols(stockSymbols)
      const etfData = await this.typesenseService.getEtfFromSymbols(etfSymbols)

      const stockIsins: any = []
      const stocks: any = []
      const etfs: any = []

      data.stocks.forEach((stock: any) => {
         stockIsins.push(stockData[stock.symbol].isin)

         stocks.push({
            "type": "STOCK",
            "identifier": stock.identifier,
            "stockName": stock.symbol,
            "symbol": stock.symbol,
            "companyName": stockData[stock.symbol].name,
            "isin": stockData[stock.symbol].isin,
            "shariahCompliantStatus": null,
            "currency": stockData[stock.symbol].currency,
            "logo": stockData[stock.symbol].logo,
            "ranking": stockData[stock.symbol].ranking,
            "country": stockData[stock.symbol].country,
         })
      })


      data.etfs.forEach((etf: any) => {
         etfs.push({
            "type": "ETF",
            "identifier": etf.identifier,
            "nav": etfData[etf.symbol].nav,
            "navCurrency": etfData[etf.symbol].navCurrency,
            "symbol": etf.symbol,
            "etfCompany": etf.etfCompany,
            "isin": etf.isin,
            "name": etfData[etf.symbol].name,
            "currency": etfData[etf.symbol].navCurrency,
            "companyName": etfData[etf.symbol].name,
            "shariahCompliantStatus": etfData[etf.symbol].shariahStates,
            "ranking": etfData[etf.symbol].ranking,
            "country": etfData[etf.symbol].domicile,
            "logo": null

          })
      })

      return {
         "identifier": data.identifier,
         "name": data.name,
         "notifyOnComplianceChange": data.notifyOnComplianceChange,
         "stockCount": data.stocks.length + data.etfs.length,
         // "etfProfileCount": data.etfs.length,
         "createDateTime": data.createDate,
         "stocksList": stocks,
         "etfProfileInfoList": etfs,
         "stockIsins": stockIsins,
         "stockAndEtf": stocks.concat(etfs)
       }
   }

   public async watchlistDefaultById(id: string) {
      return await lastValueFrom(this.firestore
         .collection('watchlist_position')
         .doc(id)
         .get()
         .pipe(map((data) => data.data()))
      )
   }

   public async watchlistAddETFs(watchlistIdentifier: string, etfIdentifier: string, symbol: string, isAdd=true) {
      const watchlistData: any = await this.watchlistDefaultById(watchlistIdentifier)
      let newList:any = []
      if(isAdd){
         if(watchlistData?.etfs?.length) {
            newList = [...watchlistData.etfs, {
               identifier: etfIdentifier,
               symbol: symbol
            }]
         } else {
            newList = [{
               identifier: etfIdentifier,
               symbol: symbol
            }]
         }
      }
      else{

         newList = watchlistData.etfs ?? []
         newList = newList.filter((item:any)=>item.symbol != symbol)

      }

      const storeObj = {
         lastUpdateDate: {
            epochSecond: this.firebaseTimestamp.seconds,
            nano: this.firebaseTimestamp.nanoseconds
         },
         etfs: newList
      };
      await this.firestore
         .collection('watchlist_position')
         .doc(watchlistIdentifier)
         .update(storeObj);
   }

   public async watchlistAddStocks(watchlistIdentifier: string, stockIdentifier: string, symbol: string, isAdd = true) {
      const watchlistData: any = await this.watchlistDefaultById(watchlistIdentifier)
      let newList = []
      if(isAdd){
         if(watchlistData?.stocks?.length) {
            newList = [...watchlistData.stocks, {
               identifier: stockIdentifier,
               symbol: symbol
            }]
         } else {
            newList = [{
               identifier: stockIdentifier,
               symbol: symbol
            }]
         }
      }
      else{

         newList = watchlistData.stocks ?? []
         newList = newList.filter((item:any)=>item.symbol != symbol)

      }

      const storeObj = {
         lastUpdateDate: {
            epochSecond: this.firebaseTimestamp.seconds,
            nano: this.firebaseTimestamp.nanoseconds
         },
         stocks: newList
      };
      await this.firestore
         .collection('watchlist_position')
         .doc(watchlistIdentifier)
         .update(storeObj);
   }

   public async watchlistStocksRemove(watchlistIdentifier: string, stockIdentifier: string) {
      const watchlistData: any = await this.watchlistDefaultById(watchlistIdentifier)
      const removingIndex = watchlistData.stocks.findIndex((stock: any) => {
         return stock.identifier === stockIdentifier
      })
      if(removingIndex != -1) {
         watchlistData.stocks.splice(removingIndex, 1)
         const storeObj = {
            lastUpdateDate: {
               epochSecond: this.firebaseTimestamp.seconds,
               nano: this.firebaseTimestamp.nanoseconds
            },
            stocks: [...watchlistData.stocks]
         };
         await this.firestore
            .collection('watchlist_position')
            .doc(watchlistIdentifier)
            .update(storeObj);
      }
   }

   public async watchlistEtfRemove(watchlistIdentifier: string, etfIdentifier: string) {
      const watchlistData: any = await this.watchlistDefaultById(watchlistIdentifier)
      const removingIndex = watchlistData.etfs.findIndex((etf: any) => {
         return etf.identifier === etfIdentifier
      })
      if(removingIndex != -1) {
         watchlistData.etfs.splice(removingIndex, 1)
         const storeObj = {
            lastUpdateDate: {
               epochSecond: this.firebaseTimestamp.seconds,
               nano: this.firebaseTimestamp.nanoseconds
            },
            etfs: [...watchlistData.etfs]
         };
         await this.firestore
            .collection('watchlist_position')
            .doc(watchlistIdentifier)
            .update(storeObj);
      }
   }

   public async getMarketNews() {
      return await lastValueFrom(
         this.firestore
            .collection('market_news_collection', (ref) =>
               ref
                  .orderBy('Datetime', 'desc')
                  .limit(10)
            )
            .get()
            //  .pipe(map((data) => data.docs))
             .pipe(map((item) => item.docs.map((data: any) => data.data())))
      );


   }
}
