<ng-container *ngIf="type == 'amount'">
   <span class="" *ngIf="amount >= 0.01 || amount == 0" [ngStyle]="amountStyle">
      {{amount | sumPipe: ( currency || '' ) }}
   </span>
   <span class="text-decoration-underline" container="body"  *ngIf="amount < 0.01 && amount != 0" ngbTooltip="{{tooltipContent}}" [placement]="'auto'" [ngStyle]="amountStyle">
      < {{0.01 | sumPipe: ( currency || '' )}}
   </span>
</ng-container>
<ng-container *ngIf="type == 'percentage'">
   <span class="" *ngIf="amount >= 0.01 || amount == 0" [ngStyle]="amountStyle">
      {{amount | number : "1.2-2" }}%
   </span>
   <span class="text-decoration-underline" container="body"  *ngIf="amount < 0.01 && amount != 0" ngbTooltip="{{tooltipContent}}" [placement]="'auto'" [ngStyle]="amountStyle">
      < 0.01%
   </span>
</ng-container>
<ng-container *ngIf="type == 'number'">
   <span class="" *ngIf="amount >= 0.01 || amount == 0" [ngStyle]="amountStyle">
      {{amount}}
   </span>
   <span class="text-decoration-underline" container="body"  *ngIf="amount < 0.01 && amount != 0" ngbTooltip="{{tooltipContent}}" [placement]="'auto'" [ngStyle]="amountStyle">
      < 0.01
   </span>
</ng-container>
