import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {
   NavigationEnd,
   NavigationStart,
   Params,
   Router,
   Event,
} from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { ApiService } from './shared/services/api.service';

import { Token } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { first, lastValueFrom, map, subscribeOn } from 'rxjs';
import { LoaderServices } from 'src/app/shared/services/loader.service';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { FirebaseCloudFunctionsService } from 'src/app/shared/services/firebase-cloud-functions.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseRemoteConfigService } from './shared/services/firebase-remote-config.service';
import { UserApiService } from './shared/services/user-api.service';
import { SubscriptionService } from './shared/services/subscription.service';
import { SubscriptionInfoModel } from './models/subscription-info.model';
import { ToastrService } from 'ngx-toastr';
import { DownloadPromptComponent } from './shared/components/download-prompt/download-prompt.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PremiumMemberGreetingPopupComponent } from './shared/components/premium-member-greeting-popup/premium-member-greeting-popup.component';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { DataLayerService } from './shared/services/data-layer.service';
import { environment } from 'src/environments/environment';
import * as branch from 'branch-sdk';
import { SharedService } from './shared/services/shared.service';
import { MusaffaRedirectService } from './shared/services/musaffa-redirect.service';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { loadWatchlistSymbols } from './stores/watchlist-symbols/watchlist-symbols.actions';
import { loadPermissions } from './stores/access-control/access-control.actions';
import { PermissionsState } from './stores/access-control/access-control.reducers';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
   title = 'musaffa-platform';
   fireAuthUserLoaded = false;
   loadingPermissions = true;
   bannerText = '';
   bannerLinkText = '';
   bannerLink = '';
   showBanner = false;
   stripeSubscriptionStatus: any;
   record_id: string | null = '';
   module: string | null = '';
   @ViewChild(DownloadPromptComponent) prompt!: DownloadPromptComponent;

   constructor(
      private router: Router,
      private apiService: ApiService,
      public auth: AuthService,
      public subscriptionService: SubscriptionService,
      private toaster: ToastrService,
      private route: ActivatedRoute,
      private modalService: NgbModal,
      public authFire: AngularFireAuth,
      private firebaseConfigService: FirebaseRemoteConfigService,
      public userApiService: UserApiService,
      private analyticsUtils: AnalyticsUtils,
      private activatedRoute: ActivatedRoute,
      private dataLayerService: DataLayerService,
      private sharedService: SharedService,
      private musaffaRedirectService: MusaffaRedirectService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private store: Store
   ) {
      // this.permissionStore
      //    .select(({ permissions }) => permissions.loading)
      //    .subscribe((isPermissionLoading) => {
      //       if(isPermissionLoading) {
      //          this.loadingPermissions = true;
      //          console.error('loadingPermissions', isPermissionLoading);
      //       } else {
      //          this.loadingPermissions = false;
      //          console.error('loadingPermissions', this.loadingPermissions);
      //       }
      //    });
      //    SubscriptionService.userSubscriptionFetched.subscribe((isUserSubscriptionFetched) => {
      //       if(isUserSubscriptionFetched) {
      //          this.store.dispatch(loadPermissions());
      //       }
      //    });
      var instance = this;
      branch.data(function (err, data) {
         var branchData: any = data;
         var module = branchData?.data_parsed?.$deeplink_path;
         var record_id = branchData?.data_parsed?.record_id;

         instance.musaffaRedirectService.goToPage(module, record_id);
      });
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      this.module = params.get('module');
      this.record_id = params.get('record_id');

      this.subscriptionService.setUserSubscriptionInfo().then(() => {
         this.store.dispatch(loadPermissions());
      });

      router.events.subscribe((val) => {
         if (this.bannerText) this.updateBannerVisibility();
         if (val instanceof NavigationStart) {
            this.subscriptionService.setUserSubscriptionInfo().then(() => {
               this.store.dispatch(loadPermissions());
            });
            const countryCode = localStorage.getItem('countryCode');
            if (!countryCode) {
               this.apiService.getUserCountryCode().then((response) => {
                  if (response?.data?.iso_code)
                     localStorage.setItem(
                        'countryCode',
                        response.data.iso_code
                     );
               });
            }
            // .then(() => {
            //    this.store.dispatch(loadPermissions());
            // });
            // .then(() => {
            //    this.store.dispatch(loadPermissions());
            // });
            // if (
            //    this.auth.getRole() === 'USER' &&
            //    !val.url.includes('authentication') &&
            //    !val.url.includes('/pages/stripe-membership-main')
            // ) {
            //    this.router.navigate(['/pages/stripe-membership-main']);
            // }
         }
         if (val instanceof NavigationEnd) {
            this.subscriptionService.setSubscriptionPlans();
            this.analyticsUtils.identifyUser();
            // this.subscriptionService.openPaywallModal()
            if (
               !(
                  val.url.includes('authentication') ||
                  val.url.includes('pages')
               )
            ) {
               localStorage.setItem('redirectUrl', val.url);
            }
         }
      });

      if (this.module) {
         this.musaffaRedirectService.goToPage(this.module, this.record_id);
      }
   }

   ngAfterViewInit(): void {
      const hasOpenedPrompt = sessionStorage.getItem('hasOpenedPrompt');
      if (!hasOpenedPrompt) {
         setTimeout(() => {
            this.openPrompt();
            sessionStorage.setItem('hasOpenedPrompt', 'true');
         }, 3000);
      }

      // const hasWebinarPopupOpened = sessionStorage.getItem('hasWebinarPopupOpened');
      // if (!hasWebinarPopupOpened) {
      //    setTimeout(() => {
      //       this.openPopup()
      //       sessionStorage.setItem('hasWebinarPopupOpened', 'true');
      //    }, 3000);
      // }
   }

   // openPopup(){
   //    this.sharedService.openWebinarModal()
   // }

   openPrompt() {
      this.prompt.openDownloadPrompt();
   }

   shouldHideBanner() {
      const inValidRoutes: string[] = [
         '/pages/stripe-membership-main',
         '/pages/stripe-premium-mobile',
         '/authentication/form',
         '/authentication/registration',
         '/authentication/sso-login',
         '/authentication/login',
      ];
      return (
         this.auth.checkRole('PREMIUM') ||
         inValidRoutes.includes(this.router.url)
      );
   }
   updateBannerVisibility() {
      if (this.shouldHideBanner()) {
         this.showBanner = false;
      } else {
         this.showBanner = true;
      }
   }

   async logoutFireAuthAndSignInAnonymously() {
      var fireUser = await lastValueFrom(this.authFire.authState.pipe(first()));
      if (!fireUser || fireUser?.isAnonymous == false) {
         // await this.authFire.signOut();
         const auth = getAuth();
         await signInAnonymously(auth);
         // this.auth.logout()
      }
   }

   async ngOnInit(): Promise<void> {
      if (this.auth.getUserId()) {
         this.store.dispatch(loadWatchlistSymbols());
      }
      // this.loadScript();

      // SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {})

      // logout user on firebase and sign in anonymously
      // window.location.href = (await this.subscriptionService.getPaymentLink()).url
      // this.logoutFireAuthAndSignInAnonymously();

      this.fireAuthUserLoaded = true;

      this.updateBannerText();

      this.auth.roleUpdatedEvent.subscribe((dt) => {
         this.updateBannerText();
      });
      // this.userApiService.fetchUserDetails();

      this.route.queryParams.subscribe(async (params) => {
         this.analyticsUtils.identifyUser();
         this.stripeSubscriptionStatus = params?.subscription_status;
         const duration = params?.duration ?? '';
         if (this.stripeSubscriptionStatus != undefined) {
            if (this.stripeSubscriptionStatus === 'success') {
               this.modalService.open(PremiumMemberGreetingPopupComponent, {
                  centered: true,
               });
               await this.analyticsUtils.user_subscription_completed(
                  duration ?? ''
               );
               this.dataLayerService.userSubscriptionCompletedEvent(duration);
            } else if (this.stripeSubscriptionStatus === 'cancel') {
               this.toaster.error('Subscription was not completed!');
               this.analyticsUtils.user_subscription_failed('Cancelled');
            }
            const queryParams: Params = {
               subscription_status: null,
               duration: null,
            };
            this.router.navigate([], {
               relativeTo: this.activatedRoute,
               queryParams,
               replaceUrl: true,
               queryParamsHandling: 'merge',
            });
         }
      });

      if (localStorage.getItem('countryCode') === 'IN' && !localStorage.getItem('disclaimerAccepted')) {
         try {
            await this.sharedService.openDisclaimerPopup();
            localStorage.setItem('disclaimerAccepted', 'true');
         } catch (error) {
            console.error(error);
         }
      }
   }
   // loadScript() {
   //    let node = document.createElement('script');
   //    node.innerHTML =
   //       '(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode setRequestMetaData setAPIUrl getAPIUrl setDMAParamsForEEA".split(" "), 0);branch.init(\'' +
   //       environment.branchKey +
   //       "'); ";
   //    // append to head of document
   //    document.getElementsByTagName('head')[0].prepend(node);
   // }
   // async ngOnInit(): Promise<void> {

   //   this.loader.show();

   //     const auth = getAuth();
   //     const user = await signInAnonymously(auth);
   //     const tokenObject: any = await this.firebaseCloudFunctionService.getUserBackendToken();
   //     let response = {
   //        refreshToken: user.user.refreshToken,
   //        roles: "ANONYMOUS",
   //        token: tokenObject?.token,
   //        userId: user.user.uid
   //     }

   //     await this.auth.setToken(response);
   //     this.loader.hide();

   // }

   async updateBannerText() {
      var config = await this.firebaseConfigService.getConfigDefault();
      this.showBanner = !this.shouldHideBanner();

      if (this.auth.checkRole('ANONYMOUS')) {
         this.bannerText = config['web_desktop_anonymous_banner_text'];
         this.bannerLinkText = config['web_desktop_anonymous_banner_link_text'];
         this.bannerLink = this.getAnonymousBannerLink();
      } else if (this.auth.checkRole('FREEMIUM')) {
         this.bannerText = config['web_desktop_freemium_banner_text'];
         this.bannerLinkText = config['web_desktop_freemium_banner_link_text'];
         this.bannerLink = this.getFreemiumBannerLink();
      }

      this.updateBannerVisibility();
   }

   getAnonymousBannerText() {
      return 'We are giving out 3-months of Premium for free!';
   }

   getFreemiumBannerText() {
      return 'We are giving out 3-months of Premium for free!';
   }

   getAnonymousBannerLinkText() {
      return 'Click to claim this offer';
   }

   getFreemiumBannerLinkText() {
      return 'We are giving out 3-months of Premium for free!';
   }

   getAnonymousBannerLink() {
      return window.location.origin + '/authentication/registration';
   }

   getFreemiumBannerLink() {
      return window.location.origin + '/pages/stripe-premium-mobile';
   }
}
