export interface Currency {
   currency_code: string,
   symbol: string,
   label: string
}

export interface Checkbox {
   id: string,
   inputs: string | string[],
   name: string | string[]
}

export interface FormValues {
   currency: string,
   selectedCheckboxes: Checkbox[]
}

export enum ZakatFormsEnum {
   Home = 'HOME',
   MultiStep = 'MULTISTEP',
   Calculations = 'CALCULATIONS',
}

export interface ZakatData {
   currency_code: string
   cash_hand_amt: number | null
   cash_bank_amt: number | null
   gold_amt: number | null
   silver_amt: number | null
   tradable_goods_amt: number | null
   raw_materials_amt: number | null
   property_total_amt: number | null
   loan_amt: number | null
   bills_amt: number | null
   payables_amt: number | null
   shares_investment_type: string | null
   stock_fund_investments: StockFundInvestments
   other_investments: OtherInvestments
}

export interface StockFundInvestments {
   holdings: Holding[]
}

export interface Holding {
   ticker: string
   no_of_shares: number
   current_market_value: number
   investment_type: string
}

export interface InvestmentType {
   id: string,
   name: string,
   disabled? : boolean
 }

export interface OtherInvestments {
   investments: Investment[]
}

export interface Investment {
   name: string
   amount: number
   investment_type: string
}

export interface CalculatedZakat {
   user_id: string
   currency_code: string
   cash_hand_amt: number | null
   cash_bank_amt: number | null
   gold_amt: number | null
   silver_amt: number | null
   tradable_goods_amt: number | null
   raw_materials_amt: number | null
   property_total_amt: number | null
   loan_amt: number | null
   bills_amt: number | null
   payables_amt: number | null
   shares_investment_type: string
   shares_short_term_amt: number | null
   shares_known_long_term_amt: number | null
   shares_unknown_short_term_amt: number | null
   shares_unknown_long_term_amt: number | null
   total_zakatable_amt: number | null
   total_liabilities_amt: number | null
   total_zakat_amt: number | null
   updated_at: string
   created_at: string
   id: number
}

// export interface Ticker {
//    canAddToWatchlist: boolean,
//    companyName: string
//    complianceStatus: string
//    countryName: string
//    currency: string
//    exchange: string
//    flag: string
//    identifier: string
//    isin: string
//    ranking: number
//    stockName: string
//    textMatch: number
//    type: string
// }
