import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSubscriptionModel } from 'src/app/models/user-subscription.model';
import { AllSubscriptionData, Period, Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { PaymentLinkResponse } from 'src/app/models/stripe-payment-link.model';
import { SubscriptionPlansResponse } from 'src/app/models/subscription-plans.model';
// import { PaywallPopupComponent } from '../components/paywall-popup/paywall-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancelSubscriptionPopupComponent } from '../components/cancel-subscription-popup/cancel-subscription-popup.component';
import { ResumeSubscriptionPopupComponent } from '../components/resume-subscription-popup/resume-subscription-popup.component';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { UserOnboardingServeComponent } from '../components/user-onboarding-serve/user-onboarding-serve.component';
import { Store } from '@ngrx/store';
import { loadPermissions } from 'src/app/stores/access-control/access-control.actions';
import { SharedService } from './shared.service';

@Injectable({
   providedIn: 'root',
})
export class SubscriptionService {
   constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private authService: AuthService,
      private store: Store,
      private analyticsUtils: AnalyticsUtils,
      private sharedService: SharedService
   ) {
      SubscriptionService.subscriptionData.subscribe((data) => {
         this.store.dispatch(loadPermissions());
      })
   }

   public static subscriptionPlans =
      new BehaviorSubject<SubscriptionPlansResponse>({
         status: false,
         message: '',
         data: {
            year: {
               id: '',
               currency: '',
               price: 0,
               coupon: null,
               trial_info: {
                  can_trial: false,
                  trial_days: 0,
               },
            },
            month: {
               id: '',
               currency: '',
               price: 0,
               coupon: null,
               trial_info: {
                  can_trial: false,
                  trial_days: 0,
               },
            },
         },
      });

   public static stripeSubscriptionInfo = new BehaviorSubject<any>({});
   public static subscriptionFetched = new BehaviorSubject<boolean>(false);
   public static userSubscriptionFetched = new BehaviorSubject<boolean>(false);

   public static subscriptionData = new BehaviorSubject<SubscriptionInfoModel>({
      isDataFetched: false,
      isSubscribed: false,
      isCancelled: false,
      subscriptionSource: null,
      identifier: null,
      purchaseDate: null,
      expiresDate: null,
      role: 'ANONYMOUS',
      newRole: null,
      period: null,
      allActiveSubscriptions: []
   });

   private async getUserSubscriptionInfo(): Promise<UserSubscriptionModel> {
      const userId = this.authService.getUserId();
      return await lastValueFrom(
         this.http.get<UserSubscriptionModel>(
            environment.revenueCat.v1.url + '/subscribers/' + userId
         )
      );
   }

   public async getUpdatePaymentLink(priceId: string) {
      return await lastValueFrom(
         this.http.post<PaymentLinkResponse>(
            environment.apiUrl + '/api/user/subscribe/update',
            {
               new_price_id: priceId,
            }
         )
      );
   }

   public async getPaymentLink(priceId: string) {
      return await lastValueFrom(
         this.http.post<PaymentLinkResponse>(
            environment.apiUrl + '/api/user/subscribe',
            {
               price_id: priceId,
            }
         )
      );
   }

   public async cancelSubscription() {
      return await lastValueFrom(
         this.http.get<any>(
            environment.apiUrl + '/api/user/subscription/cancel'
         )
      );
   }

   public async cancelSubscriptionWithPriceId(priceId: string) {
      return await lastValueFrom(
         this.http.post<any>(
            environment.apiUrl + '/api/user/subscription/cancel',
            {
               price_id: priceId
            }
         )
      );
   }

   public async cancelSubscriptionReason(
      cancellationReason: string,
      otherReason: string
   ) {
      return await lastValueFrom(
         this.http.post<any>(
            environment.apiUrl + '/api/user/subscription/cancel/reason',
            {
               cancellation_reason: cancellationReason,
               cancellation_other_reason: otherReason,
            }
         )
      );
   }

   public async setSubscriptionPlans() {
      if (this.authService.getUserId()?.length) {
         const res = await lastValueFrom(
            this.http.get<SubscriptionPlansResponse>(
               environment.apiUrl + '/api/plans'
            )
         );
         SubscriptionService.subscriptionPlans.next(res);
      }
      SubscriptionService.subscriptionFetched.next(true);
   }

   public clearSubscriptionInfo() {
      SubscriptionService.subscriptionData.next({
         isDataFetched: true,
         isSubscribed: false,
         subscriptionSource: null,
         identifier: null,
         purchaseDate: null,
         expiresDate: null,
         isCancelled: false,
         role: 'ANONYMOUS',
         newRole: null,
         period: null,
         allActiveSubscriptions: []
      });
   }

   public openPaywallModal() {
      this.sharedService.openUpgradePopup()
      // this.analyticsUtils.paywall_displayed();
      // this.modalService.open(PaywallPopupComponent, {
      //    centered: true,
      //    modalDialogClass: 'paywall-popup-modal',
      //    size: 'xl',
      //    backdrop: 'static',
      // });
   }

   public openUserOnboardingModal() {
      this.modalService.open(UserOnboardingServeComponent, {
         centered: true,
         scrollable: true,
         modalDialogClass: 'paywall-popup-modal',
         size: 'xl',
      });
   }

   public openCancelModal(priceId: string | null = null) {
      const modal = this.modalService.open(CancelSubscriptionPopupComponent, {
         centered: true,
         backdrop: 'static',
         modalDialogClass: 'paywall-popup-modal',
      });
      modal.componentInstance.priceId = priceId;
   }

   public openResumeModal() {
      this.modalService.open(ResumeSubscriptionPopupComponent, {
         centered: true,
         backdrop: 'static',
         modalDialogClass: 'paywall-popup-modal',
      });
   }

   public async resumeSubscription() {
      return await lastValueFrom(
         this.http.get<any>(
            environment.apiUrl + '/api/user/subscription/resume'
         )
      );
   }

   public async setStripeSubscriptionInfo() {
      const response = await this.getStripeSubscriptionInfo();
      if (response.status) {
         SubscriptionService.stripeSubscriptionInfo.next(response.data);
      }
   }

   public async getStripeSubscriptionInfo() {
      return await lastValueFrom(
         this.http.get<any>(environment.apiUrl + '/api/user/subscription')
      );
   }

   private getPeriod(purchaseDate: string, expiresDate: string): Period {
      if(purchaseDate && expiresDate) {
         const purchaseDateObj = new Date(purchaseDate);
         const expiresDateObj = new Date(expiresDate);
         const timeDifference = expiresDateObj.getTime() - purchaseDateObj.getTime();
         const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
         return (daysDifference >= 365) ? 'yearly' : 'monthly';
      }
      return null;
   }

   public async setUserSubscriptionInfo() {
      SubscriptionService.userSubscriptionFetched.next(false);
      if (this.authService.getUserId()) {
         const subscriptionInfo: UserSubscriptionModel =
            await this.getUserSubscriptionInfo();
         const subscriber = subscriptionInfo.subscriber;
         const subscriptions = subscriber.subscriptions;
         const currentDateTime = new Date();
         let role = 'FREEMIUM';
         let newRole: Role = 'free';
         let period: Period = null;
         let isSubscribed = false;
         let subscriptionSource = null;
         let identifier = null;
         let purchaseDate = null;
         let expiresDate = null;
         let isCancelled = false;
         let allActiveSubscriptions: Array<AllSubscriptionData> = [];
         const productIdentifiers: { [key: string]: any } = {
            premium_plus: subscriber?.entitlements?.premium_plus?.product_identifier,
            premium: subscriber?.entitlements?.premium?.product_identifier,
            starter: subscriber?.entitlements?.starter?.product_identifier,
         };
         Object.keys(productIdentifiers).forEach((prodName) => {
            if (productIdentifiers[prodName] && subscriptions[productIdentifiers[prodName]]) {
               const subscription = subscriptions[productIdentifiers[prodName]];
               const expires_date = new Date(subscription.expires_date);
               if (subscription.unsubscribe_detected_at) {
                  isCancelled = true;
               }
               if (
                  !subscription.expires_date ||
                  expires_date.getTime() > currentDateTime.getTime()
               ) {
                  role = 'PREMIUM';
                  newRole = prodName as Role;
                  isSubscribed = true;
                  identifier = productIdentifiers[prodName];
                  purchaseDate = subscription.purchase_date;
                  expiresDate = subscription.expires_date;
                  period = this.getPeriod(purchaseDate, expiresDate);
                  subscriptionSource = subscriber.subscriptions[identifier].store;
                  allActiveSubscriptions.push({
                     subscriptionSource,
                     identifier,
                     purchaseDate,
                     expiresDate,
                     isCancelled,
                     role,
                     newRole,
                     period
                  } as AllSubscriptionData);
               }
            }
         });

         if(allActiveSubscriptions.length > 0) {
            SubscriptionService.subscriptionData.next({
               isDataFetched: true,
               isSubscribed: true,
               ...allActiveSubscriptions[0],
               allActiveSubscriptions: allActiveSubscriptions
            });
         } else {
            SubscriptionService.subscriptionData.next({
               isDataFetched: true,
               isSubscribed: false,
               role: 'FREEMIUM',
               newRole: 'free',
               period: null,
               subscriptionSource: null,
               identifier: null,
               purchaseDate: null,
               expiresDate: null,
               isCancelled: false,
               allActiveSubscriptions: []
            });
         }
      } else {
         this.clearSubscriptionInfo();
      }
      SubscriptionService.userSubscriptionFetched.next(true);
   }
}
