<div class="loader-wrapper" *ngIf="show">
   <div class="showbox">
      <div class="loader">
         <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"/>
         </svg>
      </div>
   </div>
   <p class="loader-text">Loading ...</p>
</div>
