<div class="modal-header border-0 p-2 px-lg-3 pt-lg-3 pb-lg-0">
   <h4 class="modal-title" id="modal-basic-title"></h4>
   <button type="button" class="btn shadow-none p-1 bg-light rounded-3 modal-header-close-btn" aria-label="Close" (click)="activeModal.dismiss()">
      <img src="/assets/images/header/close.svg" alt="Close">
   </button>
</div>
<div class="modal-body border-0 p-3 p-lg-4 pt-0 pt-lg-0">
   <ng-container [ngSwitch]="formName">
      <app-login-form (formChange)="formChange($event)" *ngSwitchCase="'signIn'" [isInsidePopup]="true" [action]="action"></app-login-form>
      <app-signup-form (formChange)="formChange($event)" *ngSwitchCase="'signUp'" [isInsidePopup]="true" [action]="action"></app-signup-form>
      <app-forgot-password-form (formChange)="formChange($event)" *ngSwitchCase="'forgotPassword'" [isInsidePopup]="true"></app-forgot-password-form>
   </ng-container>
</div>

