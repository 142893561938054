import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, CountryCodeService } from '../../services/phone-code.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { UserApiService } from '../../services/user-api.service';
import { ToastrService } from 'ngx-toastr';
// @ts-expect-error
import moengage from '@moengage/web-sdk';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsUtils } from '../../services/analytics-utils';

@Component({
  selector: 'app-enable-personalized-assistance-popup',
  templateUrl: './enable-personalized-assistance-popup.component.html',
  styleUrls: ['./enable-personalized-assistance-popup.component.scss']
})
export class EnablePersonalizedAssistancePopupComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   phoneNumberForm!: FormGroup;
   countryCodes: string[] = [];
   isSubmitting: boolean = false;
   userDetialsLoader: boolean = false;
   formLoader: boolean = false;
   userDetails: any = {}

   constructor(
      private sharedService: SharedService,
      private userApiServices: UserApiService,
      private countryCodeService: CountryCodeService,
      private toaster: ToastrService,
      private analyticsUtils: AnalyticsUtils,
   ) { }

   ngOnInit(): void {
      this.countryCodeService.getCountries().pipe(takeUntil(this.destroy$)).subscribe((data: Country[]) => {
         const countries = data;
         this.countryCodes = data.map(country => country.dial_code)
         const storedCountryCode = localStorage.getItem('countryCode');

         const defaultCountry = countries.find(
            (country) => country.code === storedCountryCode
         );

         const defaultCountryCode = defaultCountry ? defaultCountry.dial_code : this.countryCodes[0];
     
         this.phoneNumberForm = new FormGroup({
            countryCode: new FormControl(defaultCountryCode, [Validators.required]),
            phoneNumber: new FormControl('', [
               Validators.required,
               Validators.pattern('^[0-9]+$')
            ])
         });
      });
      
      this.getUserDetails()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async submitPhoneNumber(){
      this.formLoader = true;
      if(this.phoneNumberForm.valid) {

         const phoneNumberWithCountryCode = `${this.phoneNumberForm.value.countryCode} ${this.phoneNumberForm.value.phoneNumber}`
         const phoneNumberWithoutCountryCode = `${this.phoneNumberForm.value.phoneNumber}`

         const updatedUserDetails = {
            ...this.userDetails,
            phone: phoneNumberWithCountryCode,
            pseudoPhone: phoneNumberWithoutCountryCode
         };

         try {
            let res = await this.userApiServices.updateUser(updatedUserDetails);
            moengage.add_mobile(phoneNumberWithCountryCode);
            this.isSubmitting = false;
            this.toaster.success('Phone number submitted successfully!');
            await lastValueFrom(this.userApiServices.refreshUserData())
            this.closePersonalizedAssistancePopup();
            this.analyticsUtils.postsubscribe_mobileno_shared();
         } catch (e) {
            if (e instanceof HttpErrorResponse) {
               if (e.status == 422) {
                  this.toaster.error(e.error.message);
                  this.formLoader = false;
               }
            } else {
               this.toaster.error('Something went wrong');
               this.formLoader = false;
            }
         }

      }
      
   }

   trimAndValidate(event: Event) {
      const input = event.target as HTMLInputElement;
      input.value = input.value.replace(/[^0-9]/g, '').trim();
      this.phoneNumberForm.controls['phoneNumber'].setValue(input.value, { emitEvent: false });
   }

   private getUserDetails() {
      this.userDetialsLoader = true;
      this.userApiServices.getUserData().pipe(takeUntil(this.destroy$)).subscribe({
         next: (data) => {
            if(data) {
               console.log(data);
               this.userDetails = {
                  ageGroup : data.age_group,
                  country : data.country,
                  email : data.email,
                  firstname : data.firstname,
                  lastname : data.lastname,
                  phone : data.phone,
                  pseudoPhone : data.phone ? this.countryCodeService.getNumberWithoutCountryCode(data.phone) : null,
                  sex : data.sex,
               }
               this.userDetialsLoader = false;
            }
         },
         error: (error) => {
            console.error(error);
            this.userDetialsLoader = false;
         },
         complete: () => {
            this.userDetialsLoader = false;
         },
      })
   }

   closePersonalizedAssistancePopup() {
      this.analyticsUtils.postsubscribe_mobileno_dismissed();
      this.sharedService.closePersonalizedAssistancePopup()
   }

}
