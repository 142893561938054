import {
   Component,
   EventEmitter,
   HostBinding,
   Input,
   OnInit,
   Output,
   ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OTPMailResponse } from 'src/app/models/user/otp-mail.model';
import { VerificationMailResponse } from 'src/app/models/user/verification-mail.model';
import { VerifyOTPResponse } from 'src/app/models/user/verify-otp.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { environment } from 'src/environments/environment.swbeta';
import { UserOnboardingServeComponent } from 'src/app/shared/components/user-onboarding-serve/user-onboarding-serve.component';
import {
   Country,
   CountryCodeService,
} from 'src/app/shared/services/phone-code.service';
import { HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom, takeUntil } from 'rxjs';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';
interface UserData {
   email: string;
   firstname: string;
   lastname: string;
   password: string;
   mobile: string;
   countryCode: string;
}

@Component({
   selector: 'app-signup-form',
   templateUrl: './signup-form.component.html',
   styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
   isEmailVerified: boolean = false;
   isMobileVerified: boolean = false;
   formInitComplete: boolean = false;
   showPassword: boolean = false;
   isVerificationPinSent: boolean = false;
   signupForm!: FormGroup;
   showSignUpLoader: boolean = false;
   identifier!: string;
   errorMessage: string = '';
   nextResendDuration: number = environment.resendPinSeconds;
   seconds: number = this.nextResendDuration;
   showMailLoader: boolean = false;
   isOTPMailSent: boolean = false;
   showPinInput: boolean = false;
   showOTPLoader: boolean = false;
   isReadonly: boolean = false;
   source: string = 'web';
   emailSendOtpApi: string = '';
   mobileSendOtpApi: string = '';
   emailVerificationApi: string = '';
   mobileVerificationApi: string = '';
   @Input() verificationRequired: boolean = true;
   @Input() action: string | null = null;
   @Input() isInsidePopup: boolean = false;
   @Output() formChange: EventEmitter<string> = new EventEmitter();
   countries: Country[] = [];
   showUnverifiedError: boolean = false;
   isMobileVerificationEnabled: boolean = false;
   verifiedEmail: string = '';

   constructor(
      public analyticsUtils: AnalyticsUtils,
      private fb: FormBuilder,
      private userApiService: UserApiService,
      private toaster: ToastrService,
      private auth: AuthService,
      private subscriptionService: SubscriptionService,
      private router: Router,
      private sharedService: SharedService,
      private countryCodeService: CountryCodeService,
      private userApiServices: UserApiService,
      private modalService: NgbModal,
      private cdr: ChangeDetectorRef,
      private firebaseRemoteConfigService: FirebaseRemoteConfigService
   ) {}

   async ngOnInit(): Promise<void> {
      this.countries = this.countryCodeService.countries;
      let firstName = '';
      let lastName = '';
      let email = '';
      let mobile = '';
      let countryCode = '';
      let country = await this.userApiService.getUserCountryCode();
      if(country.length) {
         countryCode = this.countries.find((c) => c.code === country)?.dial_code ?? '';
      }
      if(!this.verificationRequired) {
         this.isEmailVerified = true;
         this.cdr.detectChanges();
         const user = await lastValueFrom(this.userApiServices.getUserData(true));
         this.source = user.registration_type;
         this.verifiedEmail = user.email;
         if(user) {
            firstName = user.firstname ?? '';
            lastName = user.lastname ?? '';
            email = user.email ?? '';
            mobile = user.phone ?? '';
         }
      }
      const formGroup: any = {
         firstName: [firstName, [Validators.required]],
         lastName: [lastName, [Validators.required]],
         email: [
            email,
            [
               Validators.email,
               Validators.required,
               Validators.pattern(
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-z]{1,5})\b/
               ),
            ],
         ],
         mobile: [
            '',
            [
               Validators.required,
               Validators.pattern(/^[0-9]*$/),
               Validators.minLength(7),
               Validators.maxLength(11),
            ],
         ],
         countryCode: [countryCode, [Validators.required]],
      };

      if (this.verificationRequired) {
         formGroup.password = [
            '',
            [
               Validators.minLength(7),
               Validators.required,
               Validators.pattern(
                  '(?!.* )(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{7,30}'
               ),
            ],
         ];
         formGroup.code = ['', [Validators.minLength(4), Validators.required]];
      }
      this.signupForm = this.fb.group(formGroup);
      this.formInitComplete = true

      const verificationDefaults = await this.firebaseRemoteConfigService.getVerificationDefaults();
      this.isMobileVerificationEnabled = verificationDefaults.mobile_verification.enabled;
      if(!this.isMobileVerificationEnabled) {
         this.isMobileVerified = true;
      }
      // verificationDefaults.mobile_verification.disabled_countries.forEach((country: string) => {
      //    this.countries = this.countries.filter(c => c.code !== country);
      // });
   }

   onEmailVerifiedChange(event: boolean) {
      this.isEmailVerified = event;
      if(this.isEmailVerified) {
         this.verifiedEmail = this.signupForm.value.email;
      }
   }

   onMobileVerifiedChange(event: boolean) {
      this.isMobileVerified = event;
   }

   isFormValid() {
      return (
         this.signupForm.controls.firstName.valid &&
         this.signupForm.controls.lastName.valid &&
         this.signupForm.controls.email.valid &&
         this.signupForm.controls.mobile.valid &&
         this.signupForm.controls.countryCode.valid &&
         (!this.verificationRequired || this.signupForm.controls.password.valid)
      );
   }

   onCountryCodeChange(event: any) {
      this.signupForm.controls.countryCode.setValue(event.dial_code);
   }

   getUserData() {
      const userData = {
         email: this.verifiedEmail,
         firstname: this.signupForm.value.firstName,
         lastname: this.signupForm.value.lastName,
         password: this.signupForm.value.password,
         mobile:
            this.signupForm.value.countryCode + this.signupForm.value.mobile,
      };
      return userData;
   }

   async sendVerificationMail() {
      if (!this.isFormValid) {
         return;
      }
      this.errorMessage = '';
      const userData = this.getUserData();
      this.analyticsUtils.SignupFlow_OTPScreen_Opened(userData.email);
      try {
         this.showMailLoader = true;
         const resp =
            await this.userApiService.sendVerificationMail<VerificationMailResponse>(
               userData
            );

         if (resp.status) {
            if (resp.data.is_new) {
               this.identifier = resp.data.identifier;
               this.showMailLoader = false;
               this.isOTPMailSent = true;
               this.signupForm.controls.code.setValue('');
               this.signupForm.controls.code.markAsUntouched();
               this.inputTimer();
               this.analyticsUtils.SignupFlow_RegistrationScreen_SignupCompleted(
                  userData.email
               );
               this.toaster.success('Verification Pin sent');
            } else {
               this.errorMessage = resp.message;
               this.showMailLoader = false;
            }
         } else {
            this.errorMessage = resp.message;
            this.analyticsUtils.SignupFlow_RegistrationScreen_Failed(
               userData.email,
               this.errorMessage
            );
            this.showMailLoader = false;
         }
      } catch (error) {
         console.error(error);
         this.showMailLoader = false;
      } finally {
         this.showMailLoader = false;
      }
   }

   async registerUser() {

      if (!this.isFormValid) {
         return;
      }
      this.showMailLoader = true;
      if(this.signupForm.value.mobile) {
         const userData = this.getUserData();
         const resp: any = await this.userApiService.registerUser(userData);
         if (resp.status) {
            this.auth.setToken(resp.data.token);
            await this.subscriptionService.setUserSubscriptionInfo();
            const subInfo = SubscriptionService.subscriptionData.getValue();

            if (subInfo.role === 'FREEMIUM') {
               this.sharedService.openUpgradePopup();
               // this.subscriptionService.openPaywallModal();
            }
            const userData =
               await this.userApiService.fetchUserDetailsAndSave();
            this.analyticsUtils.SignupFlow_SaveUser_Completed(this.source.toLowerCase());
            this.sharedService.afterSignUpActions(userData.email);
            this.signupForm.controls.firstName.setValue(userData.firstname);
            this.signupForm.controls.lastName.setValue(userData.lastname);
            this.auth.setLoggedIn();
            this.router.navigate(['/'], { replaceUrl: true });
            this.showSignUpLoader = false;

            this.modalService.open(UserOnboardingServeComponent, {
               modalDialogClass: 'paywall-popup-modal',
               centered: true,
               scrollable: true,
               size: 'xl',
            });
         } else {
            this.toaster.error(resp.message);
            // this.analyticsUtils.SignupFlow_VerifyOTP_Failed(
            //    this.signupForm.value.email,
            //    resp.message
            // );
            this.signupForm.controls.code.setValue('');
            this.signupForm.controls.code.markAsUntouched();
            this.showSignUpLoader = false;
         }
      }
      this.showMailLoader = false;
   }

   inputTimer() {
      this.showPinInput = true;
      let interval = setInterval(() => {
         if (this.seconds == 0) {
            clearInterval(interval);
            this.showPinInput = false;
            this.seconds = this.nextResendDuration;
            this.signupForm.controls.code.markAsUntouched();
         } else {
            this.seconds--;
         }
      }, 1000);
   }

   async resendOTPMail() {
      try {
         this.showOTPLoader = true;
         const userEmail = {
            email: this.signupForm.value.email,
         };
         this.analyticsUtils.resend_otp_tapped();

         let res = await this.userApiService.sendOTPMail<OTPMailResponse>(
            userEmail
         );
         if (res.status) {
            this.identifier = res.data.identifier;
            this.showOTPLoader = false;
            this.inputTimer();
         }
      } catch (error) {
         console.error(error);
         this.showOTPLoader = false;
      } finally {
         this.showOTPLoader = false;
      }
   }

   submitSignupForm(event: Event) {
      event.preventDefault();
      if(this.isEmailVerified && this.isMobileVerified) {
         if (this.verificationRequired) {
            this.registerUser();
         } else {
            this.saveUser();
         }
      } else {
         // if(!this.isEmailVerified && !this.isMobileVerified) {
         //    this.toaster.error('Please verify your email and mobile number');
         // } else if(!this.isEmailVerified) {
         //    this.toaster.error('Please verify your email');
         // } else {
         //    this.toaster.error('Please verify your mobile number');
         // }
         this.showUnverifiedError = true;
      }
   }

   async saveUser() {
      this.showMailLoader = true;
      if(this.signupForm.value.mobile) {
         const userData = this.getUserData();
         const resp: any = await this.userApiService.activateUser({mobile: userData.mobile});
         if(resp.status) {
            this.analyticsUtils.SignupFlow_SaveUser_Completed(this.source.toLowerCase());
            this.modalService.open(UserOnboardingServeComponent, {
               centered: true,
               scrollable: true,
               size: 'xl',
               modalDialogClass: 'paywall-popup-modal',
            });
            this.router.navigate(['/']);
         }
      }
      this.showMailLoader = false;
   }


   async activationCode(event: Event) {
      event.preventDefault();
      this.showSignUpLoader = true;
      const activationCode = this.signupForm.value.code
         .trim()
         .replace(/\s/g, '');
      this.analyticsUtils.SignupFlow_VerifyOTP_Tapped(
         this.signupForm.value.email
      );

      let request = {
         identifier: this.identifier,
         activationCode: activationCode,
      };

      try {
         let resp = await this.userApiService.verifyOTPCode<VerifyOTPResponse>(
            request
         );
         if (resp.status) {
            this.auth.setToken(resp.data.token);
            await this.subscriptionService.setUserSubscriptionInfo();
            const subInfo = SubscriptionService.subscriptionData.getValue();

            if (subInfo.role === 'FREEMIUM') {
               this.sharedService.openUpgradePopup();
               // this.subscriptionService.openPaywallModal();
            }
            const userData =
               await this.userApiService.fetchUserDetailsAndSave();
            this.analyticsUtils.SignupFlow_VerifyOTP_Completed(userData.email);
            this.sharedService.afterSignUpActions(userData.email);
            this.signupForm.controls.firstName.setValue(userData.firstname);
            this.signupForm.controls.lastName.setValue(userData.lastname);
            this.auth.setLoggedIn();
            this.router.navigate(['/'], { replaceUrl: true });
            this.showSignUpLoader = false;

            this.modalService.open(UserOnboardingServeComponent, {
               modalDialogClass: 'paywall-popup-modal',
               centered: true,
               scrollable: true,
               size: 'xl',
            });
         } else {
            this.toaster.error(resp.message);
            this.analyticsUtils.SignupFlow_VerifyOTP_Failed(
               this.signupForm.value.email,
               resp.message
            );
            this.signupForm.controls.code.setValue('');
            this.signupForm.controls.code.markAsUntouched();
            this.showSignUpLoader = false;
         }
      } catch (error) {
         this.showSignUpLoader = false;
      } finally {
         this.showSignUpLoader = false;
      }
   }

   signIn() {
      this.formChange.emit('signIn');
   }

   togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
   }

   @HostBinding('class')
   get formStyling(): string {
      if (this.isInsidePopup) {
         return 'form-styling';
      } else {
         return '';
      }
   }
}
