<!-- loader container start -->
<div class="loader-wrapper d-flex align-items-center sticky-top" *ngIf="headerLoader">

    <div class="container d-flex align-items-center justify-content-between w-100 gap-4" *ngIf="isMobile">
        <span class="d-block h-100 rounded skeleton-loader pt-3 pb-4 px-5"></span>
        <div class="d-flex align-items-center gap-2">
            <span class="d-block h-100 rounded-circle skeleton-loader p-3"></span>
            <span class="d-block h-100 rounded-circle skeleton-loader p-3"></span>
            <span class="d-block h-100 rounded skeleton-loader ps-3 pe-5 py-3"></span>
            <span class="d-block h-100 rounded skeleton-loader p-3"></span>
        </div>
    </div>

    <div class="container mx-auto d-flex flex-column" *ngIf="!isMobile">
        <div class="d-flex align-items-center justify-content-between py-3 gap-5 top-loader">
            <div class="d-flex align-items-center gap-4">
                <span class="d-block h-100 rounded skeleton-loader pt-3 pb-4 logo-loader"></span>
                <span class="d-block h-100 rounded skeleton-loader pt-3 pb-4 search-loader"></span>
            </div>
            <div class="d-flex align-items-center gap-4">
                <span class="d-block h-100 rounded skeleton-loader p-3"></span>
                <span class="d-block h-100 rounded skeleton-loader p-3"></span>
                <span class="d-block h-100 rounded skeleton-loader py-3 px-5"></span>
                <span class="d-block h-100 rounded-circle skeleton-loader pt-3 pb-4 ps-3 pe-4"></span>
            </div>
        </div>
        <div class="d-flex align-items-center gap-4 py-2 bottom-loader">
            <span class="d-block h-100 rounded skeleton-loader pt-3 pb-2 px-5"
                *ngFor="let i of [1,2,3,4,5,6,7,8]"></span>
        </div>
    </div>
</div>
<!-- loader container end -->

<header class="d-flex flex-column primary-header sticky-top" *ngIf="!headerLoader">
    <div class="container d-flex align-items-center justify-content-between header-btns-and-search">
        <div class="d-flex align-items-center gap-3 gap-xl-4">

            <!-- <button class="btn shadow-none py-2 px-2 hamburger-btn" (click)="toggleSidebar()">
                <img src="/assets/images/header/hamburger.svg" alt="Hamburger Icon">
            </button> -->


            <!-- <a routerLink="/" class="d-block py-2 pe-2 logo-wrapper"
                [ngClass]="{'d-none': (isSidebarOpen || isMobile)}">
                <img appLazyLoadImage="/assets/images/musaffa-logo-black.png"
                    placeholder="/assets/images/musaffa-logo-white-lowres.png" alt="Musaffa Logo"
                    class="header-logo img-fluid" />
            </a> -->
            <a routerLink="/" class="d-block logo_mobile_only">
                <img appLazyLoadImage="/assets/images/header/musaffa-logo-white.webp"
                    placeholder="/assets/images/home/logo.svg" alt="Musaffa Logo" class="header-logo img-fluid" />
            </a>
            <div class="d-none d-lg-block" *ngIf="!isMobile">
                <app-header-searchbar></app-header-searchbar>
            </div>
            <li class="d-block desktop_only_link">
                <a class="d-block text-decoration-none desktop-link" routerLink="/for-business">For
                    Business</a>
            </li>
            <li class="d-block desktop_only_link">
                <a routerLink="/ambassador-program" class="d-block text-decoration-none desktop-link">Ambassador</a>
            </li>
            <li class="d-block desktop_only_link">
                <a routerLink="/about-us" class="d-block text-decoration-none desktop-link">About Us</a>
            </li>
            <li class="d-block desktop_only_link">
                <button class="btn shadow-none rounded-3 gradient-border-btn" data-featurebase-feedback-portal>
                    <span>What's New</span> ✨
                </button>
            </li>
        </div>

        <ul class="d-flex align-items-center gap-2 gap-lg-4" role="list" *ngIf="isMobile">

            <li>
                <button class="btn shadow-none px-1 search-btn d-lg-none" (click)="openSearchBottomSheet()">
                    <i class="bi icon-search"></i>
                </button>
                <!-- <app-stocks-and-etfs-search></app-stocks-and-etfs-search> -->
            </li>
            <li *ngIf="isLoggedIn">
                <app-header-user-notifications></app-header-user-notifications>
            </li>
            <!-- <li *ngIf="shouldShowCountryDropdown">
                <app-header-countries-dropdown></app-header-countries-dropdown>
            </li> -->
            <li *ngIf="isLoggedIn">
                <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
                    <button class="btn shadow-none rounded-circle p-0 avatar-dropdown-btn" id="avatarBtnDropdown"
                        ngbDropdownToggle>
                        <span class="avatar-text">{{ fullUserName.charAt(0).toUpperCase() }}</span>
                    </button>
                    <div class="avatar-btn-dropdown border-0 p-2" ngbDropdownMenu aria-labelledby="avatarBtnDropdown">
                        <div class="desktop-user-info mb-2 rounded-3">
                            <h6 class="welcome-text fw-600">Welcome</h6>
                            <p class="mb-0 line-clamp-1 username">{{ fullUserName }}</p>
                        </div>
                        <!-- <hr class="my-2"> -->
                        <ul role="list" class="">
                            <li *ngIf="shouldShowCountryDropdown">
                                <div class="d-flex align-items-center justify-content-between pe-2">
                                    <div class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item">
                                        <img src="/assets/icons/globe-icon.svg" alt="Globe Icon"
                                            style="width: 16px; height: 16px;">
                                        <span>Default</span>
                                    </div>
                                    <app-header-countries-dropdown></app-header-countries-dropdown>
                                </div>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/user')">
                                    <img src="/assets/images/header/profile.svg" alt="Profile Icon">
                                    <span>Profile Details</span>
                                </button>
                            </li>
                            <li *ngIf="alreadyJoined">
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="openJoinReferralPage()">
                                    <img src="/assets/icon/currency-dollar.svg" alt="Dollar Icon">
                                    <span>Show Referral Portal</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/stock-requests')">
                                    <img src="/assets/images/header/request.svg" alt="Request Icon">
                                    <span>My Stocks Requests</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/user/support')">
                                    <img src="/assets/images/header/message.svg" alt="Message Icon">
                                    <span>Get in touch</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 text-danger fw-500 gap-3 user-info-item"
                                    (click)="logout()">
                                    <img src="/assets/images/header/logout.svg" alt="Logout Icon">
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>

            <li *ngIf="!isLoggedIn">
                <a class="btn text-decoration-none shadow-none text-white mobile-login-btn signin_btn"
                    routerLink="/authentication/login">
                    Sign in
                </a>
            </li>

            <!-- <li *ngIf="!isLoggedIn && !isMobile">
                <a class="btn text-decoration-none shadow-none rounded-3 text-white login-btn signin_up py-2 px-3 px-lg-4"
                    routerLink="/authentication/registration">
                    Sign up
                </a>
            </li> -->

        </ul>

        <ul class="d-flex align-items-center gap-2 gap-lg-3" role="list" *ngIf="!isMobile">
            <li class="d-flex align-items-center desktop_only_link" *ngIf="isLoggedIn && userNewRole === 'free'">
                <a class="btn text-gradient-no-bold border-0 d-flex align-items-center gap-2 desktop-link"
                    routerLink="/pricing">
                    <img src="/assets/icons/premium-gold.svg" alt="Icon" class="mb-1 img-fluid">
                    <span>Go Premium</span>
                </a>
            </li>
            <li class="d-flex align-items-center desktop_only_link"
                *ngIf="isLoggedIn && userNewRole && ['starter', 'premium'].includes(userNewRole)">
                <a class="btn text-gradient-no-bold border-0 d-flex align-items-center gap-2 desktop-link"
                    routerLink="/pricing">
                    <img src="/assets/icons/premium-gold.svg" alt="Icon" class="mb-1 img-fluid"
                        style="width: 14px; height: 14px;">
                    <span>Upgrade</span>
                </a>
            </li>
            <li *ngIf="isLoggedIn">
                <app-header-user-notifications></app-header-user-notifications>
            </li>
            <li *ngIf="isLoggedIn">
                <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
                    <button class="btn shadow-none rounded-circle p-0 avatar-dropdown-btn" id="avatarBtnDropdown"
                        ngbDropdownToggle>
                        {{ fullUserName.charAt(0).toUpperCase() }}
                    </button>
                    <div class="avatar-btn-dropdown border-0 p-2" ngbDropdownMenu aria-labelledby="avatarBtnDropdown">
                        <div class="desktop-user-info mb-2 rounded-3">
                            <h6 class="welcome-text fw-600">Welcome</h6>
                            <p class="mb-0 line-clamp-1 username">{{ fullUserName }}</p>
                        </div>
                        <!-- <hr class="my-2"> -->
                        <ul role="list" class="">
                            <li *ngIf="shouldShowCountryDropdown">
                                <div class="d-flex align-items-center justify-content-between pe-2">
                                    <div class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item">
                                        <img src="/assets/icons/globe-icon.svg" alt="Globe Icon"
                                            style="width: 16px; height: 16px;">
                                        <span>Default</span>
                                    </div>
                                    <app-header-countries-dropdown></app-header-countries-dropdown>
                                </div>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/user')">
                                    <img src="/assets/images/header/profile.svg" alt="Profile Icon">
                                    <span>Profile Details</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="openJoinReferralPage()">
                                    <img src="/assets/icons/currency-dollar.svg" alt="Dollar Icon">
                                    <span>Show Referral Portal</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/stock-requests')">
                                    <img src="/assets/images/header/request.svg" alt="Request Icon">
                                    <span>My Stocks Requests</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 fw-500 gap-3 user-info-item"
                                    (click)="navigateTo('/user/support')">
                                    <img src="/assets/images/header/message.svg" alt="Message Icon">
                                    <span>Get in touch</span>
                                </button>
                            </li>
                            <li>
                                <button ngbDropdownItem
                                    class="d-flex align-items-center rounded-3 p-2 text-danger fw-500 gap-3 user-info-item"
                                    (click)="logout()">
                                    <img src="/assets/images/header/logout.svg" alt="Logout Icon">
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li class="" *ngIf="!isLoggedIn">
                <a class="btn text-decoration-none shadow-none rounded-3 text-white login-btn signin_btn py-2 px-3 px-lg-3"
                    routerLink="/authentication/login">
                    Sign In
                </a>
            </li>
            <li class="" *ngIf="!isLoggedIn">
                <a class="btn text-decoration-none shadow-none rounded-3 text-white login-btn signin_up py-2 px-3 px-lg-3"
                    routerLink="/authentication/registration">
                    Free Sign Up
                </a>
            </li>
        </ul>

    </div>

</header>

<!-- mobile side nav start -->
<!-- <aside class="mobile-menu" [class.mobile-menu-open]="isMobileMenuOpen" *ngIf="isMobile">
    <header class="d-flex px-2 align-items-center justify-content-between border-bottom">
        <div class="logo-wrapper">
            <img src="/assets/images/musaffa-logo.svg" alt="Musaffa Logo" class="header-logo img-fluid" />
        </div>
        <div class="d-flex align-items-center gap-3">
            <button class="btn shadow-none fw-500 rounded-3 whats-new-btn" data-featurebase-feedback-portal>
                <span>What's New ✨</span>
            </button>
            <button class="btn shadow-none p-2 bg-light rounded-3" (click)="closeMobileMenu()">
                <img src="/assets/images/header/close.svg" alt="Close">
            </button>
        </div>
    </header>
    <nav class="container-xxl p-0 mobile-nav">
        <article class="user-info-card d-flex align-items-center p-3 gap-3" *ngIf="user && isLoggedIn">
            <div class="border bg-light d-flex align-items-center justify-content-center user-avatar rounded-circle">
                <span class="d-block fs-4">{{ fullUserName | stringInitials}}</span>
            </div>
            <div>
                <h6 class="mb-1 d-flex align-items-center gap-2">
                    <span>{{ fullUserName }}</span>
                    <img src="/assets/images/crown-icon.svg" alt="crown-icon" class="crown-icon img-fluid"
                        *ngIf="userRole === 'PREMIUM'">
                </h6>
                <a (click)="closeMobileMenu()" routerLink="/user" class="d-block">View Profile Details</a>
            </div>
        </article>
        <ul class="mobile-menu-links p-3" role="list">
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/portfolio-dashboard"
                    (click)="closeMobileMenu()">My Portfolio</a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none"
                    (click)="closeMobileMenu(); openLoginOrNavigate('/watchlist-lists')">My Watchlist</a>
            </li>
            <hr>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/stock-screener"
                    (click)="closeMobileMenu()">Stock
                    Screener</a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/etf-screener" (click)="closeMobileMenu()">ETF
                    Screener</a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/super-investors"
                    (click)="closeMobileMenu()">Super
                    Investors </a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/purification"
                    (click)="closeMobileMenu()">Purification Calculator </a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/zakat-calculator"
                    (click)="closeMobileMenu()">Zakat Calculator</a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/academy-articles"
                    (click)="closeMobileMenu()">Musaffa Academy</a>
            </li>
            <li class="d-block">
                <a class="d-block py-2 text-decoration-none" routerLink="/for-business" (click)="closeMobileMenu()">For
                    Business</a>
            </li>

            <ng-container *ngIf="userNewRole === null || userNewRole === 'free'">
                <hr>
                <li class="d-block">
                    <a class="border-0 bg-transparent d-flex align-items-center gap-2 py-2 text-decoration-none text-gradient"
                        (click)="closeMobileMenu()" routerLink="/pricing">
                        <img src="/assets/icons/premium-gold.svg" alt="Icon" class="mb-1 img-fluid">
                        <span class="fw-600">Go Premium</span>
                    </a>
                </li>
            </ng-container>

        </ul>
    </nav>
    <div class="p-3" *ngIf="isLoggedIn">
        <button class="btn d-block shadow-none rounded-3 lh-base fw-500 w-100 gradient-border logout-btn py-2 px-4"
            (click)="logout()">
            Logout
        </button>
    </div>
</aside> -->


<!-- search dropdown start -->
<ng-container *ngIf="isMobile">
    <div class="mobile-list-container" [ngClass]="isSearchBottomSheetOpen ? 'menu-open' : ''">
        <header class="d-flex p-3 align-items-center justify-content-between">
            <h5 class="mb-0">Search Stocks & ETFs</h5>
            <button class="btn bg-light shadow-none rounded-3 p-2" (click)="closeSearchBottomSheet()">
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path fill="#000"
                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
            </button>
        </header>

        <div>
            <app-stocks-and-etfs-search [isSearchBottomSheetVisible]="isSearchBottomSheetOpen"
                (closeBottomSheetEmitter)="closeSearchBottomSheet()">
            </app-stocks-and-etfs-search>
        </div>
    </div>
</ng-container>
<!-- search dropdown end -->