<section class="pt-4 pt-lg-5 bg-white">
   <div class="container text-center">
      <h3 class="fw-600 mb-3">As Featured In</h3>

      <div class="scroller" [attr.data-direction]="direction" [attr.data-speed]="speed">
         <ul role="list" class="d-flex align-items-center scroller__inner">
            <li class="d-flex align-items-center logos-list-item overflow-hidden"
               *ngFor="let feature of featuredInLogos; trackBy: trackByFn">
               <a [href]="feature.redirectUrl" target="_blank">
                  <img [src]="feature.imagePath" class="img-fluid" [alt]="feature.id">
               </a>
            </li>
         </ul>
      </div>

   </div>
</section>