<div class="modal-body p-0">
   <article>
      <h3 class="mb-0 fw-600">
         Disclaimer
      </h3>
      <div>
         <p class="mb-2">
            The content provided on this website is intended solely for informational and educational purposes. Musaffa is a technology, data, education, and research company. We are not licensed financial service provider by the authorized relevant <a href="https://dea.gov.in/business/financial-regulators" target="_blank">financial service regulator</a> in India or any other regulatory government body, and we do not offer or facilitate any trading, brokerage, investment, or financial advisory services. We do not deliberately target an audience seeking financial services.
         </p>
         <p class="mb-0">
            By accessing this website, you acknowledge and confirm that you are seeking information relating to Musaffa of your own accord, and that there has been no form of solicitation, advertisement, or inducement by Musaffa or its members. Musaffa shall not be liable for the consequences of any action taken by relying on the material or information provided on this website. The contents of this website are the intellectual property of Musaffa.
         </p>
      </div>
      <div class="form-check d-flex align-item-center gap-2">
         <input class="form-check-input shadow-none" type="checkbox" [(ngModel)]="isAccepted" id="acceptCheckbox">
         <label class="form-check-label" for="acceptCheckbox">
            I accept the above
         </label>
      </div>
      <button class="btn d-flex align-items-center gap-2 shadow-none gradient-btn text-white py-2 px-3 rounded-3" [disabled]="!isAccepted" (click)="closeDisclaimerPopup()">
         <span>Continue to Musaffa </span>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
            <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
   </article>
</div>
