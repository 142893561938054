import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryService } from 'src/app/shared/services/country.service';
@Component({
   selector: 'app-plaid-card',
   templateUrl: './plaid-card.component.html',
   styleUrls: ['./plaid-card.component.scss'],
})
export class PlaidCardComponent implements OnInit {
   @Input() isInsideBrokeragesPopup: boolean = false;
   @Output() plaidLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

   loading: boolean = false;

   constructor(
      public activeModal: NgbActiveModal,
      private countryService: CountryService,
      private pricingService: PricingService,
      private authService: AuthService
   ) {}

   ngOnInit(): void {}

   loadPlaidScript() {
      if (
         document.querySelector(
            'script[src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"]'
         )
      ) {
         console.log('Plaid script is already loaded');
         return;
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
         console.log('Plaid script loaded successfully');
      };

      script.onerror = () => {
         console.error('Failed to load the Plaid script');
      };
   }

   openPlaid() {
      const buttonContainer = document.createElement('div');
      buttonContainer.id = 'plaidButtonContainer';

      if (window.Plaid) {
         (async () => {
            try {
               const response = await this.pricingService.createLinkToken();
               const { link_token } = await response;
               const handler = window.Plaid.create({
                  token: link_token,
                  onSuccess: async (public_token: string, metadata: any) => {
                     const params = {
                        public_token,
                        webhook_type: 'CONNECTION_ADDED',
                        userId: this.authService.getUserId(),
                        link_token,
                     };
                     let exchangeResponse;
                     let data;
                     let retries = 3;
                     while (retries > 0) {
                        try {
                           this.loading = true;
                           this.plaidLoading.emit(true);
                           alert('Please Wait for Few Minutes...');
                           exchangeResponse =
                              await this.pricingService.plaidWebhook(params);
                           data = exchangeResponse;
                           if (data?.status === true) {
                              break;
                           }
                           console.warn('Webhook failed, retrying...');
                           retries--;
                        } catch (error) {
                           console.error('Error in plaidWebhook call:', error);
                           if (retries <= 1) {
                              throw error;
                           }
                           retries--;
                        }
                     }
                     if (!data || data?.status !== true) {
                        console.error('Webhook failed after all retries.');
                        throw new Error('Webhook retry attempts exhausted.');
                     }
                     if (data.status == true) {
                        this.loading = false;
                        this.plaidLoading.emit(false);
                        alert('Webhook Status : ' + data.message);
                        window.location.reload();
                     }
                  },
                  onExit: (err: any, metadata: any) => {
                     if (err) console.error('Exit Error:', err);
                  },
               });
               handler.open();
            } catch (error) {
               console.error('Error initializing Plaid Link:', error);
            }
         })();
      } else {
         console.error('Plaid library is not loaded');
      }
   }

   initiatePlaid() {
      const supported_country_arr = ['US', 'CA'];
      let country = this.countryService.getSelectedCountry().countryCode;
      this.activeModal.dismiss();
      if (supported_country_arr.includes(country)) {
         this.loadPlaidScript();
         this.openPlaid();
         return;
      } else {
         alert(
            "Plaid is not Supported In Your Country Please Reselect Your Country to ['US','CA']"
         );
      }
   }
}
