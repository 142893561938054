import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-premium-member-greeting-popup',
  templateUrl: './premium-member-greeting-popup.component.html',
  styleUrls: ['./premium-member-greeting-popup.component.scss']
})
export class PremiumMemberGreetingPopupComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userNewRole: Role = null;
   planText: string = ''

   constructor(public congratulationsModal: NgbActiveModal) {}

   ngOnInit(): void {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userNewRole = data.newRole;
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getPlanText(role: string | null) {
      switch (role) {
         case 'starter':
            return 'Musaffa Starter';
         case 'premium':
            return 'Musaffa Premium';
         case 'premium_plus':
            return 'Musaffa Premium Plus';
         default:
            return '';
      }
   }

}
