import { Component, ElementRef, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
interface OtpVerificationResponse {
  success: boolean;
  message: string;
  status_code?: string;
}

type ValidationRule = () => boolean | Promise<boolean>;

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyOtpComponent implements AfterViewInit {
  @ViewChild('otpModal') otpModal!: ElementRef;
  @ViewChild('emailExistsModal') emailExistsModal!: ElementRef;
  @Input() isVerified = false;
  @Input() inputValue = '';
  @Input() verificationType = 'email';
  @Input() disabled = false;
  @Input() validationRule?: ValidationRule;
  @Output() isVerifiedChange = new EventEmitter<boolean>();

  otpValue = '';
  isVerifying = false;
  isSending = false;
  isValidating = false;
  invalidOtp = true;
  otpSent = false;

  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private userApiService: UserApiService, private toaster: ToastrService, private router: Router) { }

  async openVerificationModal(content: any): Promise<void> {
    if (await this.validateBeforeVerification()) {
      this.otpValue = '';
      try {
        const result = await this.modalService.open(content, {
          ariaLabelledBy: 'modal-basic-title',
          centered: true
        }).result;
        //   if (result === 'verify') {
        //   }
      } catch (error) {
        // Modal dismissed
      }
    }
  }

  loginClick() {
    this.modalService.dismissAll();
    this.router.navigate(['/authentication/login']);
  }

  async openEmailExistsModal() {
    this.modalService.open(this.emailExistsModal, {
      centered: true,
      size: 'md'
    });
  }

    ngAfterViewInit() {
      console.log('this.isVerified');
      console.log(this.isVerified);
      // this.openVerificationModal(this.otpModal);
      // this.openEmailExistsModal();
    }

  private async validateBeforeVerification(): Promise<boolean> {
    if (!this.validationRule) {
      return true;
    }

    this.isValidating = true;
    try {
      const isValid = await Promise.resolve(this.validationRule());
      return isValid;
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    } finally {
      this.isValidating = false;
    }
  }

  public async verifyOtpClick(): Promise<void> {
    if (this.otpValue.length !== this.otpConfig.length) {
      this.invalidOtp = true;
      return;
    }
    this.isVerifying = true;
    try {
      const response: OtpVerificationResponse = await this.verify();
      if (response.success) {
        this.isVerified = true;
        this.isVerifiedChange.emit(true);
        this.modalService.dismissAll();
      } else {
        this.toaster.error(response.message);
        this.invalidOtp = true;
      }
    } catch (error) {
      console.error('Verification Error:', error);
    } finally {
      this.isVerifying = false;
    }
  }

  resendOtpClick() {
   //  this.startTimer();
    this.sendOtpClick(false);
  }

  public async sendOtpClick(isOpenModal: boolean = true): Promise<void> {
   this.startTimer();
    this.isSending = true;
    this.otpSent = false;
    try {
      // TODO: Replace with actual API call
      const response: OtpVerificationResponse = await this.sendOtp();

      if (response.success) {
        this.otpSent = true;
        if (isOpenModal) {
          this.openVerificationModal(this.otpModal);
        }
      } else {
         if (response.status_code === 'ALREADY_EXISTS') {
            this.openEmailExistsModal();
        } else {
          this.toaster.error(response.message);
        }
      }
    } catch (error) {
      // TODO: Handle error
    } finally {
      this.isSending = false;
    }
  }

  private async sendOtp(): Promise<OtpVerificationResponse> {
    if (this.verificationType === 'email') {
      const response: any = await this.userApiService.sendEmailVerificationOtp(this.inputValue);
      return {
        success: response.status,
        message: response.message,
        status_code: response.status_code
      };
    } else {
      const response: any = await this.userApiService.sendMobileVerificationOtp(this.inputValue);
      return {
        success: response.status,
        message: response.message,
        status_code: response.status_code
      };
    }
  }

  private async verify(): Promise<OtpVerificationResponse> {
    if (this.verificationType === 'email') {
      const response: any = await this.userApiService.verifyEmail(this.inputValue, this.otpValue);
      return {
        success: response.status,
        message: response.message,
        status_code: response.status_code
      };
    } else {
      const response: any = await this.userApiService.verifyMobile(this.inputValue, this.otpValue);
      return {
        success: response.status,
        message: response.message,
        status_code: response.status_code
      };
    }
  }

  // OTP
  otpConfig = {
    length: 4,  // ✅ 4 digit OTP
    isPasswordInput: false,
    disableAutoFocus: false,
    allowNumbersOnly: true,  // ✅ Only numbers allowed
    containerClass: 'otp-container',  // ✅ Custom class for styling
    inputClass: 'otp-input' // ✅ Custom class for styling input boxes
  };

  onOtpChange(otp: string) {
    this.otpValue = otp;  // ✅ Ensure OTP value is updated
    this.invalidOtp = false; // ✅ Reset invalid OTP flag when user enters value
  }

  // OTP TIMER
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  timer: number = 60;  // ⏳ 60 seconds ka countdown
  interval: any;
  isResendDisabled: boolean = true;

  startTimer() {
    this.timer = 60;
    this.isResendDisabled = true;

    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
        this.cdRef.detectChanges(); // ✅ UI Update
      } else {
        this.isResendDisabled = false;
        clearInterval(this.interval);
        this.cdRef.detectChanges();
      }
    }, 1000);
  }

  ngOnInit() {
   //  this.startTimer();
  }

}
