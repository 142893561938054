import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateManualPortfolioPopupComponent } from '../components/create-manual-portfolio-popup/create-manual-portfolio-popup.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { RequestHolding } from 'src/app/models/portfolio.model';
import { TransactionObj } from 'src/app/models/portfolio.model';

@Injectable({
   providedIn: 'root',
})
export class MyPortfolioService {
   private createManualPortfolioRef: NgbModalRef | undefined;
   private tabChangeSubject = new BehaviorSubject<any>('investment');

   // Observable that components can subscribe to
   tabChange$ = this.tabChangeSubject.asObservable();
   url: string = environment.apiUrl;

   setTab(tab: string) {
      this.tabChangeSubject.next(tab);
   }
   private manualPortfolioNameSubject = new BehaviorSubject<string>('');
   manualPortfolioName$ = this.manualPortfolioNameSubject.asObservable();

   private selectedHoldingIndexSubject = new BehaviorSubject<number>(0);
   selectedHoldingIndex$ = this.selectedHoldingIndexSubject.asObservable();

   constructor(private http: HttpClient, private modalService: NgbModal) {}

   setSelectedHoldingIndex(index: number): void {
      this.selectedHoldingIndexSubject.next(index);
   }

   setManualPortfolioName(name: string): void {
      this.manualPortfolioNameSubject.next(name);
   }

   fetchPortfolioHoldings<T>(account_id: string): Observable<T> {
      return this.http.post<T>(this.url + '/api/portfolio/account-holdings', {
         account_id,
      });
   }

   addHoldings<T>(account_id: string, holdings: any[]): Observable<T> {
      return this.http.post<T>(this.url + '/api/portfolio/manual/holdings', {
         account_id,
         holdings,
      });
   }

   fetchConvertedPrice<T>(ticker: string, currency: string): Observable<T> {
      return this.http.post<T>(this.url + '/api/fetch-converted-price', {
         ticker,
         currency,
      });
   }

   fetchHistoricalPrice<T>(
      ticker: string,
      currency: string,
      date: string
   ): Observable<T> {
      return this.http.post<T>(this.url + '/api/stocks/' + encodeURIComponent(ticker) + '/price', {
         date,
      });
   }

   // async getHistoricalPrice(ticker: string, currency: string, date: string) {
   //    return await lastValueFrom(
   //       this.http.post<any>(this.url + '/api/stocks/' + ticker + '/price', {
   //          date,
   //       })
   //    );
   // }

   addTransaction<T>(transactionData: TransactionObj): Observable<T> {
      return this.http.post<T>(
         this.url + '/api/portfolio/manual/holdings/transactions',
         transactionData
      );
   }

   deleteTransaction<T>(id: string): Observable<T> {
      return this.http.post<T>(
         this.url + '/api/portfolio/holding-transactions/delete',
         { id }
      );
   }

   // async deleteTransaction(id: string) {
   //    return await lastValueFrom(
   //       this.http.post<any>(
   //          this.url + '/api/portfolio/holding-transactions/delete',
   //          {
   //             id,
   //          }
   //       )
   //    );
   // }

   openCreateManualPortfolioPopup(modalType: 'create' | 'edit' = 'create') {
      this.createManualPortfolioRef = this.modalService.open(
         CreateManualPortfolioPopupComponent,
         {
            centered: true,
            scrollable: true,
            backdropClass: 'global-modal-backdrop',
            backdrop: 'static',
            windowClass: 'global-modal',
         }
      );
   }

   closeCreateManualPortfolioPopup() {
      if (this.createManualPortfolioRef) {
         this.createManualPortfolioRef.close();
      }
   }

   createManualPortfolio<T>(name: string, currency: string): Observable<T> {
      return this.http.post<T>(this.url + '/api/portfolio/manual', {
         name,
         currency,
      });
   }
}

// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';

// @Injectable({
//    providedIn: 'root'
// })

// export class CountryService {
//    private selectedCountrySubject = new BehaviorSubject<string>('US');
//    selectedCountry$ = this.selectedCountrySubject.asObservable();

//    constructor() {}

//    setSelectedCountry(country: string): void {
//       this.selectedCountrySubject.next(country);
//    }
// }
