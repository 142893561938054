<!-- <div class="modal-header border-0 px-0 pt-0 pb-2">
   <h4 class="modal-title"></h4>
   <button *ngIf="status !== 'SUCCESS'" type="button"
      class="btn d-flex align-items-center justify-content-center p-2 ms-auto shadow-none rounded-circle bg-white"
      aria-label="Close" (click)="closeModal()">
      <svg class="lh-1" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 384 512">
         <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
      </svg>
   </button>
</div> -->
<div class="modal-body p-3 bg-white position-relative">
   <iframe *ngIf="status === 'PENDING'" class="iframe-elm" [src]="iframeUrl | safe" height="100%"
      frameborder="0"></iframe>
   <div *ngIf="status === 'SUCCESS'"
      class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center">
      <div *ngIf="pleaseWaitBtn"
         class="d-flex flex-column justify-content-center align-items-center fw-bold x-large text-success">
         <div>
            Please wait...
         </div>
         <div class="">
            {{ countdownTimer }} {{ countdownTimer > 1 ? "seconds" : "second" }}
         </div>
      </div>
      <!-- <div class="bg-white shadow rounded-3 p-4 position-absolute top-50 start-50 translate-middle status-cards d-flex flex-column justify-content-center align-items-center" style="min-width: 17.5rem;"> -->
      <div *ngIf="!pleaseWaitBtn"
         class="p-4 text-center position-absolute top-50 start-50 translate-middle status-cards d-flex flex-column justify-content-center align-items-center">
         <svg class="checkmark success mb-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark_circle_success" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke-linecap="round" />
         </svg>
         <p class="text-success fw-bold mt-2 mt-lg-3 mb-0">
            Your account has been linked successfully!
         </p>
         <div class="btns-wrapper d-flex align-items-center justify-content-center flex-wrap gap-2 gap-lg-3 my-3">
            <button *ngIf="!pleaseWaitBtn && module==='portfolio'" class="btn px-4 btn btn-success"
               (click)="goToPortfolio()">
               {{isRefetchAccountLoader?'Plese wait...':'View Portfolio'}}

            </button>
            <!-- <p class="fs-4 text-bold">Your account has been linked successfully</p> -->
            <!-- <button *ngIf="pleaseWaitBtn" class="btn px-4 btn"> -->
            <button *ngIf="!pleaseWaitBtn && module==='trade'" class="btn px-4 btn btn-success"
               (click)="startTrading()">
               {{isRefetchAccountLoader?'Plese wait...':'Start Trading'}}
            </button>
            <div *ngIf="pleaseWaitBtn" class="d-flex flex-column">
               Please Wait...
               {{countdownTimer}} {{countdownTimer > 1 ? 'seconds' : 'second'}}
            </div>
            <!-- Please Wait... -->
            <!-- </button> -->
         </div>
      </div>
      <!-- </div> -->
   </div>
   <div *ngIf="status === 'ERROR'" class="position-absolute top-0 start-0 end-0 bottom-0">
      <!-- <div class="bg-white shadow rounded-3 p-4 position-absolute top-50 start-50 translate-middle status-cards d-flex flex-column justify-content-center align-items-center" style="min-width: 17.5rem;"> -->
      <div
         class="p-4 position-absolute top-50 start-50 translate-middle w-100 status-cards d-flex flex-column justify-content-center align-items-center">
         <svg class="checkmark error mb-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark_circle_error" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark_check" stroke-linecap="round" fill="none" d="M16 16 36 36 M36 16 16 36" />
         </svg>
         <h3 class="text-black text-center fw-600 mt-2 mt-lg-3">
            Failed to link your account
         </h3>
         <p class="text-center">
            Account linking was either cancelled or failed during the process.
         </p>
         <button class="btn shadow-none rounded-3 px-4 py-1 mt-4 fw-600 border-2 btn-outline-secondary" (click)="closeModal()">
            Close
         </button>

      </div>
      <!-- </div> -->
   </div>
   <!-- <div *ngIf="status === 'ERROR'" class="position-absolute top-0 start-0 end-0 bottom-0">
      <div class="bg-white shadow rounded-3 p-4 position-absolute top-50 start-50 translate-middle status-cards d-flex flex-column justify-content-center align-items-center" style="min-width: 17.5rem;">
         <svg class="checkmark error mb-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark_circle_error" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark_check" stroke-linecap="round" fill="none" d="M16 16 36 36 M36 16 16 36"/>
         </svg>
         <p class="text-danger fw-bold my-2 my-lg-3">Something went wrong.</p>
       </div>
   </div> -->
</div>
<!-- <div class="modal-footer">
   <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Close</button>
</div> -->