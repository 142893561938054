import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disclaimer-popup',
  templateUrl: './disclaimer-popup.component.html',
  styleUrls: ['./disclaimer-popup.component.scss']
})
export class DisclaimerPopupComponent implements OnInit {
   isAccepted: boolean = false;

   constructor(
      public activeDisclaimerPopup: NgbActiveModal,
   ) { }

   ngOnInit(): void {
   }

   closeDisclaimerPopup() {
      localStorage.setItem('disclaimerAccepted', 'true');
      this.activeDisclaimerPopup.close();
   }

}
