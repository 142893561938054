import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-portfolio-limit-message-popup',
  templateUrl: './portfolio-limit-message-popup.component.html',
  styleUrls: ['./portfolio-limit-message-popup.component.scss']
})
export class PortfolioLimitMessagePopupComponent implements OnInit {
   @Input() portfolioType!: 'broker' | 'manual';

   constructor(
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private router: Router,
   ) { }

   ngOnInit(): void {
   }

   closeAllModalsAndNavigate() {
      this.modalService.dismissAll();
      this.router.navigate(['/pricing'])
   }

}
