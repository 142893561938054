import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-webinar-popup',
  templateUrl: './webinar-popup.component.html',
  styleUrls: ['./webinar-popup.component.scss']
})

export class WebinarPopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  goToLink(url: string){
    this.activeModal.dismiss()
    window.open(url, "_blank");
  }

}
