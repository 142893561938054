<div class="modal-header p-0">
   <header class="d-flex align-items-center position-relative justify-content-between">
      <div class="d-grid">
         <h3 class="mb-2 fw-600">Create New Manual Portfolio</h3>
         <p class="mb-0">
            Enter the below details and click on “Continue” to start adding Holdings and Transaction details...
         </p>
      </div>
      <button type="button" class="btn shadow-none p-1 bg-white rounded-3 modal-header-close-btn" aria-label="Close" (click)="createPopupModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7e7e7e" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
   </header>
</div>
<div class="modal-body border-0 p-3 p-lg-4">
   <form class="form-container" [formGroup]="manualPortfolioForm" (ngSubmit)="addManualPortfolio()">
      <div class="form-input-wrapper">
         <label for="selectCurrency" class="form-label mb-2">Select your currency:</label>
         <ng-select class="add-portfolio-currency-select currencies-select" appearance="outline" labelForId="selectCurrency" formControlName="currency">
            <ng-option *ngFor="let currency of currencies" [value]="currency.currency_code">{{currency.label}}</ng-option>
         </ng-select>
      </div>
      <div class="form-input-wrapper mb-0">
         <label for="portfolioName" class="form-label mb-2">Enter a name for your portfolio:</label>
         <div class="d-flex align-items-center form-control-container">
            <span class="d-inline-block lh-1 currency"></span>
            <input type="text" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" id="manualPortfolioName" formControlName="manualPortfolioName">
         </div>
      </div>
      <button type="submit" class="border-0 d-block w-100 rounded-3 text-uppercase text-white shadow-none login-btn gradient-bg-btn" [disabled]="!manualPortfolioForm.valid || manualPortfolioLoader">
         <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="manualPortfolioLoader">
            <span class="visually-hidden">Loading...</span>
         </div>
         <span>{{ manualPortfolioLoader ? 'Saving...' : 'Save & Continue' }}</span>
      </button>
   </form>
</div>
