import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CabinetGuard implements CanActivate {
  constructor(public router: Router, private auth:AuthService, private cookie:CookieService,public authFire: AngularFireAuth) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<any> {

   const originalRequestUrl = state.url;
   if(originalRequestUrl.includes('/cabinet')) {
      this.router.navigate([originalRequestUrl.replace('/cabinet', '')]);
      return false;
   }

    return true;

    // Guard for user is login or not
    let token = this.cookie.get('_authorization');
    if (!token) {
      this.router.navigate(['/authentication/anonymus-login'])
      return false
    }
    return true
  }
}
