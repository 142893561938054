<ng-template #onboardingFormContent let-modal>
   <div class="modal-body settings-modal-body p-lg-4">
      <div
         class="d-flex align-items-center justify-content-between gap-lg-3 px-3"
      >
         <img
            src="/assets/images/musaffa-logo.svg"
            alt="Musaffa Logo"
            class="header-logo img-fluid"
         />

         <button
            class="btn lh-1 p-0 shadow-none"
            (click)="closeonboardingFormModal()"
         >
            <span class="skip me-2">Skip</span>
         </button>
      </div>
      <div class="mx-auto form-container">
         <div class="page mt-5">
            <h5
               class="d-flex mb-0 modal-heading align-items-center justify-content-center"
            >
               Tell us about yourself
            </h5>
            <p
               class="d-flex align-items-center sub-heading fw-light text-center justify-content-center mt-2"
            >
               We'll customise your Musaffa experience based upon your choices
            </p>
            <form [formGroup]="serveForm" (ngSubmit)="onSubmit()">
               <div class="watch-creates mt-5">
                  <label>{{ interested_markets?.question }}</label>
                  <ng-select
                     class="custom-select mt-2"
                     [items]="interested_markets?.options"
                     bindLabel="display_value"
                     bindValue="key"
                     labelForId="state"
                     [multiple]="true"
                     [selectableGroup]="true"
                     [closeOnSelect]="false"
                     clearAllText="Clear"
                     placeholder="Select"
                     formControlName="interested_markets"
                  >
                  </ng-select>
                  <input
                     type="text"
                     class="form-control shadow-none w-100 input-custom-select mt-2"
                     id="exampleFormControlInput1"
                     placeholder="Other (Please specify)"
                     autocomplete="off"
                     formControlName="other_interested_markets"
                  />
                  <label class="margin-top">{{ brokerages?.question }}</label>
                  <ng-select
                     class="custom-select mt-2"
                     [items]="brokerages?.options"
                     bindLabel="display_value"
                     bindValue="key"
                     labelForId="state"
                     [multiple]="true"
                     placeholder="Select"
                     [selectableGroup]="true"
                     [closeOnSelect]="false"
                     clearAllText="Clear"
                     formControlName="brokerages"
                  >
                  </ng-select>
                  <input
                     type="text"
                     class="form-control shadow-none w-100 input-custom-select mt-2"
                     id="exampleFormControlInput1"
                     placeholder="Other (Please specify)"
                     autocomplete="off"
                     formControlName="other_brokerages"
                  />
                  <label class="margin-top">{{
                     user_description?.question
                  }}</label>
                  <ng-select
                     class="custom-select mt-2"
                     [items]="user_description?.options"
                     bindLabel="display_value"
                     bindValue="key"
                     placeholder="Select"
                     [clearable]="false"
                     [searchable]="false"
                     formControlName="user_description"
                  >
                  </ng-select>
                  <label class="margin-top">{{
                     investor_type?.question
                  }}</label>
                  <div
                     *ngFor="let option of investor_type?.options"
                     class="mt-2"
                  >
                     <input
                        class="form-check-input radios"
                        type="radio"
                        [value]="option.key"
                        name="investor_type"
                        [id]="option.key"
                        formControlName="investor_type"
                     />
                     <label class="form-check-label ms-2" [for]="option.key">
                        {{ option.display_value }}
                     </label>
                  </div>
                  <label class="margin-top">{{
                     signup_reason?.question
                  }}</label>
                  <ng-select
                     class="custom-select mt-2"
                     [items]="signup_reason?.options"
                     bindLabel="display_value"
                     bindValue="key"
                     labelForId="state"
                     placeholder="Select"
                     [multiple]="true"
                     [selectableGroup]="true"
                     [closeOnSelect]="false"
                     clearAllText="Clear"
                     formControlName="signup_reason"
                  >
                  </ng-select>
                  <input
                     type="text"
                     class="form-control shadow-none w-100 input-custom-select mt-2"
                     id="exampleFormControlInput1"
                     placeholder="Other (Please specify)"
                     autocomplete="off"
                     formControlName="other_signup_reason"
                  />
                  <label class="margin-top">{{
                     first_point_attribution?.question
                  }}</label>
                  <ng-select
                     class="custom-select mt-2"
                     [items]="first_point_attribution?.options"
                     bindLabel="display_value"
                     bindValue="key"
                     formControlName="first_point_attribution"
                     [clearable]="false"
                     [searchable]="false"
                     placeholder="Select"
                  >
                  </ng-select>
                  <input
                     *ngIf="serveForm.value.first_point_attribution === 'other'"
                     type="text"
                     class="form-control shadow-none w-100 input-custom-select mt-2"
                     id="exampleFormControlInput1"
                     placeholder="Other"
                     autocomplete="off"
                     formControlName="other_first_point_attribution"
                  />
               </div>
               <div class="watchlist-button mx-auto mt-3">
                  <div class="btn-cont">
                     <button
                        [disabled]="
                           !serveForm.valid ||
                           (!serveForm.value.interested_markets &&
                              !serveForm.value.other_interested_markets) ||
                           (!serveForm.value.brokerages &&
                              !serveForm.value.other_brokerages) ||
                           (!serveForm.value.signup_reason &&
                              !serveForm.value.other_signup_reason) ||
                           (!serveForm.value.first_point_attribution &&
                              !serveForm.value.other_first_point_attribution)
                        "
                        type="submit"
                        class="btn cancel"
                     >
                        <div *ngIf="!isLoading">Submit</div>
                        <div *ngIf="isLoading">
                           <div
                              class="spinner-border spinner-border-sm text-white"
                              role="status"
                           >
                              <span class="visually-hidden">Loading...</span>
                           </div>
                        </div>
                     </button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</ng-template>
