import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-prompt',
  templateUrl: './download-prompt.component.html',
  styleUrls: ['./download-prompt.component.scss']
})
export class DownloadPromptComponent implements OnInit {
  isMobile: boolean = false;
  isOpen: boolean = false;
  appStoreLink: string = '';

  constructor(
    private observer: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    // this.appStoreLink = this.getAppStoreLink();
    this.observer.observe('(max-width: 767px)').subscribe(result => {
      this.isMobile = result.matches         
    });
  }

  dismissPrompt() {
    this.isOpen = false;
  }

  openDownloadPrompt() {
    this.isOpen = true;
  }

  // getAppStoreLink(): string {
  //   return 'https://redirect.appmetrica.yandex.com/serve/1109342530991823905'
  // }

  redirectToAppStore() {
    this.dismissPrompt()
    window.location.href = 'https://app.musaffa.com/ar0PDlRM2Jb';
  }

  // getAppStoreLink(): string {
  //   const platform = this.deviceService.os;
  //   if (platform.toLowerCase() === 'android') {
  //     console.log('android');
  //     return 'https://play.google.com/store/apps/details?id=com.axonlogic.stocktrade';
  //   } else if (platform.toLowerCase() === 'ios') {
  //     console.log('ios');
  //     return 'https://apps.apple.com/us/app/musaffa-halal-stocks-etfs/id1614624968';
  //   } else {
  //     console.log('other');
  //     return 'https://screener.musaffa.com';
  //   }
  // }

}
