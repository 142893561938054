<div class="modal-header border-0 flex-column w-100 p-0">

   <div class="brokarge-modal-header border-bottom p-3 p-lg-4">
      <button class="p-1 btn rounded-circle bg-danger shadow-none close-btn" (click)="activeModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#fff" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
      <div>
         <p class="header-span">Connect your</p>
         <p class="header-heading">Broker for portfolio tracking!</p>
      </div>
      <div class="header-search">
         <!-- search box here -->
         <div class="search-main-container">
            <div class="d-flex align-items-center overflow-hidden search-input-container">
               <input class="search-input flex-grow-1" placeholder="Search Broker Here..." autocomplete="off" [(ngModel)]="searchText" (input)="searchBrokerage()" />
               <div class="search-icon-wrapper d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-search search-icon" viewBox="0 0 16 16">
                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
               </div>
            </div>
         </div>
      </div>
   </div>

   <ng-container *ngIf="userNewRole !== 'premium_plus'">
      <div class="portfolio-count-wrapper border-bottom w-100 px-3 px-lg-4 py-2" *ngIf="maxBrokeragePortfoliosCount || maxManualPortfoliosCount">
         <p class="mb-0">
            <span>
               <strong class="fw-600">Portfolio Connections & Limits:</strong> 
               <span *ngIf="maxBrokeragePortfoliosCount">
                  Broker Linked Portfolio - <span class="fw-500 text-black">{{ currentBrokeragePortfoliosCount }}/{{ maxBrokeragePortfoliosCount ?? '0' }}</span>
                  <span class="mx-2 mx-lg-3">|</span>
               </span>  
               <span *ngIf="maxManualPortfoliosCount">
                  Manual Portfolio - <span class="fw-500 text-black">{{ currentManualPortfoliosCount }}/{{ maxManualPortfoliosCount ?? '0' }}</span>
               </span>
            </span>
            <button (click)="navigateToPricingPage()" class="bg-transparent border-0 fw-600 ms-3 d-inline-block align-self-center p-0">Upgrade</button>
         </p>
      </div>
   </ng-container>
  
</div>

<div class="modal-body p-3 p-lg-4 ">
   <ng-container *ngIf="loading">
      <div class="grid-view gap-3">
         <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress"
               [theme]="{ height: '70px', width: '100%', 'border-radius': '4px', margin: '0px' }">
            </ngx-skeleton-loader>
         </div>
      </div>
   </ng-container>
   <ng-container *ngIf="!loading">
      <!-- supported brokerages -->
      <div *ngIf="filteredBrokerages.length" class="grid-view gap-3 mb-4">
         <button class="btn shadow-none d-flex align-items-start single-brokerage" *ngFor="let brokerage of filteredBrokerages" (click)="initiateBrokerage(brokerage)" [disabled]="isConnectLoading">

            <!-- Loader layer -->
            <div *ngIf="isConnectLoading && currBrokerageId === brokerage.brokerage_id" class="single-brokerage_loader">
               <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </div>

            <!-- <div class="d-flex align-items-center justify-content-between gap-4"> -->
            <div class="d-flex align-items-start gap-3">
               <img [src]="brokerage.square_logo" class="d-block bg-light img-fluid" [alt]="brokerage.name" />
               <div>
                  <h6 class="mb-2 line-clamp-1">{{ brokerage.name }}</h6>
                  <div class="features">
                     <div class="d-flex gap-2 align-items-center">
                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M16.875 15H1.125C0.50475 15 0 14.4952 0 13.875V3.375C0 2.75475 0.50475 2.25 1.125 2.25H16.875C17.4953 2.25 18 2.75475 18 3.375V13.875C18 14.4952 17.4953 15 16.875 15ZM1.125 3C0.918 3 0.75 3.168 0.75 3.375V13.875C0.75 14.082 0.918 14.25 1.125 14.25H16.875C17.082 14.25 17.25 14.082 17.25 13.875V3.375C17.25 3.168 17.082 3 16.875 3H1.125Z" fill="#7E7E7E" />
                           <path d="M11.625 3H6.375C6.168 3 6 2.832 6 2.625V1.125C6 0.50475 6.50475 0 7.125 0H10.875C11.4952 0 12 0.50475 12 1.125V2.625C12 2.832 11.832 3 11.625 3ZM6.75 2.25H11.25V1.125C11.25 0.918 11.082 0.75 10.875 0.75H7.125C6.918 0.75 6.75 0.918 6.75 1.125V2.25Z" fill="#7E7E7E" />
                           <path d="M17.625 7.5H0.375C0.168 7.5 0 7.332 0 7.125C0 6.918 0.168 6.75 0.375 6.75H17.625C17.832 6.75 18 6.918 18 7.125C18 7.332 17.832 7.5 17.625 7.5Z" fill="#7E7E7E" />
                           <path d="M5.625 9.75H3.375C3.168 9.75 3 9.582 3 9.375V7.125C3 6.918 3.168 6.75 3.375 6.75C3.582 6.75 3.75 6.918 3.75 7.125V9H5.25V7.125C5.25 6.918 5.418 6.75 5.625 6.75C5.832 6.75 6 6.918 6 7.125V9.375C6 9.582 5.832 9.75 5.625 9.75Z" fill="#7E7E7E" />
                           <path d="M14.625 9.75H12.375C12.168 9.75 12 9.582 12 9.375V7.125C12 6.918 12.168 6.75 12.375 6.75C12.582 6.75 12.75 6.918 12.75 7.125V9H14.25V7.125C14.25 6.918 14.418 6.75 14.625 6.75C14.832 6.75 15 6.918 15 7.125V9.375C15 9.582 14.832 9.75 14.625 9.75Z" fill="#7E7E7E" />
                        </svg>
                        <span>Portfolio Tracking</span>
                     </div>
                     <div *ngIf="brokerage.supported_features.is_trading_allowed"
                        class="d-flex gap-1 align-items-center">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M14.75 13.6875H2.75C2.23325 13.6875 1.8125 13.2668 1.8125 12.75V0.75C1.8125 0.4395 1.5605 0.1875 1.25 0.1875C0.9395 0.1875 0.6875 0.4395 0.6875 0.75V12.75C0.6875 13.8877 1.61225 14.8125 2.75 14.8125H14.75C15.0605 14.8125 15.3125 14.5605 15.3125 14.25C15.3125 13.9395 15.0605 13.6875 14.75 13.6875Z" fill="#7E7E7E" />
                           <path d="M15.0875 3.3C14.8393 3.11325 14.4867 3.16425 14.3 3.41175L12.3695 5.9865L10.562 4.782C10.4345 4.6965 10.277 4.668 10.13 4.701C9.98075 4.73325 9.851 4.8255 9.77075 4.95525L7.81775 8.127L6.04775 7.02225C5.80025 6.86925 5.47475 6.9285 5.3 7.1625L3.05 10.1625C2.86325 10.4115 2.91425 10.764 3.1625 10.95C3.26375 11.0265 3.38225 11.0625 3.5 11.0625C3.671 11.0625 3.83975 10.9845 3.95075 10.8375L5.891 8.25075L7.703 9.381C7.82975 9.459 7.9835 9.48375 8.129 9.4515C8.2745 9.417 8.4005 9.32625 8.47925 9.19875L10.4233 6.042L12.188 7.218C12.4347 7.38225 12.77 7.32675 12.95 7.0875L15.2 4.0875C15.3868 3.8385 15.3358 3.486 15.0875 3.3Z" fill="#7E7E7E" />
                        </svg>
                        <span>Trading</span>
                     </div>
                     <div *ngIf="brokerage.supported_features.automated_purification"
                        class="d-flex gap-1 align-items-center">
                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12.5 3.9375H3.5C3.35081 3.9375 3.20774 3.99679 3.10225 4.10226C2.99677 4.20773 2.9375 4.35083 2.9375 4.5C2.9375 4.64917 2.99677 4.79227 3.10225 4.89774C3.20774 5.00321 3.35081 5.0625 3.5 5.0625H12.5C12.6492 5.0625 12.7923 5.00321 12.8977 4.89774C13.0032 4.79227 13.0625 4.64917 13.0625 4.5C13.0625 4.35083 13.0032 4.20773 12.8977 4.10226C12.7923 3.99679 12.6492 3.9375 12.5 3.9375Z" fill="#7E7E7E" />
                           <path d="M15.3125 0.5625H0.6875C0.538314 0.5625 0.395242 0.621765 0.289751 0.72725C0.184265 0.832742 0.125 0.975814 0.125 1.125C0.125 1.27417 0.184265 1.41727 0.289751 1.52274C0.395242 1.62821 0.538314 1.6875 0.6875 1.6875H15.3125C15.4617 1.6875 15.6048 1.62821 15.7102 1.52274C15.8157 1.41727 15.875 1.27417 15.875 1.125C15.875 0.975814 15.8157 0.832742 15.7102 0.72725C15.6048 0.621765 15.4617 0.5625 15.3125 0.5625Z" fill="#7E7E7E" />
                           <path d="M9.6875 7.3125H6.3125C6.16333 7.3125 6.02023 7.37179 5.91476 7.47726C5.80929 7.58273 5.75 7.72583 5.75 7.875C5.75 8.02417 5.80929 8.16727 5.91476 8.27274C6.02023 8.37821 6.16333 8.4375 6.3125 8.4375H9.6875C9.83668 8.4375 9.97977 8.37821 10.0852 8.27274C10.1907 8.16727 10.25 8.02417 10.25 7.875C10.25 7.72583 10.1907 7.58273 10.0852 7.47726C9.97977 7.37179 9.83668 7.3125 9.6875 7.3125Z" fill="#7E7E7E" />
                        </svg>
                        <span>Automated Purification</span>
                     </div>
                  </div>
               </div>
            </div>
         </button>
      </div>
      <app-add-manual-card 
         [portfolioAccounts]="portfolioAccounts" 
         [portfolioType]="'manual'"
         [isInsideBrokeragesPopup]="true">
      </app-add-manual-card>
      <!-- <div *ngIf="!filteredBrokerages.length">
         <p class="no-data-text my-3">No supported brokerage found.</p>
      </div> -->
   </ng-container>
</div>

