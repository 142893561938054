import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-holding-details-popup',
   templateUrl: './holding-details-popup.component.html',
   styleUrls: ['./holding-details-popup.component.scss'],
})
export class HoldingDetailsPopupComponent implements OnInit {
   @Input() holding: any;
   isQuestionCollapsed = true;
   nonPurifiableText: string = '';

   constructor(
      private toaster: ToastrService,
      public activeModal: NgbActiveModal,
      private router: Router,
      private analyticsUtils: AnalyticsUtils,
      private remoteConfig: FirebaseRemoteConfigService
   ) {}

   ngOnInit(): void {
      this.getNonPurifiableText();
   }

   goToDetails() {
      if (this.holding.symbol) {
         this.analyticsUtils.portfolio_holding_item_view_details();
         this.activeModal.dismiss();
         this.router.navigate(['/stock/' + this.holding.symbol]);
      } else {
         this.toaster.error(
            'Symbol not found within Musaffa. We have forwarded it to our analyst team to map it with your broker data.'
         );
      }
   }

   getPercentChangeClasses(holding: any) {
      return {
         green: holding.percentage_change >= 0,
         red: holding.percentage_change < 0,
      };
   }

   getNonPurifiableText() {
      this.remoteConfig.getPurificationDefaults().then((data) => {
         this.nonPurifiableText = data.not_purifiable_info;
      });
   }
}
