<section class="mx-auto position-relative">
   <article
      class="d-flex align-items-center count-wrapper px-3 px-md-0 mb-3 gap-2"
      *ngIf="shouldShowCountAndActiveFilters"
   >
      <p class="my-0 count-para">
         {{ dataCount }} matching {{ type === "etf" ? "ETFs" : "stocks" }}
      </p>
      <p class="my-0 active-filters">
         | &nbsp;Active Filters
         <span class="text-white fw-500 rounded">{{ activeFiltersCount }}</span>
      </p>

      <button
         type="button"
         class="text-nowrap btn_reset my-0 ms-2"
         *ngIf="showResetButton && activeFiltersCount > 0"
         (click)="resetAllFilters()"
      >
         Reset All
      </button>
   </article>
   <div *ngIf="!shouldShowCountAndActiveFilters" class="my-1"></div>

   <ng-container *ngIf="isDataLoading">
      <ngx-skeleton-loader
         count="15"
         appearance="line"
         animation="progress"
         [theme]="{ height: '40px', width: '100%', margin: '0px 0px 8px 0px' }"
      >
      </ngx-skeleton-loader>
   </ng-container>
   <!--  (scroll)="onTableScroll($event)" -->
   <ng-container *ngIf="!isDataLoading">
      <div
         #tableContainer
         class="dynamic-table-wrapper custom-scroll-bar table-responsive table__filter__responsive"
         role="region"
         tabindex="0"
         (scroll)="onTableScroll($event)"
      >
         <table>
            <thead>
               <th scope="col" class="star-th"></th>
               <th scope="col" class="name-th fw-500">Name</th>
               <th
                  scope="col"
                  class="text-capitalize fw-500"
                  *ngFor="let column of columns"
               >
                  <div
                     (click)="sortTable(column.key)"
                     class="d-flex align-items-center"
                     [ngClass]="{
                        'justify-content-end':
                           column.type &&
                           [
                              'amount',
                              'percent',
                              'marketcap',
                              'number',
                              'million',
                              'aum'
                           ].includes(column.type),
                        'justify-content-center':
                           this.complianceFields.includes(column.key),
                        'cursor-pointer': isSortable(column.key)
                     }"
                  >
                     {{ column.header }}

                     <div *ngIf="isSortingEnabled && isSortable(column.key)">
                        &nbsp;
                        <i class="icon-sort" *ngIf="!isSorted(column.key)"></i>
                        <i
                           class="bi bi-sort-down-alt fs-6 fw-600"
                           *ngIf="isSorted(column.key) && isAscending"
                        ></i>
                        <i
                           class="bi bi-sort-up fs-6 fw-600"
                           *ngIf="isSorted(column.key) && !isAscending"
                        ></i>
                     </div>
                     <!-- <div *ngIf="!isSortingEnabled && showSortingArrows">
                        &nbsp;
                        <i
                           class="icon-sort"
                           (click)="sortTable(column.key)"
                        ></i>
                     </div> -->

                     <!-- <i class="icon-filter" (click)="toggleFilterDropdown(column.key)"></i>
                     <div *ngIf="isFilterOpen(column.key)" class="filter-dropdown">
                     </div> -->
                  </div>
               </th>
               <!-- <th class="text-capitalize fw-500">Price</th>
               <th class="text-center text-capitalize fw-500">
                  52 Week Low & High
                  <i class="icon-filter" (click)="toggleFilterDropdown('weekLowHigh')"></i>
                  <div *ngIf="isFilterOpen('weekLowHigh')" class="filter-dropdown">

                  </div>
               </th>
               <th class="text-center text-capitalize fw-500" (click)="sortTable('analystRating')">
                  Analyst Rating
                  <i class="icon-sort" *ngIf="!isSorted('dividendYield')"></i>
                  <i class="icon-sort-up" *ngIf="isSorted('dividendYield') && isAscending"></i>
                  <i class="icon-sort-down" *ngIf="isSorted('dividendYield') && !isAscending"></i>
               </th> -->
            </thead>
            <tbody *ngIf="data && data.length > 0; else noData">
               <tr *ngFor="let row of data; trackBy: trackByFn">
                  <td class="star-td p-0">
                     <button
                        class="btn p-0 shadow-none rounded-0 text-center w-100 delete-btn"
                        *ngIf="isUsedInWatchlist; else showWatchlistButton"
                        (click)="onDelete(row)"
                     >
                        <svg
                           class="mx-auto"
                           viewBox="0 0 16 17"
                           fill="#1bad4e"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M15.653 7.78235C15.9679 7.47542 16.0791 7.02496 15.9432 6.60621C15.8071 6.18747 15.4524 5.88855 15.0167 5.82512L11.1426 5.2622C10.9776 5.23817 10.835 5.13469 10.7613 4.98507L9.02933 1.47494C8.83486 1.08054 8.44014 0.835449 8.00025 0.835449C7.56069 0.835449 7.16597 1.08054 6.9715 1.47494L5.23918 4.98539C5.16549 5.13501 5.0226 5.23849 4.8576 5.26252L0.983514 5.82544C0.548111 5.88855 0.193125 6.18779 0.056962 6.60653C-0.0788811 7.02528 0.0322923 7.47574 0.34723 7.78267L3.15027 10.5149C3.26978 10.6315 3.32456 10.7994 3.29637 10.9634L2.6351 14.8215C2.57647 15.1611 2.66553 15.4914 2.88532 15.7519C3.22685 16.1578 3.82308 16.2815 4.29981 16.031L7.76445 14.2093C7.90926 14.1333 8.09156 14.134 8.23606 14.2093L11.701 16.031C11.8695 16.1197 12.0493 16.1646 12.2348 16.1646C12.5734 16.1646 12.8944 16.014 13.1152 15.7519C13.3353 15.4914 13.424 15.1605 13.3654 14.8215L12.7038 10.9634C12.6756 10.7991 12.7304 10.6315 12.8499 10.5149L15.653 7.78235Z"
                              fill="#ECBE1C"
                           />
                        </svg>
                     </button>
                     <ng-template #showWatchlistButton>
                        <app-watchlist-button
                           [stock]="row"
                           *ngIf="!row.locked"
                        ></app-watchlist-button>
                        <div
                           class="star-locked-cell d-flex align-items-stretch justify-items-stretch position-relative"
                           *ngIf="row.locked"
                        >
                           <span
                              class="d-flex align-items-stretch justify-items-stretch"
                           ></span>
                        </div>
                     </ng-template>
                  </td>
                  <td class="name-td bg-white p-0 align-middle">
                     <a
                        class="company-info ps-0"
                        [routerLink]="['/stock', row[getSymbolField(row)]]"
                        *ngIf="getRowType(row) === 'stock' && !row.locked"
                     >
                        <div
                           class="stock-image-wrapper flex-shrink-0 bg-light rounded-circle overflow-hidden"
                        >
                           <img
                              *ngIf="row['logo']"
                              [src]="row['logo']"
                              alt=""
                              class="img-fluid"
                           />
                           <div
                              class="d-flex h-100 text-black align-items-center justify-content-center fs-6 fw-600"
                           >
                              {{ row[getSymbolField(row)]?.charAt(0) }}
                              <!-- {{ row["companyName"]?.charAt(0) }} -->
                           </div>
                        </div>
                        <div
                           class="fw-600 block rounded-3 text-center stock-ticker-chip"
                           [ngbTooltip]="
                              row[getSymbolField(row)] +
                              ' - ' +
                              (getName(row) ?? '')
                           "
                           [triggers]="'hover focus'"
                           [container]="'body'"
                           tooltipClass="my-custom-tooltip"
                           placement="top"
                        >
                           <span class="line-clamp-1">
                              {{ row[getSymbolField(row)] }}
                           </span>
                        </div>
                        <div class="d-none d-lg-block stock-name">
                           <h6
                              class="mb-0 line-clamp-1"
                              [ngbTooltip]="
                                 getName(row)?.length >= 18 ? getName(row) : ''
                              "
                              [triggers]="'hover focus'"
                              [container]="'body'"
                              tooltipClass="my-custom-tooltip"
                              placement="top"
                           >
                              {{ getName(row) }}
                           </h6>
                        </div>
                     </a>

                     <div
                        class="position-relative px-0 d-flex cursor-pointer align-items-center locked-cell"
                        *ngIf="row.locked"
                        (click)="accessControlService.accessDenied()"
                     >
                        <span
                           class="d-flex align-items-center justify-content-center fw-400"
                        >
                           <i class="icon-lock me-1"></i> Unlock
                        </span>
                     </div>

                     <a
                        *ngIf="getRowType(row) === 'etf' && !row.locked"
                        class="company-info ps-0"
                        [routerLink]="'/etf/' + row.symbol"
                     >
                        <!-- ETF Logo or Initial -->
                        <div
                           class="stock-image-wrapper flex-shrink-0 bg-light rounded-circle overflow-hidden"
                        >
                           <div
                              *ngIf="!row['logo']"
                              class="d-flex h-100 text-black align-items-center justify-content-center fs-6 fw-600"
                           >
                              {{ row[getSymbolField(row)]?.charAt(0) }}
                           </div>
                        </div>

                        <!-- ETF Symbol -->
                        <div
                           class="fw-600 block rounded-3 text-center stock-ticker-chip"
                           [ngbTooltip]="
                              row[getSymbolField(row)] + ' - ' + getName(row)
                           "
                           [triggers]="'hover focus'"
                           [container]="'body'"
                           tooltipClass="my-custom-tooltip"
                           placement="top"
                        >
                           <span class="line-clamp-1">{{
                              row[getSymbolField(row)]
                           }}</span>
                        </div>

                        <!-- ETF Name -->
                        <div class="d-none d-lg-block stock-name">
                           <h6
                              class="mb-0 line-clamp-1"
                              [ngbTooltip]="
                                 getName(row)?.length >= 18 ? getName(row) : ''
                              "
                              [triggers]="'hover focus'"
                              [container]="'body'"
                              tooltipClass="my-custom-tooltip"
                              placement="top"
                           >
                              {{ getName(row) }}
                           </h6>
                        </div>
                     </a>
                  </td>

                  <!-- Dynamic column data -->
                  <ng-container *ngFor="let column of columns">
                     <td
                        class="halal-ratings-td"
                        *ngIf="
                           this.rankingFields.includes(column.key) ||
                              this.complianceFields.includes(column.key);
                           else otherColumns
                        "
                     >
                        <a
                           *ngIf="this.rankingFields.includes(column.key)"
                           class="d-flex align-items-center justify-content-center"
                        >
                           <app-compliance-chip
                              *ngIf="
                                 showCompliance ||
                                    (accessibleComplianceSymbols.includes(
                                       row[getSymbolField(row)]
                                    ) &&
                                       row['ranking_v2'] > 0);
                                 else noStars
                              "
                              [complianceRankingV2]="row['ranking_v2']"
                              [showCompliance]="showCompliance"
                              [symbol]="row[getSymbolField(row)]"
                              [complianceStatus]="row[getComplianceField(row)]"
                              [isAccessibleComplianceSymbol]="
                                 accessibleComplianceSymbols.includes(
                                    row[getSymbolField(row)]
                                 )
                              "
                              [onlyRanking]="true"
                              [preloaded]="true"
                           ></app-compliance-chip>

                           <ng-template #noStars>
                              <span
                                 class="text-center"
                                 *ngIf="
                                    showComplianceRanking ||
                                       accessibleComplianceSymbols.includes(
                                          row[getSymbolField(row)]
                                       );
                                    else lockCompliance
                                 "
                                 >-</span
                              >
                              <ng-template #lockCompliance>
                                 <span
                                    class="text-center"
                                    *ngIf="
                                       !showComplianceRanking ||
                                       (!allowHalalRating &&
                                          !accessibleComplianceSymbols.includes(
                                             row[getSymbolField(row)]
                                          ))
                                    "
                                 >
                                    <div
                                       class="ranking-locked-wrapper d-flex align-items-center justify-content-center py-1 cursor-pointer"
                                       placement="top"
                                       (click)="
                                          accessControlService.accessDenied()
                                       "
                                    >
                                       <i class="icon-lock me-1"></i>
                                       <span>Unlock</span>
                                    </div>
                                 </span>
                              </ng-template>
                           </ng-template>
                        </a>
                        <div
                           class="ps-3 ps-lg-0 d-flex justify-content-center"
                           *ngIf="this.complianceFields.includes(column.key)"
                        >
                           <app-compliance-chip
                              *ngIf="
                                 showCompliance ||
                                    accessibleComplianceSymbols.includes(
                                       row[getSymbolField(row)]
                                    );
                                 else hideCompliance
                              "
                              [symbol]="row[getSymbolField(row)]"
                              [isAccessibleComplianceSymbol]="
                                 accessibleComplianceSymbols.includes(
                                    row[getSymbolField(row)]
                                 )
                              "
                              [complianceStatus]="row[getComplianceField(row)]"
                              [complianceRanking]="row['ranking_v2']"
                              [onlyCompliance]="true"
                              [preloaded]="true"
                           ></app-compliance-chip>

                           <ng-template #hideCompliance>
                              <app-compliance-chip
                                 [symbol]="''"
                                 [complianceStatus]="'LOCKED'"
                                 [complianceRanking]="0"
                                 [onlyCompliance]="true"
                                 [preloaded]="true"
                              >
                              </app-compliance-chip>
                           </ng-template>
                        </div>
                        <div
                           class="ps-3 d-flex justify-content-center"
                           *ngIf="column.key === 'shariahStates'"
                        >
                           <app-compliance-chip
                              *ngIf="
                                 showCompliance ||
                                    (!isLoggedIn &&
                                       accessibleComplianceSymbols.includes(
                                          row['symbol']
                                       )) ||
                                       isLoggedIn;
                                 else hideCompliance
                              "
                              [symbol]="row['symbol']"
                              [isAccessibleComplianceSymbol]="
                                 accessibleComplianceSymbols.includes(
                                    row['symbol']
                                 )
                              "
                              [complianceStatus]="row['shariahStates']"
                              [complianceRanking]="row['ranking']"
                              [onlyCompliance]="true"
                              [preloaded]="true"
                           ></app-compliance-chip>
                           <ng-template #hideCompliance>
                              <app-compliance-chip
                                 [symbol]="''"
                                 [complianceStatus]="'LOCKED'"
                                 [complianceRanking]="0"
                                 [onlyCompliance]="true"
                                 [preloaded]="true"
                              >
                              </app-compliance-chip>
                           </ng-template>
                        </div>
                     </td>

                     <ng-template #otherColumns>
                        <td
                           *ngIf="
                              row[column.key] !== null &&
                                 row[column.key] !== undefined;
                              else emptyCell
                           "
                        >
                           <a
                              class="d-flex align-items-center"
                              [ngClass]="{
                                 'justify-content-end':
                                    column.type &&
                                    [
                                       'amount',
                                       'percent',
                                       'marketcap',
                                       'number',
                                       'million',
                                       'aum',
                                       'lastp',
                                    ].includes(column.type)
                              }"
                           >
                              <!-- Condition to show lock icon when column is 'Halal Rating' and status is 'LOCKED' -->
                              <span
                                 class="text-nowrap text-field-cstm text-truncate ranking-locked-wrapper"
                                 *ngIf="
                                    column.header === 'Halal Rating' &&
                                       row['complianceStatus'] === 'LOCKED';
                                    else showRankingValue
                                 "
                              >
                                 <i class="icon-lock"></i>
                              </span>

                              <!-- Normal ranking display -->
                              <ng-template #showRankingValue>
                                 <span
                                    class="text-nowrap text-field-cstm text-truncate"
                                    [ngClass]="
                                       getCellClass(
                                          column.key,
                                          row[column.key],
                                          column.showPositiveNegative
                                       )
                                    "
                                    *ngIf="!column.type"
                                    [ngbTooltip]="
                                       row[column.key].length >= 18
                                          ? row[column.key]
                                          : ''
                                    "
                                    [triggers]="'hover focus'"
                                    [container]="'body'"
                                    tooltipClass="my-custom-tooltip"
                                    placement="top"
                                 >
                                    {{
                                       column.filterResultFormatFn
                                          ? column.filterResultFormatFn(
                                               row[column.key]
                                            )
                                          : column.format
                                          ? column.format(row[column.key])
                                          : (getFormattedValues(
                                               column.key,
                                               row[column.key],
                                               column.type
                                            ) | rankingGrade)
                                    }}
                                 </span>
                                 <span
                                    class="text-nowrap"
                                    [ngClass]="
                                       getCellClass(
                                          column.key,
                                          row[column.key],
                                          column.showPositiveNegative
                                       )
                                    "
                                    *ngIf="
                                       column.type === 'percent' ||
                                       column.type === 'number'
                                    "
                                 >
                                    <i
                                       *ngIf="column.showPositiveNegative"
                                       class="{{
                                          getPositiveNegativeArrow(
                                             row[column.key]
                                          )
                                       }}"
                                    ></i>
                                    <app-less-than-tooltip
                                       [amount]="row[column.key]"
                                       [currency]="' '"
                                       [tooltipPosition]="'left'"
                                       [amountStyle]="{
                                          color: column.showPositiveNegative
                                             ? row[column.key] < 0
                                                ? '#E82127'
                                                : '#009000'
                                             : '#000'
                                       }"
                                       [tooltipContent]="row[column.key]"
                                    >
                                    </app-less-than-tooltip>
                                    {{ column.type === "percent" ? "%" : "" }}
                                 </span>
                                 <span
                                    class="text-nowrap"
                                    [ngClass]="
                                       getCellClass(
                                          column.key,
                                          row[column.key],
                                          column.showPositiveNegative
                                       )
                                    "
                                    *ngIf="column.type === 'amount'"
                                 >
                                    <i
                                       *ngIf="column.showPositiveNegative"
                                       class="{{
                                          getPositiveNegativeArrow(
                                             row[column.key]
                                          )
                                       }}"
                                    ></i>
                                    <app-less-than-tooltip
                                       [amount]="row[column.key]"
                                       [currency]="
                                          row['currency'] ?? row['navCurrency']
                                       "
                                       [tooltipPosition]="'left'"
                                       [amountStyle]="{
                                          color: column.showPositiveNegative
                                             ? row[column.key] < 0
                                                ? '#E82127'
                                                : '#009000'
                                             : '#000'
                                       }"
                                       [tooltipContent]="row[column.key]"
                                    >
                                    </app-less-than-tooltip>
                                 </span>
                                 <span
                                    class="text-nowrap"
                                    *ngIf="column.type === 'million'"
                                 >
                                    {{
                                       getFormattedValues(
                                          column.key,
                                          row[column.key]
                                       ) | millionPipe : false
                                    }}
                                 </span>
                                 <span
                                    class="text-nowrap"
                                    *ngIf="column.type === 'country'"
                                 >
                                    {{ getCountryName(row[column.key]) }}
                                 </span>
                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'musaffaSector'"
                                 >
                                 {{ row[column.key] }}
                                 </span>

                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'musaffaIndustry'"
                                 >
                                 {{ row[column.key] }}
                                 </span>
                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'analyst_recommendation_weighted_avg'"
                                 >
                                 {{ row[column.key] }}
                                 </span>
                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'assetClass'"
                                 >
                                 {{ row[column.key] }}
                                 </span>
                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'investmentSegment'"
                                 >
                                 {{ row[column.key] }}
                                 </span>
                                 <span
                                  class="text-nowrap"
                                  *ngIf="column.type === 'marketcap'"
                                 >
                                 {{ row[column.key] }}
                                 </span>


                              </ng-template>
                           </a>
                        </td>
                     </ng-template>

                     <ng-template #emptyCell>
                        <td
                           class="px-3"
                           [ngClass]="
                              column.type &&
                              [
                                 'amount',
                                 'percent',
                                 'marketcap',
                                 'number',
                                 'million',
                                 'country'
                              ].includes(column.type)
                                 ? 'text-end'
                                 : 'text-center'
                           "
                        >
                           <span> - </span>
                        </td>
                     </ng-template>
                  </ng-container>
                  <!-- <td>
                     <a class="d-flex align-items-center justify-content-center" style="gap: 20px;">
                        <span class="down_price"><i class="icon-caret-down"></i>-0.44%</span>
                        <span class="up_price"><i class="icon-caret-up"></i>1.55%</span>
                     </a>
                  </td> -->

                  <!-- <td style="position: relative;">
                     <div class="week_low_high_row" placement="top" ngbTooltip="Current Price">
                        <input type="range" value="3" min="-45" max="50" id="slider" disabled>
                        <span id="output"></span>
                        <div class="range_track">
                           <div class="week_low_high_light">$124.12</div>
                           <div class="week_low_high_dark">$180.29</div>
                           <div class="week_low_high_light">$264.12</div>
                        </div>
                     </div>
                  </td>

                  <td style="position: relative;">
                     <ng-template #popContent>
                        <div class="popover_body">
                           <h5>ANALYST RECOMMENDATION (250)</h5>
                           <ul class="popover_analyst_list">
                              <li class="popover_analyst_list_green">
                                 <span class="popover_analyst_type">Buy</span>
                                 <span class="popover_analyst_percent">61%</span>
                              </li>
                              <li class="popover_analyst_list_orange">
                                 <span class="popover_analyst_type">Hold</span>
                                 <span class="popover_analyst_percent">23%</span>
                              </li>
                              <li class="popover_analyst_list_red">
                                 <span class="popover_analyst_type">Sell</span>
                                 <span class="popover_analyst_percent">2%</span>
                              </li>
                           </ul>

                           <div class="powered_by_text">
                              <p>Powered by</p>
                              <img src="/assets/images/img-finhub.svg" />
                           </div>

                        </div>
                     </ng-template>

                     <div class="rating_range_row" [ngbPopover]="popContent" container="body"
                        triggers="mouseenter:mouseleave">
                        <div class="rating_range_label">
                           85.0%
                           <span class="rating_label_type strong-buy">
                              Strong Buy
                           </span>
                        </div>
                        <div class="rating_range_slider">
                           <input type="range" min="40" max="100" class="rating_slider_green" [(ngModel)]="greenValue"
                              [style.width.%]="greenMinWidth" />
                           <input type="range" min="30" max="100" class="rating_slider_orange" [(ngModel)]="orangeValue"
                              [style.width.%]="orangeMinWidth" />
                           <input type="range" min="30" max="100" class="rating_slider_red" [(ngModel)]="redValue"
                              [style.width.%]="redMinWidth" />
                        </div>


                     </div>
                  </td> -->
               </tr>

               <!-- <tr class="position-relative tbody-overlay" *ngIf="!hasAccess">
                  <td colspan="100%" class="p-0 align-middle">
                     <button class="btn position-sticky shadow-none text-black rounded-3 d-flex align-items-center gap-2 lock-card-btn">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g stroke-width="0"/>
                           <g stroke-linecap="round" stroke-linejoin="round"/>
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16s0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16s0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004Q16.676 9.999 16 10H8q-.677-.001-1.25.004zM12 13.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75" fill="currentColor"/>
                        </svg>
                        <span class="fw-500">Sign-up & Upgrade to Unlock!</span>
                     </button>

                     <button class="btn position-sticky shadow-none text-black rounded-3 d-flex align-items-center gap-2 lock-card-btn">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g stroke-width="0"/>
                           <g stroke-linecap="round" stroke-linejoin="round"/>
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16s0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16s0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004Q16.676 9.999 16 10H8q-.677-.001-1.25.004zM12 13.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75" fill="currentColor"/>
                        </svg>
                        <span class="fw-500">Upgrade & Unlock!</span>
                     </button>
                  </td>
               </tr> -->
               <!-- <tr *ngIf="showCta" class="lock-overlay">
                  <td colspan="100%" class="p-0 text-center" *ngIf="!isLoggedIn" >
                     <button (click)="accessControlService.accessDenied()" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid" />
                        <span>Sign-up & Upgrade to Unlock!</span>
                     </button>
                  </td>
                  <td colspan="100%" class="p-0 text-center" *ngIf="isLoggedIn" >
                     <button (click)="accessControlService.accessDenied()" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid" />
                        <span>Upgrade & Unlock!</span>
                     </button>
                  </td>
               </tr> -->
            </tbody>

            <ng-template #noData>
               <tbody>
                  <tr class="bg-white no-data-tr">
                     <td colspan="100%" class="p-3 text-center">No Data</td>
                  </tr>
               </tbody>
            </ng-template>
         </table>
      </div>
   </ng-container>
   <div class="d-flex align-items-center justify-content-start">
      <ngb-pagination
         *ngIf="dataCount > pageSize"
         [collectionSize]="dataCount"
         [page]="page"
         [pageSize]="pageSize"
         [maxSize]="5"
         [rotate]="true"
         [boundaryLinks]="true"
         (pageChange)="onPageChange($event)"
         [disabled]="isPaginationDisabled"
         class="d-flex justify-content-center align-items-center gap-2 mt-3"
      >
         <ng-template ngbPaginationFirst>
            <i class="bi bi-chevron-double-left"></i>
         </ng-template>
         <ng-template ngbPaginationLast>
            <i class="bi bi-chevron-double-right"></i>
         </ng-template>
         <ng-template ngbPaginationPrevious>
            <i class="bi bi-chevron-left"></i>
         </ng-template>
         <ng-template ngbPaginationNext>
            <i class="bi bi-chevron-right"></i>
         </ng-template>
         <ng-template ngbPaginationNumber let-page>
            {{ page }}
         </ng-template>
      </ngb-pagination>
   </div>
</section>
