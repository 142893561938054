<div id="wrapper" class="inner_wrapper">
    <div class="small_content footer_page container-fluid pt-5 pb-5">
       <div class="text-center pb-3"> 
            <h1>Comprehensive Legal Disclaimers</h1>
        </div>                    
    
<h5>General Information</h5>

<p>Musaffa, LLC. and its parent and affiliates ("<strong>Musaffa</strong>", "<strong>us</strong>", "<strong>we</strong>" or "<strong>our</strong>") provide services through our website <a href="https://musaffa.com/">https://musaffa.com/</a> (the "<strong>Website</strong>"), Musaffa Academy (the "<strong>Academy</strong>"), Musaffa Web User Platform (the "<strong>Platform</strong>"), Musaffa Mobile User Application (the "<strong>Application</strong>"), Musaffa Community (the "<strong>Community</strong>") and any other media form, media channel, mobile website, or mobile application, present or future, related, linked, or otherwise connected thereto, including any content, functionality, and services offered on or through (collectively, the "<strong>Services</strong>") and such Services are intended for residents of jurisdictions where such use is lawful. Any access or use of our Services is at the user's own initiative and risk. Users are required to comply with all applicable laws and regulations while using our Services.</p>

<p>In the event of a conflict between these Comprehensive Legal Disclaimers and the Disclaimers outlined in the Terms of Use, the Disclaimer in the Terms of Use shall prevail. Access the Disclaimers at <a href="/terms-of-use#section-19">Terms of Use</a>.</p>

<h5>No Offer or Solicitation</h5>

<p>Nothing on the Services should be construed as a solicitation, offer, opinion, or recommendation to enter into any securities transactions, services regarding the profitability or suitability of any security or investment, or to engage in any of the investment strategies presented on our Services or Musaffa's Content (defined below). All information regarding investment strategies or securities is presented neutrally without any form of endorsement. The information and data used on the Services are for illustration and reference only.</p>

<p>Any forward-looking statements, predictions, projections, or forecasts regarding the economy, stock market, bond market, or market trends mentioned on our Services are influenced by external market factors beyond the control of Musaffa and may not materialize as anticipated. In addition, any opinions or estimates provided are general in nature and may change without prior notice.</p>

<h5>No Financial, Legal, Tax, or Investment Advice</h5>

<p>Musaffa is not a broker or dealer; we are not an investment advisor, and we do not have access to non-public information about publicly traded companies. We do not offer any licensed services or regulated financial advice and are not regulated by any government authority. For the purposes of clarity, "regulated financial advice" refers to personalized recommendations or guidance about specific investment products or strategies, typically provided by licensed professionals under the oversight of financial regulatory bodies. Any content that discusses stock performance, or related topics is provided for informational purposes only.</p>

<p>Musaffa is an education, technology, data, and research provider. Musaffa's Content, educational materials, third-party content, or any other content provided on our Services are for educational purposes only and should not be construed as financial, investment, legal, tax, or trading advice. Musaffa allows you to view stocks on our Services, you can buy or sell stocks solely through your brokerage account supported on our Platform and Application. Musaffa does not organize, execute trades, or facilitate investments, all trading activities must be conducted through your chosen brokerage account which is made available for convenience purposes.</p>

<p>While we provide insights into Shariah-compliant securities and socially responsible companies (e.g., companies that do not engage in arms sales, alcohol sales, gambling, etc.), it is your sole responsibility to verify the suitability and appropriateness of any investment strategy, related transaction, or securities screening suggestion based on your individual investment experience, financial resources, goals, values, and personal circumstances. Nothing on the Services considers specific investment objectives, financial situation, or individual needs of any person. You should consult with your own financial, legal, tax, and investment advisors when making any investment decisions. Our analysis follows AAOIFI (Accounting and Auditing Organization for Islamic Financial Institutions) methodology, but we do not guarantee the accuracy, timeliness, or completeness of the information provided, which may be subject to change. Musaffa is not liable for any errors in the information provided.</p>

<h5>Risk Disclosure</h5>

<p>All securities involve risk and may result in loss. There is no assurance that an investment mix or strategy will lead to the expected results or perform in a predictable manner. Investors should consider their investment objectives, financial circumstances, and risk tolerance before making any investment decisions.</p>

<h5>Third-Party Content</h5>

<p>We do not explicitly or implicitly endorse or approve any third-party content available on our Services. Such third-party content is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied, and for informational purposes only. Musaffa is not responsible for the accuracy, timeliness, or completeness of any third-party content. You agree to use such third-party content at your own risk and discretion, and you will be solely responsible for any damage or loss that results from the use of any third-party content.</p>

<h5>Musaffa's Content</h5>

<p>Content includes, without limitation: (i) general news and information, commentary, social media posts, research reports, educational material and information and data concerning the financial markets, securities, and other subjects; (ii) financial and investment, screening interactive tools, such as alerts, warnings, categorization or calculators; (iii) account management tools; (iv) trademarks, marks, logos, designs, names owned by Musaffa; and (v) any other information, material, content, media, services, or software provided by Musaffa (collectively, "<strong>Musaffa's Content</strong>").</p>

<p>Musaffa's Content and any services, products, media, data, or items obtained through the Services are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. Neither Musaffa nor anyone associated with Musaffa represents or warrants that the Services or Musaffa's Content, or any services, products, media, data, or items obtained through the Services will be accurate, reliable, complete, error-free, secure, always available, or uninterrupted, that defects will be corrected, or that Musaffa's Content or any services, products, media or items obtained through the Services will otherwise meet your needs or expectations.</p>

<h5>Educational Materials</h5>

<p>Any educational materials provided on the Services are for informational purposes only and should not be considered a recommendation or endorsement of any particular investment strategy. Past performance is not indicative of future results, and all investments carry risks, including the losses of principal.</p>

<h5>Technical Analysis and Mathematical Illustrations</h5>

<p>Advertisements and materials discussing technical analysis, mathematical illustrations, or other investment approaches are for illustrative purposes only and do not reflect actual results or specific investment recommendations, which may fluctuate in value. Security charts used on our Services are for illustrative purposes only and are not a recommendation, an offer to sell, or a solicitation of an offer to buy any security. The outcomes shown on our Services are not guaranteed or projected, and they do not include taxes, fees, or expenses, which would reduce the figures presented. You should not rely solely on such information for making investment decisions. Investing carries the risk of loss, and actual results may differ.</p>

<h5>Promotions and Awards</h5>

<p>Any promotions or awards mentioned are for informational purposes only and are not indicative of future performance. Musaffa is not affiliated with any third-party organizations granting such awards.</p>

<h5>Logos and Brands</h5>

<p>Any logos and brands of the third-party companies mentioned on our Services and in publication materials are used solely for identification purposes and remain the property of their respective owners. Further, the use of such third-party company logos and brands on our Services does not suggest any form of endorsement or partnership.</p>

<h5>Translation Deviances</h5>

<p>In case of any discrepancies between different language versions of our website or materials, the English version shall prevail.</p>

<h5>Regulatory Compliance</h5>

<p>None of the advertisements and promotional materials have been reviewed by the Securities Exchange Commission of the United States or any other regulatory body unless expressly stated. You should refer to the specific terms and conditions for any promotions or offers.</p>

<h5>Limitation of Liability</h5>

<p>Please refer to <strong>Section 20</strong> of the <a href="/terms-of-use#section-20">Terms of Use</a> for information regarding the Limitation of Liability.</p>

<h5>Updates and Changes</h5>

<p>These Comprehensive Legal Disclaimers may be updated or changed without prior notice. By continuing to use Musaffa Services, you agree to be bound by the current version of the Comprehensive Legal Disclaimers and our <a href="/terms-of-use">Terms of Use</a>.</p>
</div>