import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { loadWatchlistSymbols, loadWatchlistSymbolsSuccess, loadWatchlistSymbolsFailure } from './watchlist-symbols.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class WatchlistSymbolsEffects {
  loadWatchlistSymbols$ = createEffect(() => this.actions$.pipe(
    ofType(loadWatchlistSymbols),
    mergeMap(() => this.http.get<AllWatchlistSymbolsResponse>(`${environment.apiUrl}/api/watchlists/fetch-all-watchlist-symbols`)
      .pipe(
        map(response => loadWatchlistSymbolsSuccess({ symbols: response.data })),
        catchError(error => of(loadWatchlistSymbolsFailure({ error })))
      ))
  ));

  constructor(
    private actions$: Actions,
    private http: HttpClient
  ) {}
}

interface AllWatchlistSymbolsResponse {
   status: boolean,
   data: string[]
}
