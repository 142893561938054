import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TYPESENSE_COLLECTIONS } from 'src/app/shared/constants/typesense-collections.swbeta';
import { environment } from 'src/environments/environment';
import {
   AccountListResponse,
   PortfolioAccount,
} from '../models/account-list-response.model';
import { AccountDetailsResponse } from '../models/account-details-response.model';
import { AccountHoldingsResponse } from '../models/account-holdings-response.model';
import { AccountAnalyticsResponse } from '../models/account-analytics-response.model';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { PurificationTableDataResponse } from '../models/account-purification-table-response.model';
import { PurificationOverviewResponse } from '../models/account-purification-overview-response.model';
import { AccountNewsResponse } from '../models/account-news-response.model';
import { AccountTransactionsResponse } from '../models/account-transactions-response.model';
import { map } from 'rxjs';
import { PortfolioService as SharedPortfolioService } from 'src/app/shared/services/portfolio.service';
import { AccountPortfolioHealthResponse } from '../models/account-health-response.model';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class PortfolioService {
   constructor(
      private http: HttpClient,
      private typesenseService: TypesenseService,
      private sharedPortfolioService: SharedPortfolioService
   ) {}

   // private accountsLoading = new Observable<boolean>();
   // private accountDetailsLoading = new Observable<boolean>();
   // private accountHoldingsLoading = new Observable<boolean>();
   // private accountAnalyticsLoading = new Observable<boolean>();
   // private accountPurificationTableLoading = new Observable<boolean>();
   // private accountPurificationOverviewLoading = new Observable<boolean>();
   // private accountNewsLoading = new Observable<boolean>();
   // private accountTransactionsLoading = new Observable<boolean>();
   // private accountPortfolioHealthLoading = new Observable<boolean>();

   async getMergedData(
      baseArray: any[],
      symbolField: string = 'symbol',
      onlyStocks: boolean = true,
      typeField: string = 'type',
      getHeadquarters: boolean = false
   ) {
      if (onlyStocks) {
         baseArray = baseArray.filter(
            (holding: any) =>
               !holding[typeField] || holding[typeField] === 'stock'
         );
      }
      const symbols = [
         ...new Set(baseArray.map((holding) => holding[symbolField])),
      ];

      let data =
         await this.typesenseService.getCompanyProfileAndDataFromSymbols(
            symbols,
            getHeadquarters
         );

      return baseArray.map((holding) => {
         return {
            ...holding,
            ...data[holding[symbolField]],
         };
      });
   }

   async getMergedProfileAndComplianceData(baseArray: any[], symbolField: string = 'symbol', onlyStocks: boolean = true, typeField: string = 'type', getHeadquarters: boolean = false) {
      if (onlyStocks) {
         baseArray = baseArray.filter(
            (holding: any) =>
               !holding[typeField] || holding[typeField] === 'stock'
         );
      }
      const symbols = [
         ...new Set(baseArray.map((holding) => holding[symbolField])),
      ];

      let data =
         await this.typesenseService.getCompanyProfileAndDataFromSymbols(
            symbols,
            false,
            true
         );

      return baseArray.map((holding) => {
         return {
            ...holding,
            ...data[holding[symbolField]],
         };
      });
   }

   openConnectionModal() {
      this.sharedPortfolioService.openSupportedBrokeragesModal(false);
   }


   closeConnectionModal() {
      this.sharedPortfolioService.closeSupportedBrokeragesModal();
   }

   getPortfolioAccounts() {
      return this.http.get<AccountListResponse>(
         `${environment.apiUrl}/api/portfolio-data/accounts`
      );
   }

   getPortfolioAccountDetails(accountId: string) {
      return this.http.get<AccountDetailsResponse>(
         `${environment.apiUrl}/api/portfolio-data/accounts/${accountId}`
      );
   }

   getPortfolioHoldings(accountId: string) {
      return this.http.post<AccountHoldingsResponse>(
         `${environment.apiUrl}/api/portfolio-data/holdings`,
         {
            account_id: accountId,
         }
      );
   }

   getPortfolioAnalytics(
      accountId: string,
      type: 'shariah_compliant_status' | 'analyst_rating'
   ) {
      return this.http.post<AccountAnalyticsResponse>(
         `${environment.apiUrl}/api/portfolio-data/account-analytics`,
         {
            account_id: accountId,
            required_data: type,
         }
      );
   }

   getPurificationOverview(accountId: string) {
      return this.http.post<PurificationOverviewResponse>(
         `${environment.apiUrl}/api/portfolio-data/purification-overview`,
         {
            account_id: accountId,
         }
      );
   }

   getPurificationTableData(accountId: string) {
      return this.http.post<any>(
         `${environment.apiUrl}/api/portfolio-data/purification-table`,
         {
            account_id: accountId,
         }
      );
   }

   getAccountNews(accountId: string) {
      return this.http.post<AccountNewsResponse>(
         `${environment.apiUrl}/api/portfolio-data/get-relevant-news`,
         {
            account_id: accountId,
         }
      );
   }

   getAccountTransactions(accountId: string) {
      return this.http
         .post<AccountTransactionsResponse>(
            `${environment.apiUrl}/api/portfolio-data/account-transactions`,
            {
               account_id: accountId,
            }
         )
         .pipe(
            map((res: any) => {
               return {
                  status: res.status,
                  message: res.message,
                  status_code: res.status_code,
                  data: res.data.map((transaction: any) => {
                     const { units, amount, trade_date, ...rest } = transaction;
                     return {
                        ...rest,
                        units: Math.abs(parseFloat(units))?.toFixed(2) || '0',
                        amount: Math.abs(parseFloat(amount))?.toFixed(2) || '0',
                        trade_date: new Date(trade_date).toLocaleDateString(
                           'en-US',
                           {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                           }
                        ),
                     };
                  }),
               };
            })
         );
   }

   getAccountPortfolioHealth(accountId: string) {
      return this.http.post<AccountPortfolioHealthResponse>(
         `${environment.apiUrl}/api/portfolio-data/account-analytics/health`,
         {
            account_id: accountId,
         }
      );
   }

   getPortfolio() {
      //  return this.http.get('http://localhost:3000/portfolio');
   }

   getAccountAnalyticsData<T>(
      accountId: string,
      requiredData: string
   ): Observable<T> {
      return this.http.post<T>(
         `${environment.apiUrl}/api/portfolio-data/account-analytics`,
         {
            account_id: accountId,
            required_data: requiredData,
         }
      );
   }

   getPortfolioPerformance(accountId: string) {
      return this.http.post<any>(
         `${environment.apiUrl}/api/portfolio-data/portfolio-performance`,
         {
            account_id: accountId,
            duration: '1y',
         }
      );
   }

   purifyHolding(accountId: string, symbol: string) {
      return this.http.post<any>(
         `${environment.apiUrl}/api/portfolio-data/purify-symbol`,
         {
            account_id: accountId,
            symbol: symbol,
         }
      );
   }
}
