import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';

export interface DataLayer{
   event?:string,
   category?:string,
   action?:string,
   label?: string,
   value?:any
}

@Injectable({
  providedIn: 'root'
})

export class DataLayerService {
  private window; 

   constructor (private windowRef: WindowReferenceService) {
      this.window = this.windowRef.nativeWindow; // intialise the window to what we get from our window service
   }

   private pingHome(obj:DataLayer) {
      // if(obj)  this.window.dataLayer.push(obj);
   }

   signUpEvent(email: String) {
      const hit = {
         event: 'signup',
         email: email
      };
     this.pingHome(hit);
   }

   userSubscriptionCompletedEvent(plan: string) {
      const hit = {
         event: 'user_subscription_completed',
         plan: plan
      };
     this.pingHome(hit);
   }

   portfolioLinkingSuccessEvent(brokerage: string) {
      const hit = {
         event: 'portfolio_linking_success',
         brokerage: brokerage
      };
     this.pingHome(hit);
   }

}