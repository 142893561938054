import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserApiService } from './user-api.service';
import * as branch from 'branch-sdk';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { WindowReferenceService } from './window-reference.service';
// @ts-expect-error
import moengage from '@moengage/web-sdk';

//@ts-ignore

@Injectable({
   providedIn: 'root',
})
export class AnalyticsUtils {
   private mixpanel: any;

   static METHOD_GOOGLE = 'google';
   static METHOD_EMAIL = 'email';
   static METHOD_FACEBOOK = 'facebook';
   static METHOD_APPLE = 'apple';

   // public sample_shariah_report_tapped = 'sample_shariah_report_tapped';
   // public sample_investment_checklist_tapped ='sample_investment_checklist_tapped';
   // public sample_forecast_tapped = 'sample_forecast_tapped';
   // public sample_purification_tapped = 'sample_purification_tapped';

   public CreateAccount_ContinueGoogle_Tapped_EVENT =
      'CreateAccount_ContinueGoogle_Tapped';
   public CreateAccount_ContinueGoogle_Completed_EVENT =
      'CreateAccount_ContinueGoogle_Completed';
   public CreateAccount_ContinueGoogle_Failed_EVENT =
      'CreateAccount_ContinueGoogle_Failed';

   public SignupPrompt_ContinueGoogle_Tapped_EVENT =
      'SignupPrompt_ContinueGoogle_Tapped';
   public SignupPrompt_ContinueGoogle_Completed_EVENT =
      'SignupPrompt_ContinueGoogle_Completed';
   public SignupPrompt_ContinueGoogle_Failed_EVENT =
      'SignupPrompt_ContinueGoogle_Failed';

   public LoginScreen_ContinueGoogle_Tapped_EVENT =
      'LoginScreen_ContinueGoogle_Tapped';
   public LoginScreen_ContinueGoogle_Completed_EVENT =
      'LoginScreen_ContinueGoogle_Completed';
   public LoginScreen_ContinueGoogle_Failed_EVENT =
      'LoginScreen_ContinueGoogle_Failed';

   public CreateAccount_ContinueApple_Tapped_EVENT =
      'CreateAccount_ContinueApple_Tapped';
   public CreateAccount_ContinueApple_Completed_EVENT =
      'CreateAccount_ContinueApple_Completed';
   public CreateAccount_ContinueApple_Failed_EVENT =
      'CreateAccount_ContinueApple_Failed';

   public LoginScreen_ContinueApple_Tapped_EVENT =
      'LoginScreen_ContinueApple_Tapped';
   public LoginScreen_ContinueApple_Completed_EVENT =
      'LoginScreen_ContinueApple_Completed';
   public LoginScreen_ContinueApple_Failed_EVENT =
      'LoginScreen_ContinueApple_Failed';

   public SignupPrompt_ContinueApple_Tapped_EVENT =
      'SignupPrompt_ContinueApple_Tapped';
   public SignupPrompt_ContinueApple_Completed_EVENT =
      'SignupPrompt_ContinueApple_Completed';
   public SignupPrompt_ContinueApple_Failed_EVENT =
      'SignupPrompt_ContinueApple_Failed';

   public CreateAccount_ContinueFB_Tapped_EVENT =
      'CreateAccount_ContinueFB_Tapped';
   public CreateAccount_ContinueFB_Completed_EVENT =
      'CreateAccount_ContinueFB_Completed';
   public CreateAccount_ContinueFB_Failed_EVENT =
      'CreateAccount_ContinueFB_Failed';

   public SignupPrompt_ContinueFB_Tapped_EVENT =
      'SignupPrompt_ContinueFB_Tapped';
   public SignupPrompt_ContinueFB_Completed_EVENT =
      'SignupPrompt_ContinueFB_Completed';
   public SignupPrompt_ContinueFB_Failed_EVENT =
      'SignupPrompt_ContinueFB_Failed';

   public LoginScreen_ContinueFB_Tapped_EVENT = 'LoginScreen_ContinueFB_Tapped';
   public LoginScreen_ContinueFB_Completed_EVENT =
      'LoginScreen_ContinueFB_Completed';
   public LoginScreen_ContinueFB_Failed_EVENT = 'LoginScreen_ContinueFB_Failed';

   public StockNews_ShareTapped = 'StockNews_ShareTapped';
   public PressRelease_ShareTapped = 'PressRelease_ShareTapped';

   public CreateAccount_ContinueEmail_Tapped =
      'CreateAccount_ContinueEmail_Tapped';
   private window;
   constructor(
      private authService: AuthService,
      private userApiService: UserApiService,
      private firebaseAnalytics: AngularFireAnalytics,
      private windowRef: WindowReferenceService
   ) {
      this.mixpanel = mixpanel;
      this.window = this.windowRef.nativeWindow; // intialise the window to what we get from our window service
   }

   private async initMixpanel() {
      // Initialize Mixpanel with your token
      await this.mixpanel.init(environment.mixPlaneID, {
         debug: true,
         track_pageview: true,
         ignore_dnt: true,
      });
   }

   async identifyUser() {
      // console.trace();
      await this.initMixpanel();
      // console.log('this.authService.getUserId()');
      // console.log(this.authService.getUserId());
      if (this.authService.getUserId()) {
         this.mixpanel.identify(this.authService.getUserId());
         let user: any = await this.userApiService.fetchUserDetails();
         branch.setIdentity(user?.identifier);
         moengage.add_unique_user_id(user?.identifier);
         this.setUserInfo(
            user?.firstname,
            user?.lastname,
            user?.sex,
            user?.geo_ip_country,
            user?.age_group,
            user?.registration_type,
            user?.create_date,
            user?.email,
            user?.is_active,
            user?.phone,
            user?.default_country
         );
      } else {
         this.mixpanel.identify(null);
      }
   }

   setUserInfo(
      first_name: string,
      last_name: string,
      gender: string,
      country_code: string,
      ageGroup: string,
      registrationType: string,
      created: string,
      email: string,
      is_active: boolean,
      number: number,
      default_country: string
   ) {
      this.mixpanel.people.set('$first_name', first_name);
      this.mixpanel.people.set('$last_name', last_name);
      this.mixpanel.people.set('gender', gender);
      this.mixpanel.people.set('countryCode', country_code);
      this.mixpanel.people.set('ageGroup', ageGroup);
      this.mixpanel.people.set('registrationType', registrationType);
      this.mixpanel.people.set('$created', created);
      this.mixpanel.people.set('$email', email);
      //moegage seetup user

      moengage.add_first_name(first_name ?? '');
      moengage.add_last_name(last_name ?? '');
      moengage.add_email(email ?? '');
      moengage.add_gender(gender ?? '');
      moengage.add_mobile(number ?? '');
      moengage.add_user_attribute('geo_ip_country', country_code ?? '');
      moengage.add_user_attribute('is_active', is_active ?? '');
      moengage.add_user_attribute('registration_type', registrationType ?? '');
      moengage.add_user_attribute('create_date', created ?? '');
      moengage.add_user_attribute('default_country', default_country ?? '');
   }

   // trackEvent(eventName: string, properties?: any) {
   //    // Track an event with optional properties
   //    this.mixpanel.track(eventName, properties);
   // }

   async sendCustomEvent(name: String, params: any) {
      this.sendEvent(name, params);
   }

   async sendEvent(name: any, params: any) {
      // log("--evebt--");
      //     log(name);
      // log(params.toString());

      // if (params == null) {
      //   AppMetrica.reportEvent(name);
      //   await FirebaseAnalytics.instance.logEvent(
      //     name: name,
      //   );
      //   MixpanelUtils.instance().track(name);
      // } else {
      //   AppMetrica.reportEventWithMap(name, params);
      //   await FirebaseAnalytics.instance.logEvent(
      //     name: name,
      //     parameters: params,
      //   );
      //   MixpanelUtils.instance().track(name, properties: params);
      // }
      this.firebaseAnalytics.logEvent(name, params);
      this.mixpanel.track(name, params);
      if (params != null)
         this.window.dataLayer.push({
            event: name,
            ...params,
         });
      else
         this.window.dataLayer.push({
            event: name,
         });
   }

   sendDefaultLogin(method: string) {
      var params = { method: method };
      // FirebaseAnalytics.instance.logLogin(loginMethod: method);
      // facebookAppEvents.logEvent(name: "login", parameters: params);
      // AppMetrica.reportEventWithMap("login", params);
      this.firebaseAnalytics.logEvent('login', params);
      this.mixpanel.track('login', params);
   }

   async sendDefaultSignup(method: String) {
      var params = { method: method };
      // await FirebaseAnalytics.instance.logSignUp(signUpMethod: method);

      // facebookAppEvents.logEvent(name: "signup", parameters: params);
      // FacebookAppEvents().logCompletedRegistration(registrationMethod: method);
      // AppMetrica.reportEventWithMap("signup", params);
      this.firebaseAnalytics.logEvent('signup', params);
      branch.logEvent('signup', params);
      moengage.track_event('signup', params);
      this.mixpanel.track('signup', params);
   }

   async Onboarding_Continue_Tapped(pageNumber: any) {
      var params = {
         pageNumber: pageNumber ?? '-1',
      };
      this.sendEvent('Onboarding_Continue_Tapped', params);
   }

   async CreateAccount_Skip_Tapped() {
      var params = null;
      this.sendEvent('CreateAccount_Skip_Tapped', params);
   }

   async LoginScreen_Opened() {
      var params = null;
      this.sendEvent('LoginScreen_Opened', params);
   }

   async LoginScreen_Login_Tapped(email?: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('LoginScreen_Login_Tapped', params);
   }

   LoginScreen_Login_Failed(
      email?: String,
      error_message?: String,
      exceptionMessage?: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('LoginScreen_Login_Failed', params);
   }

   async LoginScreen_Login_Completed(email?: String) {
      await this.identifyUser();
      var params = {
         email: email ?? '',
      };
      this.sendEvent('LoginScreen_Login_Completed', params);
      this.sendDefaultLogin(AnalyticsUtils.METHOD_EMAIL);
   }

   LoginScreen_Verification_Pending(email?: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('LoginScreen_Verification_Pending', params);
   }

   async LoginScreen_ForgotPassword_Tapped() {
      var params = null;
      this.sendEvent('LoginScreen_ForgotPassword_Tapped', params);
   }

   async ForgotPassword_Submit_Tapped(email?: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('ForgotPassword_Submit_Tapped', params);
   }

   async LoginScreen_ContinueGoogle_Tapped() {
      var params = null;
      this.sendEvent('LoginScreen_ContinueGoogle_Tapped', params);
   }

   async LoginScreen_ContinueGoogle_Completed(email: String, isNew: boolean) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('LoginScreen_ContinueGoogle_Completed', params);
      if (isNew) this.sendDefaultSignup(AnalyticsUtils.METHOD_GOOGLE);
      else this.sendDefaultLogin(AnalyticsUtils.METHOD_GOOGLE);
   }

   async SignupFlow_ContinueGoogle_Completed(email: String, isNew: boolean) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_ContinueGoogle_Completed', params);
      if (isNew) this.sendDefaultSignup(AnalyticsUtils.METHOD_GOOGLE);
      else this.sendDefaultLogin(AnalyticsUtils.METHOD_GOOGLE);
   }

   async SignupFlow_OTPScreen_Opened(email?: string) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_OTPScreen_Opened', params);
   }

   async SignupFlow_VerifyOTP_Tapped(email?: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_VerifyOTP_Tapped', params);
   }

   SignupFlow_VerifyOTP_Failed(
      email?: String,
      message?: String,
      exceptionMessage?: any
   ) {
      var params: any = { email: email ?? '', error_message: message };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('SignupFlow_VerifyOTP_Failed', params);
   }

   async SignupFlow_VerifyOTP_Completed(email?: String) {
      await this.identifyUser();
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_VerifyOTP_Completed', params);
      await this.sendDefaultSignup(AnalyticsUtils.METHOD_EMAIL);
   }

   async SignupFlow_RegistrationScreen_Opened() {
      this.sendEvent('SignupFlow_RegScreen_Opened', null);
   }

   async SignupFlow_RegistrationScreen_SignupTapped(email?: String) {
      var params = {
         email: email ?? '',
      };
      await this.sendEvent('SignupFlow_RegScreen_SignupTapped', params);
   }

   async SignupFlow_RegistrationScreen_SignupCompleted(email?: String) {
      var params = {
         email: email ?? '',
      };
      await this.sendEvent('SignupFlow_RegScreen_SignupCompleted', params);
   }

   async SignupFlow_RegistrationScreen_Failed(
      email?: String,
      error_message?: String,
      exceptionMessage?: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      await this.sendEvent('SignupFlow_RegScreen_SignupFailed', params);
   }

   async CreateAccountContinueEmailTapped() {
      this.sendEvent('CreateAccount_ContinueEmail_Tapped', null);
   }

   async SignupPromptContinueEmailTapped() {
      this.sendEvent('SignupPrompt_ContinueEmail_Tapped', null);
   }

   async CreateAccountAlreadyMemberLoginTapped() {
      this.sendEvent('CreateAccount_AlreadyMember_Tapped', null);
   }

   async sample_purification_tapped() {
      this.sendEvent('sample_purification_tapped', null);
   }

   async sample_shariah_report_tapped() {
      this.sendEvent('sample_shariah_report_tapped', null);
   }

   async sample_investment_checklist_tapped() {
      this.sendEvent('sample_investment_checklist_tapped', null);
   }

   async sample_forecast_tapped() {
      this.sendEvent('sample_forecast_tapped', null);
   }

   async SignupPromptAlreadyMemberLoginTapped() {
      this.sendEvent('SignupPrompt_AlreadyMember_Tapped', null);
   }

   async OnboardingOpened() {
      this.sendEvent('Onboarding_Opened', null);
   }

   async Home_TopSearch_Tapped() {
      this.sendEvent('Home_TopSearch_Tapped', null);
   }

   async Home_StockScreener_Tapped() {
      this.sendEvent('Home_StockScreener_Tapped', null);
   }

   async Home_ETFScreener_Tapped() {
      this.sendEvent('Home_ETFScreener_Tapped', null);
   }

   async Home_StockPurification_Tapped() {
      this.sendEvent('Home_StockPurification_Tapped', null);
   }

   async Home_PopularStock_Tapped(symbol: String) {
      var params = { symbol: symbol };

      await this.sendEvent('Home_PopularStock_Tapped', params);
   }

   async Home_MyPortfolio_Tapped(symbol: String) {
      var params = { symbol: symbol };
      await this.sendEvent('Home_MyPortfolio_Tapped', params);
   }

   async Home_Zakat_Tapped() {
      await this.sendEvent('Home_Zakat_Tapped', null);
   }

   async Home_AcademyArticle_Tapped(title: String) {
      var params = { title: title };
      await this.sendEvent('Home_AcademyArticle_Tapped', params);
   }

   async Home_Academy_LearnMore_Tapped() {
      this.sendEvent('Home_Academy_LearnMore_Tapped', null);
   }

   async home_portfolio_tapped() {
      this.sendEvent('home_portfolio_tapped', null);
   }

   async Home_AppFeatures_All_Tapped() {
      this.sendEvent('Home_AppFeatures_All_Tapped', null);
   }

   async Academy_Article_Opened(title: String) {
      var params = { title: title };
      this.sendEvent('Academy_Article_Opened', params);
   }

   async AppFeatures_Article_Opened(title: String, source: String) {
      var params = { title: title, source: source };
      this.sendEvent('AppFeatures_Article_Opened', params);
   }

   async Home_MarketNews_Tapped(title?: String) {
      var params = { title: title ?? '' };
      await this.sendEvent('Home_MarketNews_Tapped', params);
   }

   async StockNewsTapped(symbol?: String, title?: String) {
      var params = { symbol: symbol ?? '', title: title ?? '' };
      await this.sendEvent('StockNews_Tapped', params);
   }

   async StockPressReleaseTapped(symbol?: String, title?: String) {
      var params = { symbol: symbol ?? '', title: title ?? '' };
      await this.sendEvent('StockPressRelease_Tapped', params);
   }

   async BottomNav_Screener_Tapped() {
      await this.sendEvent('BottomNav_Screener_Tapped', null);
   }

   async BottomNav_Home_Tapped() {
      await this.sendEvent('BottomNav_Home_Tapped', null);
   }

   async BottomNav_Recent_Tapped() {
      await this.sendEvent('BottomNav_Recent_Tapped', null);
   }

   async BottomNav_Portfolio_Tapped() {
      await this.sendEvent('BottomNav_Portfolio_Tapped', null);
   }

   async BottomNav_Watchlist_Tapped() {
      await this.sendEvent('BottomNav_Watchlist_Tapped', null);
   }

   async BottomNav_Profile_Tapped() {
      await this.sendEvent('BottomNav_Profile_Tapped', null);
   }

   async Screener_CountryFilter_Tapped() {
      await this.sendEvent('Screener_CountryFilter_Tapped', null);
   }

   async Screener_CountryFilter_Applied(country: String) {
      var params = { country: country ?? '' };
      await this.sendEvent('Screener_CountryFilter_Applied', params);
   }

   async Screener_TopSearch_Tapped() {
      await this.sendEvent('Screener_TopSearch_Tapped', null);
   }

   async Screener_StockItem_Tapped(symbol: String) {
      var params = { symbol: symbol ?? '' };
      await this.sendEvent('Screener_StockItem_Tapped', params);
   }

   async Screener_ETFItem_Tapped(symbol: String) {
      var params = { symbol: symbol ?? '' };
      await this.sendEvent('Screener_ETFItem_Tapped', params);
   }

   async Screener_Stock_Filter_Tapped() {
      await this.sendEvent('Screener_Stock_Filter_Tapped', null);
   }

   async Screener_ETF_Filter_Tapped() {
      await this.sendEvent('Screener_ETF_Filter_Tapped', null);
   }

   async Recent_ListItem_Tapped(symbol: String) {
      var params = { symbol: symbol };
      await this.sendEvent('Recent_ListItem_Tapped', params);
   }

   async Watchlist_NewWatchlist_Tapped() {
      await this.sendEvent('Watchlist_NewWatchlist_Tapped', null);
   }

   async Profile_ChangePassword_Tapped() {
      await this.sendEvent('Profile_ChangePassword_Tapped', null);
   }

   async Profile_Support_Tapped() {
      await this.sendEvent('Profile_Support_Tapped', null);
   }

   async Profile_ShareApp_Tapped() {
      await this.sendEvent('Profile_ShareApp_Tapped', null);
   }

   async Profile_Logout_Tapped() {
      branch.logout();
      await this.sendEvent('Profile_Logout_Tapped', null);
      moengage.destroy_session();
   }

   async User_Logged_Out() {
      await this.sendEvent('User_Logged_Out', null);
   }

   async Profile_AccountDetails_Tapped() {
      await this.sendEvent('Profile_AccountDetails_Tapped', null);
   }

   async Profile_PrivacyPolicy_Tapped() {
      await this.sendEvent('Profile_PrivacyPolicy_Tapped', null);
   }

   async Profile_TermsOfUse_Tapped() {
      await this.sendEvent('Profile_TermsOfUse_Tapped', null);
   }

   async Profile_RateApp_Tapped() {
      await this.sendEvent('Profile_RateApp_Tapped', null);
   }

   async InApp_Feedback_Opened(sourcepage: String) {
      var params = { sourcepage: sourcepage ?? '' };
      await this.sendEvent('InApp_Feedback_Opened', params);
   }

   async StockDetails_ScreeningReport_Tapped(
      symbol: string,
      compliance: string
   ) {
      var params = { symbol: symbol ?? '', compliance: compliance ?? '' };

      await this.sendEvent('StockDetails_ScreeningReport_Tapped', params);
   }

   async ETFDetails_ScreeningReport_Tapped(symbol: String, compliance: String) {
      var params = { symbol: symbol ?? '', compliance: compliance ?? '' };
      await this.sendEvent('ETFDetails_ScreeningReport_Tapped', params);
   }

   async Purification_Calculate_Tapped(symbol: String) {
      var params = { symbol: symbol ?? '' };
      await this.sendEvent('Purification_Calculate_Tapped', params);
   }

   async Purification_Calculate_Success(symbol: String) {
      var params = { symbol: symbol ?? '' };
      await this.sendEvent('Purification_Calculate_Success', params);
   }

   async Purification_Calculate_Failed(symbol: String) {
      var params = { symbol: symbol ?? '' };
      await this.sendEvent('Purification_Calculate_Failed', params);
   }

   async Purification_MoreInfo_Tapped() {
      await this.sendEvent('Purification_MoreInfo_Tapped', null);
   }

   async Purification_Results_MoreInfo_Tapped() {
      await this.sendEvent('Purification_Results_MoreInfo_Tapped', null);
   }

   async Purification_WhatNext_Tapped() {
      await this.sendEvent('Purification_WhatNext_Tapped', null);
   }

   async InApp_Feedback_Submitted(sourcepage: String) {
      var params = { sourcepage: sourcepage ?? '' };
      await this.sendEvent('InApp_Feedback_Submitted', params);
   }

   async Profile_SocialIcon_Tapped(icon: String) {
      var params = { social: icon ?? '' };
      await this.sendEvent('Profile_SocialIcon_Tapped', params);
   }

   async Screener_StockScreener_Selected() {
      await this.sendEvent('Screener_StockScreener_Selected', null);
   }

   async Screener_ETFScreener_Selected() {
      await this.sendEvent('Screener_ETFScreener_Selected', null);
   }

   async Screener_Stock_Exchange_Filter_Applied(values: any) {
      var params = { values: values };
      await this.sendEvent('Screener_Stock_Exchange_Filter_Applied', params);
   }

   async Screener_Stock_Sector_Filter_Applied(values: String) {
      var params = { values: values ?? '' };
      await this.sendEvent('Screener_Stock_Sector_Filter_Applied', params);
   }

   async Screener_Stock_Industry_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_Stock_Industry_Filter_Applied', params);
   }

   async Screener_Stock_Compliance_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_Stock_Compliance_Filter_Applied', params);
   }

   async Screener_Stock_HalalScore_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_Stock_HalalScore_Filter_Applied', params);
   }

   async Screener_ETF_Exchange_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_ETF_Exchange_Filter_Applied', params);
   }

   async Screener_ETF_AssetClass_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_ETF_AssetClass_Filter_Applied', params);
   }

   async Screener_ETF_Compliance_Filter_Applied(values: any) {
      var params = { values: values.toString() };
      await this.sendEvent('Screener_ETF_Compliance_Filter_Applied', params);
   }

   async SignupFlow_RegScreen_ValidateFailed(obj: any) {
      var params = obj;

      await this.sendEvent('SignupFlow_RegScreen_ValidateFailed', params);
   }

   async Profile_DeleteAccount_Tapped() {
      await this.sendEvent('Profile_DeleteAccount_Tapped', null);
   }

   async Profile_ScreeningMethodology_Tapped() {
      await this.sendEvent('Profile_ScreeningMethodology_Tapped', null);
   }

   async Profile_AppFeatures_Tapped() {
      await this.sendEvent('Profile_AppFeatures_Tapped', null);
   }

   async CreateAccount_Screen_Opened() {
      await this.sendEvent('CreateAccount_Screen_Opened', null);
   }

   async Profile_AboutMusaffa_Tapped() {
      await this.sendEvent('Profile_AboutMusaffa_Tapped', null);
   }

   async Profile_MusaffaAcademy_Tapped() {
      await this.sendEvent('Profile_MusaffaAcademy_Tapped', null);
   }

   async SignupFlow_EmailSubmit_ValidateFailed(email: String, err: String) {
      var params = { email: email, error_message: err };

      await this.sendEvent('SignupFlow_EmailSubmit_ValidateFailed', params);
   }

   async SignupFlow_EmailError_PopupDisplayed(email: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_EmailError_PopupDisplayed', params);
   }

   async SignupFlow_EmailError_ContinueGoogle(email: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_EmailError_ContinueGoogle', params);
   }

   async SignupFlow_EmailError_ContinuePassword(email: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_EmailError_ContinuePassword', params);
   }

   async SignupFlow_EmailError_Google_Proceed(email: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_EmailError_Google_Proceed', params);
   }

   async SignupFlow_EmailError_Password_Proceed(email: String) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('SignupFlow_EmailError_Password_Proceed', params);
   }

   async User_Signup_Banner_Tapped() {
      this.sendEvent('SignupBanner_Tapped', null);
   }

   async ProfileScreenLoginTapped() {
      this.sendEvent('ProfileScreen_Login_Tapped', null);
   }

   async ProfileScreenGetStartedTapped() {
      this.sendEvent('ProfileScreen_GetStarted_Tapped', null);
   }

   async CreateAccountContinueGoogleTapped() {
      this.sendEvent('CreateAccount_ContinueGoogle_Tapped', null);
   }

   async CreateAccountContinueGoogleCompleted(email: String, isNew: boolean) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('CreateAccount_ContinueGoogle_Completed', params);
      if (isNew) this.sendDefaultSignup(AnalyticsUtils.METHOD_GOOGLE);
      else this.sendDefaultLogin(AnalyticsUtils.METHOD_GOOGLE);
   }

   CreateAccountContinueGoogleFailed(
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('CreateAccount_ContinueGoogle_Failed', params);
   }

   async CreateAccountContinueAppleCompleted(email: String, isNew: boolean) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('CreateAccountContinueAppleCompleted', params);
      if (isNew) this.sendDefaultSignup(AnalyticsUtils.METHOD_APPLE);
      else this.sendDefaultLogin(AnalyticsUtils.METHOD_APPLE);
   }

   async CreateAccountContinueFacebookCompleted(email: String, isNew: boolean) {
      var params = {
         email: email ?? '',
      };
      this.sendEvent('CreateAccountContinueFacebookCompleted', params);
      if (isNew) this.sendDefaultSignup(AnalyticsUtils.METHOD_FACEBOOK);
      else this.sendDefaultLogin(AnalyticsUtils.METHOD_FACEBOOK);
   }

   CreateAccountContinueFacebookFailed(
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('CreateAccountContinueFacebookFailed', params);
   }

   CreateAccountContinueAppleFailed(
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('CreateAccountContinueAppleFailed', params);
   }

   SignupFlowContinueGoogleFailed(
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('SignupFlow_ContinueGoogle_Failed', params);
   }

   LoginScreenContinueGoogleFailed(
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent('LoginScreen_ContinueGoogle_Failed', params);
   }

   async Purification_StockSearch_NotSelected(search_text: String) {
      var params = { search_text: search_text };

      this.sendEvent('Purification_StockSearch_NotSelected', params);
   }

   async SOCIAL_AUTH_BTN_TAP(event_name: String) {
      this.sendEvent(event_name, null);
   }

   async SOCIAL_AUTH_COMPLETED(
      event_name: String,
      email: string,
      isNew: boolean,
      method: string
   ) {
      await this.userApiService.fetchUserDetailsAndSave();
      await this.identifyUser();
      var params = {
         email: email ?? '',
      };
      await this.sendEvent(event_name, params);
      if (isNew) this.sendDefaultSignup(method);
      else this.sendDefaultLogin(method);
   }

   SOCIAL_AUTH_FAILED(
      event_name: String,
      email: String,
      error_message: String,
      exceptionMessage: any
   ) {
      var params: any = {
         email: email ?? '',
         error_message: error_message ?? '',
      };
      if (exceptionMessage != null) {
         params['exception_message'] = exceptionMessage;
      }
      this.sendEvent(event_name, params);
   }

   async Signup_Prompt_Displayed(source: String) {
      var params = { source: source };
      this.sendEvent('Signup_Prompt_Displayed', params);
   }

   async Home_Top_ShareTapped() {
      this.sendEvent('Home_Top_ShareTapped', null);
   }

   async AcademyArticle_Top_ShareTapped(title: String) {
      var params = { title: title };
      this.sendEvent('AcademyArticle_Top_ShareTapped', params);
   }

   async AppFeatures_Top_ShareTapped(title: String) {
      var params = { title: title };
      this.sendEvent('AppFeatures_Top_ShareTapped', params);
   }

   async MarketNews_Top_ShareTapped(title: String) {
      var params = { title: title };
      this.sendEvent('MarketNews_Top_ShareTapped', params);
   }

   async StockDetails_Top_ShareTapped(symbol: String) {
      var params = { symbol: symbol };
      this.sendEvent('StockDetails_Top_ShareTapped', params);
   }

   async Stock_ScreeningReport_ShareTapped(symbol: String, compliance: String) {
      var params = { symbol: symbol, shariah_compliance: compliance };
      this.sendEvent('Stock_ScreeningReport_ShareTapped', params);
   }

   async ETF_ScreeningReport_ShareTapped(symbol: String, compliance: String) {
      var params = { symbol: symbol, shariah_compliance: compliance };
      this.sendEvent('ETF_ScreeningReport_ShareTapped', params);
   }

   async ETFDetails_Top_ShareTapped(symbol: String) {
      var params = { symbol: symbol };
      this.sendEvent('ETFDetails_Top_ShareTapped', params);
   }

   async Purification_Top_ShareTapped(symbol: String) {
      var params = { symbol: symbol };
      this.sendEvent('Purification_Top_ShareTapped', params);
   }

   async skip_confirmation_skip_tapped() {
      this.sendEvent('skip_confirmation_skip_tapped', null);
   }

   async skip_confirmation_signup_started() {
      this.sendEvent('skip_confirmation_signup_started', null);
   }

   async skip_confirmation_login_tapped() {
      this.sendEvent('skip_confirmation_login_tapped', null);
   }

   async paywall_displayed() {
      this.sendEvent('paywall_displayed', null);
   }

   async paywall_closed() {
      this.sendEvent('paywall_closed', null);
   }

   async paywall_check_coverage_prompted() {
      this.sendEvent('paywall_check_coverage_prompted', null);
   }

   async paywall_country_show_coverage() {
      this.sendEvent('paywall_country_show_coverage', null);
   }

   async paywall_country_confirm_proceed() {
      this.sendEvent('paywall_country_confirm_proceed', null);
   }

   async paywall_country_confirm_cancel() {
      this.sendEvent('paywall_country_confirm_cancel', null);
   }

   async paywall_subscribe_tapped(plan: String) {
      var params = { plan: plan };
      this.sendEvent('paywall_subscribe_tapped', params);
   }

   async paywall_begin_subscription_process(plan: String) {
      var params = { plan: plan };
      this.sendEvent('paywall_begin_subscription_process', params);
   }

   async home_upgrade_tapped() {
      this.sendEvent('home_upgrade_tapped', null);
   }

   async sample_feature_upgrade_tapped(source: String) {
      var params = { source: source };
      this.sendEvent('sample_feature_upgrade_tapped', params);
   }

   async portfolio_upgrade_tapped() {
      this.sendEvent('portfolio_upgrade_tapped', null);
   }

   async stock_collection_signup_tapped(source: String) {
      var params = { source: source };
      this.sendEvent('stock_collection_signup_tapped', params);
   }

   async generate_referral_link_tapped() {
      this.sendEvent('generate_referral_link_tapped', null);
   }

   async share_referral_link_tapped() {
      this.sendEvent('share_referral_link_tapped', null);
   }

   async profile_refer_app_tapped() {
      this.sendEvent('profile_refer_app_tapped', null);
   }

   async profile_upgrade_tapped() {
      this.sendEvent('profile_upgrade_tapped', null);
   }

   async profile_signup_tapped() {
      this.sendEvent('profile_signup_tapped', null);
   }

   async feature_upgrade_tapped(source: String) {
      var params = { feature: source };
      this.sendEvent('feature_upgrade_tapped', params);
   }

   async user_subscription_completed(plan: String) {
      const userId = this.authService.getUserId();
      let params = { plan: plan, user_id: userId };
      // let mixpanelParams = { plan: plan };
      branch.logEvent('user_subscription_completed', params);
      moengage.track_event('user_subscription_completed', params);
      await this.sendEvent('user_subscription_completed', params);
   }

   async user_subscription_failed(error: String) {
      var params = { error: error };
      this.sendEvent('user_subscription_failed', params);
      moengage.track_event('user_subscription_failed', params);
   }

   async onboarding_questions_skip_tapped() {
      this.sendEvent('onboarding_questions_skip_tapped', null);
      moengage.track_event('onboarding_questions_skip_tapped', null);
   }

   async onboarding_questions_submit_tapped() {
      this.sendEvent('onboarding_questions_submit_tapped', null);
      moengage.track_event('onboarding_questions_submit_tapped', null);
   }

   async user_subscription_exception() {
      this.sendEvent('user_subscription_exception', null);
   }

   async purification_trial_upgrade() {
      this.sendEvent('purification_trial_upgrade', null);
   }

   async user_subscription_cancelled() {
      this.sendEvent('user_subscription_cancelled', null);
      moengage.track_event('user_subscription_cancelled', null);
   }

   async request_coverage_tapped(country: String, symbol: String) {
      var params = { country: country, symbol: symbol };
      this.sendEvent('request_coverage_tapped', params);
   }

   async request_coverage_limit_shown(country: String, symbol: String) {
      var params = { country: country, symbol: symbol };
      this.sendEvent('request_coverage_limit_shown', params);
   }

   async portfolio_link_account_tapped() {
      this.sendEvent('portfolio_link_account_tapped', null);
   }

   async portfolio_begin_link_account() {
      this.sendEvent('portfolio_begin_link_account', null);
   }

   async portfolio_linking_success(brokerage: String) {
      var params = { brokerage: brokerage };
      moengage.track_event('portfolio_linking_success', params);
      this.sendEvent('portfolio_linking_success', params);
   }

   async portfolio_linking_failed() {
      this.sendEvent('portfolio_linking_failed', null);
   }

   async portfolio_account_switched() {
      this.sendEvent('portfolio_account_switched', null);
   }

   async portfolio_holding_item_tapped(symbol: String) {
      var params = { symbol: symbol };
      this.sendEvent('portfolio_holding_item_tapped', params);
   }

   async portfolio_holding_view_all() {
      this.sendEvent('portfolio_holding_view_all', null);
   }

   async portfolio_holding_item_view_details() {
      this.sendEvent('portfolio_holding_item_view_details', null);
   }

   async portfolio_link_another_brokerage() {
      this.sendEvent('portfolio_link_another_brokerage', null);
   }

   async portfolio_unlink_brokerage() {
      // var params = { brokerage: brokerage };
      this.sendEvent('portfolio_unlink_brokerage', null);
   }

   async portfolio_sync_tapped() {
      this.sendEvent('portfolio_sync_tapped', null);
   }

   async portfolio_purification_calculate() {
      this.sendEvent('portfolio_purification_calculate', null);
   }

   async portfolio_purification_loading() {
      this.sendEvent('portfolio_purification_loading', null);
   }

   async portfolio_purification_comingsoon() {
      this.sendEvent('portfolio_purification_comingsoon', null);
   }

   async purification_method_changed(method: String) {
      var params = { methodology: method };
      this.sendEvent('purification_method_changed', params);
   }
   async purification_activity_tapped() {
      this.sendEvent('purification_activity_tapped', null);
   }
   async mark_purified_tapped() {
      this.sendEvent('mark_purified_tapped', null);
   }
   async mark_all_purified_tapped() {
      this.sendEvent('mark_all_purified_tapped', null);
   }
   async resend_otp_tapped() {
      this.sendEvent('resend_otp_tapped', null);
   }
}
