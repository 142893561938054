
<section>
   <!-- <header>
      <div class="container">
         <a href="/cabinet"
            ><img
               src="/assets/images/musaffa-logo.svg"
               alt="musaffa logo"
               class="img-logo"
         /></a>
      </div>
   </header> -->
   <!-- <app-new-header></app-new-header> -->
   <div class="main">
      <div class="page">
         <div class="page-graph">
            <h2>Trying to learn more about Musaffa?</h2>
            <p>The page you’re looking for does not exist. Head back to the homepage to learn more.</p>
            <button type="button" class="btn" [routerLink]="['/']">BACK TO HOME</button>
         </div>

      </div>
   </div>
</section>
